import React from 'react'
import { Stack, Typography } from '@mui/material'

const BlacklistedAccountMessage: React.FC<{ email: string }> = ({ email }) => (
  <Stack spacing={1.5}>
    <Typography color="primary.dark">This account has been blocked:</Typography>

    <Typography color="primary.dark" fontWeight={700}>
      {email}
    </Typography>

    <Typography color="primary.dark">
      Click below to call and appeal this restriction.
    </Typography>
  </Stack>
)

export default BlacklistedAccountMessage
