import { ActionType, createReducer } from 'typesafe-actions'

import { LoadingContext, LoadingState } from '../../../utils/types'
import { makeLoading } from '../../../utils/store'
import { attachmentsActions } from '../actions'
import { oneInsert } from '../../mutators/relations'

interface State {
  fetchingAttachmentsById: Record<number, LoadingContext>
}

const initialState: State = {
  fetchingAttachmentsById: {},
}

export default createReducer<State, ActionType<typeof attachmentsActions>>(
  initialState
)
  .handleAction(
    attachmentsActions.getAttachment.request,
    (state, { payload: { attachmentId } }): State => ({
      ...state,
      fetchingAttachmentsById: oneInsert(
        makeLoading(LoadingState.loading),
        attachmentId,
        state.fetchingAttachmentsById
      ),
    })
  )
  .handleAction(
    attachmentsActions.getAttachment.success,
    (state, { payload: { attachmentId } }): State => ({
      ...state,
      fetchingAttachmentsById: oneInsert(
        makeLoading(LoadingState.success),
        attachmentId,
        state.fetchingAttachmentsById
      ),
    })
  )
  .handleAction(
    attachmentsActions.getAttachment.failure,
    (state, { payload: { attachmentId, message } }): State => ({
      ...state,
      fetchingAttachmentsById: oneInsert(
        makeLoading(LoadingState.failure, message),
        attachmentId,
        state.fetchingAttachmentsById
      ),
    })
  )
