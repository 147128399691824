import { Typography, Box, CircularProgress } from '@mui/material'
import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import makeStyles from '@mui/styles/makeStyles'

import ThemedButton from '../../components/common/ThemedButton'
import AuthLayout from '../../components/layouts/AuthLayout'
import useLoadingSuccess from '../../hooks/useLoadingSuccess'
import { RoutePath } from '../../routes'
import appointmentActions from '../../store/appointment/actions'
import {
  getAppointment,
  getCheckForPendingAppointmentLoading,
  getPendingAppointment,
} from '../../store/appointment/selectors'
import { getLoadingState } from '../../utils/types'
import gtag from '../../utils/gtag'

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.dark,
  },
}))

const CheckPendingAppt: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()

  const loading = useSelector(getCheckForPendingAppointmentLoading)

  const loadingState = getLoadingState(loading)

  const appointmentDetails = useSelector(getAppointment)

  const pendingAppointment = useSelector(getPendingAppointment)

  useEffect(() => {
    dispatch(appointmentActions.checkForPendingAppointment.request())
  }, [dispatch])

  const goToNextPage = useCallback(() => {
    history.replace(RoutePath.addSignature)
  }, [history])

  // TODO: handle errors
  useLoadingSuccess(loading, () => {
    if (pendingAppointment) {
      gtag('event', 'webView_appt_already_exist', {
        ...(pendingAppointment || {}),
        paymentOrderId: undefined,
      })
      return
    }

    goToNextPage()
  })

  const handleRescheduleClick = useCallback(() => {
    gtag('event', 'webView_appt_reschedule_click', {
      ...(pendingAppointment || {}),
      paymentOrderId: undefined,
    })

    window.alert(
      'To reschedule the consultation, please download Kyla app. Open tab "Appointments", select your consultation and click the button "Reschedule"'
    )

    window.location.href = appointmentDetails.clinic.url
  }, [appointmentDetails?.clinic, pendingAppointment])

  return (
    <AuthLayout title="">
      {loadingState.success && pendingAppointment ? (
        <>
          <Box mb={2.5}>
            <Typography className={classes.text} align="center" variant="h5">
              <b>Appointment scheduled</b>
            </Typography>
          </Box>
          <Box mb={2.5}>
            <Typography className={classes.text}>
              You have scheduled appointment on{' '}
              <b>
                {moment
                  .tz(pendingAppointment?.scheduledTime, 'America/Los_Angeles')
                  .format('MMMM D YYYY, h:mm a')}
              </b>
              . Would you like to reschedule the appointment?
            </Typography>
          </Box>

          <Box display="flex" flexDirection="row" gap={1}>
            <ThemedButton size="small" onClick={handleRescheduleClick}>
              Reschedule
            </ThemedButton>
            <ThemedButton size="small" onClick={goToNextPage}>
              New appointment
            </ThemedButton>
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
    </AuthLayout>
  )
}

export default CheckPendingAppt
