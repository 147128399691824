import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import parsePhoneNumber from 'libphonenumber-js'
import MaskedInput from 'react-text-mask'

import authActions from 'src/store/auth/actions'
import FooterButtons from '../../../components/FooterButtons'
import {
  isFailure,
  isLoading,
  isSuccess,
  LoadingContext,
} from '../../../utils/types'
import gtag from '../../../utils/gtag'
import { RoutePath } from '../../../routes'
import {
  getGeneralInfo,
  getReactivationCodeSentTime,
  getSendingReactivationCode,
} from '../../../store/auth/selectors'
import ThemedTextField from '../../../components/common/ThemedTextField'
import DisablingTicker from '../../../components/common/DisablingTicker'
import { useLoadingChange } from '../../../hooks/useLoadingChange'
import { SUPPORT_PHONE_NUMBER } from '../../../utils/general'
import { PageLayout } from '../../../components/common/page-layout'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      // padding: theme.spacing(9, 4, 4),
      height: '100%',
      position: 'relative',
    },
    content: {
      flex: 1,
    },
    bold: {
      fontWeight: 700,
    },
    text: {
      fontFamily: 'NeurialGrotesk',
    },
    subTitle: {
      lineHeight: '20px',
      fontSize: '0.875rem',
      fontWeight: 500,
      color: theme.palette.primary.dark,
    },
    title: {
      fontWeight: 700,
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      color: theme.palette.primary.dark,
      lineHeight: 1.5,
      marginBottom: theme.spacing(2),
    },
    formInput: {
      width: '100%',
      marginBottom: theme.spacing(1.25),
    },
    optionsContainer: {
      marginBottom: theme.spacing(4),
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: '0.875rem',
    },
    button: {
      fontFamily: 'NeurialGrotesk',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '20px',
      textAlign: 'center',
      color: theme.palette.primary.main,
      padding: theme.spacing(2, 2.5),
      border: `2px solid ${theme.palette.primary.light}`,
      boxSizing: 'border-box',
      borderRadius: '20px',
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    disabledButton: {
      opacity: 0.7,
      pointerEvents: 'none',
    },
    noteWrapper: {
      padding: theme.spacing(2, 2.5),
      background: 'rgba(25, 99, 198, 0.1)',
      borderRadius: '20px 20px 20px 0px',
      marginBottom: theme.spacing(1),
    },
  }),
  { name: 'ReactivateAccount' }
)

const ReactivateAccount: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const history = useHistory()

  const [error, setError] = useState('')

  const generalInfo = useSelector(getGeneralInfo)
  const reactivationCodeSentTime = useSelector(getReactivationCodeSentTime)
  const sendingReactivationCode = useSelector(getSendingReactivationCode)
  const isSendingReactivationCode = isLoading(sendingReactivationCode.state)

  const { handleSubmit, errors, control, watch } = useForm<{
    verificationCode: string
  }>({
    mode: 'onChange',
    shouldFocusError: false,
  })

  const { verificationCode } = watch()

  const handleSendingReactivationCodeChange = useCallback(
    (newLoading: LoadingContext) => {
      if (isSuccess(newLoading.state)) {
        history.push(RoutePath.createNewPassword)
      }
      if (isFailure(newLoading.state)) {
        setError(newLoading.message as string)
      }
    },
    []
  )

  useLoadingChange(handleSendingReactivationCodeChange, sendingReactivationCode)

  const handleGoNext = useCallback(
    ({ verificationCode }) => {
      setError('')
      dispatch(authActions.reactivateAccount.request({ verificationCode }))
    },
    [setError]
  )

  const handleGoBack = useCallback(() => {
    history.goBack()
  }, [history])

  const sendReactivationCode = useCallback(() => {
    dispatch(authActions.sendReactivationCode.request())
  }, [dispatch])

  useEffect(() => {
    gtag('event', 'apptstart_createnew')
    sendReactivationCode()
  }, [])

  return (
    <PageLayout>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        className={classes.root}
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          className={classes.content}
        >
          <Grid>
            <Typography className={clsx(classes.text, classes.title)}>
              Reactivate Account
            </Typography>

            <Typography className={clsx(classes.text, classes.subTitle)}>
              {`To verify your email address, please enter the verification code sent to ${generalInfo.email}.`}
            </Typography>
          </Grid>
          <Grid container direction="column" justifyContent="space-between">
            <Box className={classes.noteWrapper}>
              <Typography className={classes.subTitle}>
                In a case, you do not have access to your email address, please
                click &quot;Connect to support&quot;.
                <br />
                <br />
                <span className={classes.bold}>Note:</span> Check you
                &quot;Spam&quot; folder if you haven’t reviewed a code.
              </Typography>
            </Box>
            <Controller
              render={(props) => (
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                  render={(ref: React.Ref<any>, props: any) => (
                    <ThemedTextField
                      autoComplete="off"
                      ref={ref}
                      disabled={isSendingReactivationCode}
                      classes={{ input: classes.formInput }}
                      placeholder="Enter 6-digits Verification Code"
                      error={Boolean(errors.verificationCode)}
                      {...props}
                    />
                  )}
                  {...props}
                />
              )}
              control={control}
              name="verificationCode"
              rules={{
                required: true,
                pattern: {
                  value: /^\d{6}$/,
                  message: 'Invalid verification code',
                },
              }}
            />
            <Grid
              item
              container
              className={classes.optionsContainer}
              justifyContent="flex-end"
            >
              <DisablingTicker
                time={reactivationCodeSentTime}
                disablingTimeInSeconds={60}
              >
                {({ disabled }) => (
                  <Button
                    variant="outlined"
                    className={clsx(classes.button, {
                      [classes.disabledButton]:
                        disabled || isSendingReactivationCode,
                    })}
                    onClick={sendReactivationCode}
                  >
                    Resend code
                  </Button>
                )}
              </DisablingTicker>
              <Button
                variant="outlined"
                className={clsx(classes.button, {
                  [classes.disabledButton]: isSendingReactivationCode,
                })}
                href={parsePhoneNumber(SUPPORT_PHONE_NUMBER)?.getURI() || ''}
              >
                Call Support
              </Button>

              {Boolean(error) && (
                <Grid>
                  <Typography className={clsx(classes.text, classes.errorText)}>
                    {error}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <FooterButtons
            nextButtonLabel="Continue"
            backButtonLabel="Back"
            onNextButtonClick={handleSubmit(handleGoNext)}
            onBackButtonClick={handleGoBack}
            disableNext={!verificationCode}
            loadingNext={isSendingReactivationCode}
            disableBack={isSendingReactivationCode}
          />
        </Grid>
      </Grid>
    </PageLayout>
  )
}

export default ReactivateAccount
