import React from 'react'
import { useHistory } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles'
import { Box } from '@mui/material'

import FooterButtons from '../components/FooterButtons'
import AuthLayout from '../components/layouts/AuthLayout'
import DetailedInsuranceInfoCard from './createAppointment/DetailedInsuranceInfoCard'

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    overflow: 'auto',
  },
}))

const DetailedInsuranceInfo: React.FC = () => {
  const classes = useStyles()

  const history = useHistory()

  return (
    <AuthLayout
      title="Insurance Details"
      bottomActions={
        <FooterButtons
          nextOnly
          nextButtonLabel="Back"
          onNextButtonClick={() => history.goBack()}
        />
      }
      contentClass={classes.content}
    >
      <Box>
        <DetailedInsuranceInfoCard withExpansion />
      </Box>
    </AuthLayout>
  )
}

export default DetailedInsuranceInfo
