import React, { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  debounce,
  Grid,
  Theme,
  Typography,
  Divider,
  LinearProgress,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import ThemedTextField from '../components/common/ThemedTextField'
import { Clinic } from '../store/clinics/models'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      borderRadius: '0 0 40px 40px',
      backgroundColor: theme.palette.primary.light,
      overflow: 'auto',
    },
    title: {
      fontFamily: 'NeurialGrotesk',
      fontWeight: 500,
      fontSize: '1.125rem',
      lineHeight: 1.3,
      color: theme.palette.primary.dark,
    },
    filterInput: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    infoContainer: {
      margin: theme.spacing(2.5, 0),
      cursor: 'pointer',
    },
    infoText: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '0.875rem',
      lineHeight: 1.3,
      color: theme.palette.primary.main,
    },
    progress: {
      height: 2,
      position: 'absolute',
      top: 0,
      left: 15,
      right: 15,
    },
    entriesWrapper: {
      flex: 1,
      overflow: 'auto',
      padding: theme.spacing(1, 4.5, 1),
    },
    filterWrapper: {
      padding: theme.spacing(3, 4.5, 0),
    },
  }),
  { name: 'ClinicsListContainer' }
)

interface ClinicsListContainerProps {
  onClinicSelect: (clinic: Clinic) => void
  clinics: Clinic[]
  isLoading: boolean
}

const getSearchedClinics = (
  name: string,
  address: string,
  filterQuery: string
) => {
  const nonSensitiveName = name.toLowerCase()
  const nonSensitiveAddress = address.toLowerCase()
  const nonSensitiveQuery = filterQuery.toLowerCase()

  return (
    nonSensitiveName.includes(nonSensitiveQuery) ||
    nonSensitiveAddress.includes(nonSensitiveQuery)
  )
}

const ClinicsListContainer: React.FC<ClinicsListContainerProps> = ({
  clinics,
  isLoading,
  onClinicSelect,
}) => {
  const classes = useStyles()

  const [filterValue, setFilterValue] = useState<string>('')

  const [filteredClinics, updateFilteredClinics] = useState<Clinic[]>(clinics)

  useEffect(() => {
    if (!isEmpty(clinics)) {
      updateFilteredClinics(clinics)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(clinics)])

  const handleGetFilteredCompanies = useCallback(
    debounce((filterQuery: string, clinics: Clinic[]) => {
      if (!filterQuery) {
        updateFilteredClinics(clinics)
      } else {
        const filteredClinics: Clinic[] = clinics.filter(({ name, address }) =>
          getSearchedClinics(name, address, filterQuery)
        )
        updateFilteredClinics(filteredClinics)
      }
    }, 300),
    []
  )

  return (
    <Grid container item xs className={classes.root} direction="column">
      {isLoading && <LinearProgress className={classes.progress} />}
      <Grid className={classes.filterWrapper}>
        <Grid item>
          <Typography className={classes.title}>
            Select a clinic or type a city
          </Typography>
        </Grid>
        <Grid item>
          <ThemedTextField
            disabled={isLoading}
            classes={{ input: classes.filterInput }}
            value={filterValue}
            onChange={(event) => {
              setFilterValue(event.target.value)
              handleGetFilteredCompanies(event.target.value, clinics)
            }}
            variant="outlined"
            color="primary"
            placeholder="Type here"
          />
        </Grid>
      </Grid>
      <Grid item className={classes.entriesWrapper}>
        {filteredClinics.map((clinic, index) => (
          <React.Fragment key={clinic.id}>
            <Grid
              className={classes.infoContainer}
              onClick={() => onClinicSelect(clinic)}
            >
              <Typography className={classes.infoText}>
                {clinic.name}
              </Typography>
              <Typography className={classes.infoText}>
                {clinic.address}
              </Typography>
            </Grid>
            {clinics[index + 1] && <Divider />}
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  )
}

export default ClinicsListContainer
