import React, { useCallback, useRef, useMemo } from 'react'
import { Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { grey } from '@mui/material/colors'

import AuthLayout from '../../../components/layouts/AuthLayout'
import AddressForm, {
  AddressFormActions,
  AddressFormProps,
} from '../../../components/forms/AddressForm'
import FooterButtons from '../../../components/FooterButtons'
import useLoadingSuccess from '../../../hooks/useLoadingSuccess'
import { getUpdateProfileSubmitting } from '../../../store/auth/selectors'
import { isLoading } from '../../../utils/types'
import authActions from '../../../store/auth/actions'
import { RoutePath } from '../../../routes'
import useLoadingFailure from '../../../hooks/useLoadingFailure'
import { extractErrorMessage } from '../../../utils/errors'
import gtag from '../../../utils/gtag'
import { useIsLabOrderingFlow } from '../../../hooks/labOrdering/useIsLabOrderingFlow'
import {
  confirmLabOrderDraftAction,
  updateLabOrderDraftAddressAction,
  updateLabOrderDraftBillingTypeAction,
} from '../../../store/testOrdering'
import { useLabOrderDraftCreate } from '../../../hooks/labOrdering/useLabOrderDraftCreate'
import {
  getConfirmingLabOrderDraft,
  getUpdatingLabOrderDraftAddress,
  getUpdatingLabOrderDraftBillingType,
} from '../../../store/testOrdering/selectors'
import useLoadingFailureAlert from '../../../hooks/useLoadingFailureAlert'

const useStyles = makeStyles(() => ({
  note: {
    color: grey[600],
  },
}))

const AddressInformation: React.FC = () => {
  const addressFormRef = useRef<AddressFormActions>(null)

  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const { labOrderDraft } = useLabOrderDraftCreate()

  const updatingProfile = useSelector(getUpdateProfileSubmitting)

  const updatingLabOrderDraftAddress = useSelector(
    getUpdatingLabOrderDraftAddress
  )

  const updatingLabOrderDraftBillingType = useSelector(
    getUpdatingLabOrderDraftBillingType
  )

  const confirmingLabOrderDraft = useSelector(getConfirmingLabOrderDraft)

  const triggerSubmit = useCallback(() => {
    if (!addressFormRef.current) {
      return
    }

    addressFormRef.current.submit()
  }, [])

  const handleSubmit = useCallback<AddressFormProps['onSubmit']>(
    (values) => {
      if (isLabOrderingFlow && labOrderDraft) {
        dispatch(
          updateLabOrderDraftAddressAction({
            address: values,
            draftId: labOrderDraft.id,
          })
        )
      } else {
        dispatch(authActions.updateProfile.request(values))
      }
    },
    [dispatch, labOrderDraft, isLabOrderingFlow]
  )

  useLoadingFailure(updatingProfile, ({ error }) => {
    const message = extractErrorMessage(error)

    gtag('event', 'webView_signup_address_failed', {
      message,
    })

    dispatch(authActions.clearPatientAddressDetails())

    alert(message)
  })

  useLoadingSuccess(updatingProfile, () => {
    history.push(RoutePath.createMedicalAccount)
  })

  useLoadingSuccess(updatingLabOrderDraftAddress, () => {
    dispatch(
      updateLabOrderDraftBillingTypeAction({
        billingType: 'SELF_PAY',
        draftId: labOrderDraft!.id,
      })
    )
  })

  useLoadingSuccess(updatingLabOrderDraftBillingType, () => {
    dispatch(confirmLabOrderDraftAction(labOrderDraft!.id))
  })

  useLoadingSuccess(confirmingLabOrderDraft, () => {
    history.push(RoutePath.choosePaymentCard)
  })

  useLoadingFailureAlert(updatingLabOrderDraftAddress)

  useLoadingFailureAlert(updatingLabOrderDraftBillingType)

  useLoadingFailureAlert(confirmingLabOrderDraft)

  const pageIsLoading = useMemo(
    () =>
      isLoading(updatingProfile.state) ||
      isLoading(updatingLabOrderDraftAddress.state) ||
      isLoading(updatingLabOrderDraftBillingType.state) ||
      isLoading(confirmingLabOrderDraft.state),
    [
      updatingProfile,
      updatingLabOrderDraftAddress,
      updatingLabOrderDraftBillingType,
      confirmingLabOrderDraft,
    ]
  )

  return (
    <>
      <AuthLayout
        title={
          isLabOrderingFlow && labOrderDraft
            ? 'Shipping Address'
            : 'Address Information'
        }
        bottomActions={
          <>
            <FooterButtons
              nextOnly
              nextButtonLabel="Continue"
              onNextButtonClick={triggerSubmit}
              loadingNext={pageIsLoading}
            />
          </>
        }
      >
        <AddressForm
          ref={addressFormRef}
          disabled={pageIsLoading}
          withPreselection={Boolean(isLabOrderingFlow && labOrderDraft)}
          onSubmit={handleSubmit}
        />
        <Box mt={2}>
          <Typography className={classes.note} variant="body2">
            <b>Please note:</b> Kyla is not responsible if the information is
            entered incorrectly.
          </Typography>
        </Box>
      </AuthLayout>
    </>
  )
}

export default AddressInformation
