import { useCallback, useState } from 'react'
import moment, { Moment } from 'moment/moment'

interface UseControlSlots {
  dateRange: {
    current: Moment
    next: Moment
  }
  handleShowPrev(): void
  handleShowNext(): void
}

export const useControlSlots = (): UseControlSlots => {
  const [dateRange, updateDateRange] = useState<{
    current: Moment
    next: Moment
  }>({
    current: moment(),
    next: moment().add(1, 'day'),
  })

  const handleShowPrev = useCallback(
    () =>
      updateDateRange((range) => ({
        next: range.next.clone().subtract(2, 'day'),
        current: range.current.clone().subtract(2, 'day'),
      })),
    [updateDateRange]
  )

  const handleShowNext = useCallback(
    () =>
      updateDateRange((range) => ({
        current: range.current.clone().add(2, 'day'),
        next: range.next.clone().add(2, 'day'),
      })),
    [updateDateRange]
  )

  return { dateRange, handleShowPrev, handleShowNext }
}
