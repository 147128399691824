import { AxiosError } from 'axios'

import { createAction, createAsyncAction } from 'typesafe-actions'
import { Clinic } from '../clinics/models'
import {
  Appointment,
  AvailableSlotsInfo,
  CopayInfo,
  InClinicSlot,
  PaymentMethod,
  ReasonForConsultation,
  ScheduledAppointment,
} from './models'
import { ServerErrorResponse } from '../../utils/api/types'
import { IndicatedInsuranceStatus, IndicatedPaymentMethod } from './types'
import { Token } from '@stripe/stripe-js'

const actions = {
  updateClinic: createAction('appointment/UPDATE_CLINIC')<{
    clinic: Clinic
  }>(),
  updateAppointmentTime: createAction('appointment/UPDATE_APPOINTMENT_TIME')<{
    dateTime: InClinicSlot
  }>(),
  clearAppointmentTime: createAction('appointment/CLEAR_APPOINTMENT_TIME')<
    void
  >(),
  markApptAsSymptomatic: createAction('appointment/MARK_APPT_AS_SYMPTOMATIC')<
    boolean
  >(),
  updateReason: createAction('appointment/UPDATE_APPOINTMENT_REASON')<{
    reason: string
  }>(),
  preselectAppointment: createAction('appointment/PRESELECT_APPOINTMENT')<{
    appointment: Partial<Appointment>
  }>(),
  preselectSourceWidgetType: createAction(
    'appointment/PRESELECT_SOURCE_WIDGET_TYPE'
  )<{
    sourceWidgetType: string
  }>(),
  getAvailableSlots: createAsyncAction(
    'appointment/GET_SLOTS',
    'appointment/GET_SLOTS_SUCCESS',
    'appointment/GET_SLOTS_FAILURE'
  )<
    {
      currentDate: string
      clinicId?: number
      examRoom?: number
      virtualVisit: boolean
    },
    {
      slotsInfo: AvailableSlotsInfo
    },
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
  getInClinicAvailableSlots: createAsyncAction(
    'appointment/GET_IN_CLINIC_SLOTS',
    'appointment/GET_IN_CLINIC_SLOTS_SUCCESS',
    'appointment/GET_IN_CLINIC_SLOTS_FAILURE'
  )<
    {
      currentDate: string
    },
    {
      slotsInfo: AvailableSlotsInfo
    },
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
  getClinicByZip: createAsyncAction(
    'appointment/GET_CLINIC_BY_ZIP',
    'appointment/GET_CLINIC_BY_ZIP_SUCCESS',
    'appointment/GET_CLINIC_BY_ZIP_FAILURE'
  )<
    { zipCode: number },
    { eligible: boolean },
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
  cancelAppointment: createAsyncAction(
    'appointment/CANCEL_APPOINTMENT',
    'appointment/CANCEL_APPOINTMENT_SUCCESS',
    'appointment/CANCEL_APPOINTMENT_FAILURE'
  )<{ appointmentId: number }, void, { error: Error }>(),
  addWillNotUseMobilePhoneFlag: createAction(
    'appointment/ADD_WILL_NOT_USE_MOBILE_PHONE_FLAG'
  )<{
    flag: boolean
  }>(),
  setIndicatedInsuranceStatus: createAction(
    'appointment/SET_INDICATED_INSURANCE_STATUS'
  )<IndicatedInsuranceStatus>(),
  setSelectedPaymentMethod: createAction(
    'appointment/SET_SELECTED_PAYMENT_METHOD'
  )<IndicatedPaymentMethod>(),

  getStripePublicKey: createAsyncAction(
    'appointment/GET_STRIPE_PUBLIC_KEY_REQUEST',
    'appointment/GET_STRIPE_PUBLIC_KEY_SUCCESS',
    'appointment/GET_STRIPE_PUBLIC_KEY_FAILURE'
  )<void, string, { error: Error }>(),

  createPaymentToken: createAsyncAction(
    'appointment/CREATE_PAYMENT_TOKEN_REQUEST',
    'appointment/CREATE_PAYMENT_TOKEN_SUCCESS',
    'appointment/CREATE_PAYMENT_TOKEN_FAILURE'
  )<
    { number: string; expiration: string; cvv: string },
    Token,
    { error: Error }
  >(),
  savePaymentMethod: createAsyncAction(
    'appointment/CREATE_PAYMENT_METHOD_REQUEST',
    'appointment/CREATE_PAYMENT_METHOD_SUCCESS',
    'appointment/CREATE_PAYMENT_METHOD_FAILURE'
  )<Token & { saveCard: boolean }, string, { error: any }>(),
  selectPaymentMethod: createAction('appointment/SELECT_PAYMENT_METHOD')<{
    paymentMethod: PaymentMethod['collectlyId']
  }>(),
  scheduleAppointment: createAsyncAction(
    'appointment/SCHEDULE_APPOINTMENT_REQUEST',
    'appointment/SCHEDULE_APPOINTMENT_SUCCESS',
    'appointment/SCHEDULE_APPOINTMENT_FAILURE'
  )<void, ScheduledAppointment, { error: any }>(),
  checkApptPaymentStatus: createAsyncAction(
    'appointments/CHECK_APPT_ASYNC_TASK_STATUS',
    'appointments/CHECK_APPT_ASYNC_TASK_STATUS_SUCCESS',
    'appointments/CHECK_APPT_ASYNC_TASK_STATUS_FAILURE'
  )<
    {
      asyncTaskId: number
    },
    { appointmentId: ScheduledAppointment['id'] },
    { error: any }
  >(),
  payForAppointment: createAsyncAction(
    'appointment/PAY_FOR_APPT_REQUEST',
    'appointment/PAY_FOR_APPT_SUCCESS',
    'appointment/PAY_FOR_APPT_FAILURE'
  )<void, void, { error: any }>(),

  checkForPendingAppointment: createAsyncAction(
    'appointment/CHECK_FOR_PENDING_APPT_REQUEST',
    'appointment/CHECK_FOR_PENDING_APPT_SUCCESS',
    'appointment/CHECK_FOR_PENDING_APPT_FAILURE'
  )<void, ScheduledAppointment | null, { error: any }>(),

  getProductById: createAsyncAction(
    'appointment/GET_PRODUCT_BY_ID_REQUEST',
    'appointment/GET_PRODUCT_BY_ID_SUCCESS',
    'appointment/GET_PRODUCT_BY_ID_FAILURE'
  )<
    string,
    { productId: string; product: { nickname: string; priceDecimal: number } },
    { productId: string; error: any }
  >(),
  getPaymentMethods: createAsyncAction(
    'appointment/GET_PAYMENT_METHODS',
    'appointment/GET_PAYMENT_METHODS_SUCCESS',
    'appointment/GET_PAYMENT_METHODS_FAILURE'
  )<void, { methods: PaymentMethod[] }, { error: Error }>(),
  getCopayStatus: createAsyncAction(
    'appointment/GET_COPAY_STATUS',
    'appointment/GET_COPAY_STATUS_SUCCESS',
    'appointment/GET_COPAY_STATUS_FAILURE'
  )<{ isVirtual: boolean }, { copayStatus: boolean }, { error: Error }>(),
  getCopayInfo: createAsyncAction(
    'appointment/GET_COPAY_INFO',
    'appointment/GET_COPAY_INFO_SUCCESS',
    'appointment/GET_COPAY_INFO_FAILURE'
  )<{ isVirtual: boolean }, { copayInfo: CopayInfo }, { error: Error }>(),
  getReasonsForConsultation: createAsyncAction(
    'appointment/GET_REASONS_FOR_CONSULTATION',
    'appointment/GET_REASONS_FOR_CONSULTATION_SUCCESS',
    'appointment/GET_REASONS_FOR_CONSULTATION_FAILURE'
  )<
    { consultationType: string },
    { reasonsForConsultation: ReasonForConsultation[] },
    { error: Error }
  >(),
}

export default actions
