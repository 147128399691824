import React, { useCallback, useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import clsx from 'clsx'

import FooterButtons from '../../components/FooterButtons'
import AuthLayout from '../../components/layouts/AuthLayout'
import appointmentActions from '../../store/appointment/actions'
import {
  getAppointmentProductId,
  getCopayInfoSelector,
  getFetchingCopayAmount,
  getScheduleApptLoading,
  getSourceWidgetType,
  useProductById,
} from '../../store/appointment/selectors'
import Centered from '../../components/common/Centered'
import { getLoadingState } from '../../utils/types'
import { getAbsoluteUrl } from '../../utils/general'
import Copay from '../../assets/icons/Copay.svg'
import { IndicatedPaymentMethod } from '../../store/appointment/types'
import { RoutePath } from '../../routes'
import useLoadingSuccess from '../../hooks/useLoadingSuccess'
import useLoadingFailureAlert from '../../hooks/useLoadingFailureAlert'
import { TelemedicineWidgetTypes } from '../../utils/appointment/general'
import { useIsLabOrderingFlow } from '../../hooks/labOrdering/useIsLabOrderingFlow'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
  },
  text: {
    fontFamily: 'NeurialGrotesk',
    color: theme.palette.primary.dark,
  },
  linkText: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginTop: theme.spacing(0.5),
    alignSelf: 'self-start',
  },
  wrapper: {
    padding: theme.spacing(1.5, 2),
    background: theme.palette.background.default,
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      border: `2px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
    },
  },
  selectedWrapper: {
    backgroundColor: theme.palette.primary.light,
    border: `2px solid ${theme.palette.primary.main}`,
  },
}))

const CopayPayment: React.FC = () => {
  const dispatch = useDispatch()

  const classes = useStyles()
  const history = useHistory()

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    IndicatedPaymentMethod.insurance
  )

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const scheduleApptLoading = useSelector(getScheduleApptLoading)

  const fetchingCopayAmount = useSelector(getFetchingCopayAmount)
  const copayInfo = useSelector(getCopayInfoSelector)

  const appointmentProductId = useSelector(getAppointmentProductId)
  const { product: consultationProduct } = useProductById(appointmentProductId)

  const sourceWidgetType = useSelector(getSourceWidgetType)

  // Go back for twice to avoid cycle
  const handleBackClick = useCallback(() => {
    history.go(-2)
  }, [history])

  const getCopayInfo = () => {
    dispatch(
      appointmentActions.getCopayInfo.request({
        isVirtual: TelemedicineWidgetTypes.includes(sourceWidgetType),
      })
    )
  }

  useEffect(getCopayInfo, [])

  const { pending, success, failure } = getLoadingState(fetchingCopayAmount)
  const { pending: schedulingAppt } = getLoadingState(scheduleApptLoading)

  const handlePayWithCash = useCallback(() => {
    dispatch(
      appointmentActions.setSelectedPaymentMethod(IndicatedPaymentMethod.cash)
    )

    history.push(
      isLabOrderingFlow
        ? RoutePath.chooseConsultationExistence
        : RoutePath.createAppointment
    )
  }, [dispatch, history, isLabOrderingFlow])

  const handlePayWithInsurance = useCallback(() => {
    dispatch(
      appointmentActions.setSelectedPaymentMethod(
        IndicatedPaymentMethod.insurance
      )
    )

    history.replace(
      isLabOrderingFlow
        ? RoutePath.appointmentTime
        : RoutePath.createAppointment
    )
  }, [dispatch, history, isLabOrderingFlow])

  const handleSubmit = useCallback(() => {
    if (selectedPaymentMethod === IndicatedPaymentMethod.insurance) {
      handlePayWithInsurance()
    } else {
      handlePayWithCash()
    }
  }, [selectedPaymentMethod, handlePayWithCash, handlePayWithInsurance])

  useLoadingSuccess(scheduleApptLoading, () =>
    history.replace(RoutePath.choosePaymentCard)
  )

  useLoadingFailureAlert(scheduleApptLoading)

  return (
    <AuthLayout
      title="Insurance Verified"
      bottomActions={
        <FooterButtons
          nextButtonLabel="Next"
          backButtonLabel="Back"
          onNextButtonClick={handleSubmit}
          onBackButtonClick={handleBackClick}
          disableBack={schedulingAppt}
          loadingNext={schedulingAppt}
        />
      }
      contentClass={classes.content}
    >
      {success ? (
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box mb={6}>
            <img src={getAbsoluteUrl(Copay)} alt="copay" />
          </Box>

          <Typography className={classes.text}>
            Tap Next to continue with Insurance or select ‘Self-Pay’ to pay out
            of pocket.
          </Typography>

          <Typography
            className={classes.linkText}
            onClick={() => history.push(RoutePath.insuranceDetailedInfo)}
          >
            View My Insurance Details
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            mt={3}
            gap={2}
            width="100%"
          >
            <Box
              display="flex"
              alignItems="center"
              className={clsx(classes.wrapper, {
                [classes.selectedWrapper]:
                  selectedPaymentMethod === IndicatedPaymentMethod.insurance,
              })}
              onClick={() =>
                setSelectedPaymentMethod(IndicatedPaymentMethod.insurance)
              }
            >
              <Typography className={classes.text}>
                {`Insurance ${
                  copayInfo!.isDeductible ? 'Deductible' : 'Copay'
                } ($${copayInfo!.paymentAmount})`}
              </Typography>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              className={clsx(classes.wrapper, {
                [classes.selectedWrapper]:
                  selectedPaymentMethod === IndicatedPaymentMethod.cash,
              })}
              onClick={() =>
                setSelectedPaymentMethod(IndicatedPaymentMethod.cash)
              }
            >
              <Typography className={classes.text}>
                {`Self-Pay ($${consultationProduct?.priceDecimal})`}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Centered>
          {pending && <CircularProgress />}
          {failure && (
            <Box display="flex" flexDirection="column">
              <Typography gutterBottom>
                Something went wrong, please try again.
              </Typography>
              <Button onClick={getCopayInfo}>Reload</Button>
            </Box>
          )}
        </Centered>
      )}
    </AuthLayout>
  )
}

export default CopayPayment
