import { ActionType, createReducer } from 'typesafe-actions'
import { uniq } from 'lodash/fp'

import { Clinic } from 'src/store/clinics/models'
import clinicsActions from 'src/store/clinics/actions'
import { ById } from 'src/utils/types'
import { oneInsert } from '../../mutators/relations'

interface State {
  list: Array<Clinic['id']>
  byId: ById<Clinic>
}

const initialState = {
  byId: {},
  list: [],
}

export default createReducer<State, ActionType<typeof clinicsActions>>(
  initialState
)
  .handleAction(
    clinicsActions.getAll.success,
    (state, { payload: { clinics } }): State => {
      const byId = clinics.reduce(
        (acc, clinic) => oneInsert(clinic, clinic.id, acc),
        state.byId
      )
      const currentItemsList = clinics.map((item) => item.id)
      const list = uniq([...state.list, ...currentItemsList])

      return { ...state, byId, list }
    }
  )

  .handleAction(
    clinicsActions.getClinicById.success,
    (state, { payload: { clinicInfo } }): State => {
      const byId = oneInsert(clinicInfo, clinicInfo.id, state.byId)
      const list = uniq([...state.list, clinicInfo.id])

      return { ...state, byId, list }
    }
  )
