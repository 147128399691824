import React from 'react'
import { Box, Theme, Typography } from '@mui/material'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'

import ProgressCircleWithContent from '../../components/common/ProgressCircleWIthContent'
import ClipboardListFailure from '../../assets/icons/ClipboardListFailure.svg'
import { getAbsoluteUrl } from '../../utils/general'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(15, 3.5, 6),
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
      fontWeight: 500,
    },
    title: {
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '35px',
      textAlign: 'center',
    },
    staticCircle: {
      color: '#E17F8E',
    },
    circleWithIcon: {
      margin: theme.spacing(5, 0, 8),
    },
  }),
  { name: 'CreateMedicalAccountFailure' }
)

const CreateMedicalAccountFailure: React.FC = () => {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <Typography className={clsx(classes.text, classes.title)}>
        Failure When Creating <br /> Medical Account
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center">
        <ProgressCircleWithContent
          classes={{
            root: classes.circleWithIcon,
            staticCircle: classes.staticCircle,
          }}
          content={<img src={getAbsoluteUrl(ClipboardListFailure)} alt="" />}
        />
      </Box>

      <Typography className={clsx(classes.text)}>
        An existing account has been found with the information you have
        entered. To avoid creating a duplicate account, please contact our
        support team at <a href="tel:888-710-4044">888-710-4044</a> or
        <a href="mailto: support@kyla.com"> support@kyla.com</a>.
      </Typography>
    </Box>
  )
}

export default CreateMedicalAccountFailure
