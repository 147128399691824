import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input'
import {
  FormControl,
  FormHelperText,
  Grid,
  Link,
  Theme,
  Typography,
  Stack,
  Box,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import moment from 'moment'
import ThemedTextField from '../common/ThemedTextField'
import ThemedSelect from '../common/ThemedSelect'
import IOSSwitch from '../common/IOSSwitch'
import ThemedDatePicker from '../common/ThemedDatePicker'
import {
  validateMaxDob,
  validateMinDob,
  validateName,
} from '../../utils/general'
import { PatientGender } from '../../utils/labOrdering'
import { capitalize } from 'lodash'
import { emailRegExp } from 'src/utils/consts/validation'

const MAX_PHONE_LENGTH = 16

const useStyles = makeStyles(
  (theme: Theme) => ({
    formControl: {
      width: '100%',
    },
    switchControllers: {
      marginTop: theme.spacing(2),
    },
    controllersTitle: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '1.125rem',
      lineHeight: 1.5,
      color: theme.palette.primary.dark,
      fontWeight: 500,
      marginBottom: theme.spacing(1),
    },
    link: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '0.875rem',
      lineHeight: 1.25,
      color: theme.palette.primary.dark,
      cursor: 'pointer',
    },
    iconButtonRoot: {
      color: theme.palette.primary.dark,
    },
  }),
  { name: 'NewAccountForm' }
)

export type NewAccountFormValues = {
  firstName: string
  lastName: string
  dob: string
  gender: string
  email: string
  phoneNumber: string

  tos: boolean
}

interface NewAccountFormProps {
  onSubmit(data: NewAccountFormValues): void
  enableNextButton(): void
  disableNextButton(): void
  defaultValues: NewAccountFormValues
  disabled: boolean
}

const NewAccountForm = React.forwardRef<any, NewAccountFormProps>(
  (
    { onSubmit, enableNextButton, disableNextButton, defaultValues, disabled },
    ref: React.Ref<any>
  ) => {
    const classes = useStyles()

    const {
      register,
      handleSubmit,
      errors,
      getValues,
      control,
      watch,
    } = useForm<NewAccountFormValues>({
      mode: 'onChange',
      shouldFocusError: false,
      defaultValues: {
        ...defaultValues,
        phoneNumber: defaultValues.phoneNumber || '+1',
      },
    })

    const { gender, dob, tos } = watch()

    useEffect(() => {
      if (tos) {
        enableNextButton()
      } else {
        disableNextButton()
      }
    }, [tos])

    const handleNewAccountSubmit = useCallback(() => {
      const data = getValues()
      onSubmit(data)
    }, [getValues, onSubmit])

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(handleNewAccountSubmit),
    }))

    return (
      <Stack spacing={1.5} overflow="auto" maxHeight={550}>
        <ThemedTextField
          disabled={disabled}
          name="email"
          autoComplete="off"
          readOnly
          inputRef={register({
            required: true,
            pattern: {
              value: emailRegExp,
              message: 'Invalid email address',
            },
          })}
          color="primary"
          placeholder="Email"
          error={Boolean(errors.email)}
        />

        <FormControl className={classes.formControl}>
          <ThemedTextField
            disabled={disabled}
            name="firstName"
            autoComplete="off"
            inputRef={register({
              validate: (value) => validateName(value, 'First Name'),
            })}
            color="primary"
            placeholder="First Name"
            error={Boolean(errors.firstName)}
          />
          {Boolean(errors.firstName?.message) && (
            <FormHelperText style={{ color: 'red' }}>
              {errors.firstName?.message}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.formControl}>
          <ThemedTextField
            disabled={disabled}
            name="lastName"
            autoComplete="off"
            inputRef={register({
              validate: (value) => validateName(value, 'Last Name'),
            })}
            color="primary"
            placeholder="Last Name"
            error={Boolean(errors.lastName)}
          />

          {Boolean(errors.lastName?.message) && (
            <FormHelperText style={{ color: 'red' }}>
              {errors.lastName?.message}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.formControl}>
          <Controller
            as={
              <ThemedSelect
                disabled={disabled}
                placeholder="Sex"
                values={Object.entries(PatientGender).map(([key, value]) => ({
                  name: capitalize(key),
                  id: value,
                }))}
                error={Boolean(errors.gender)}
              />
            }
            control={control}
            name="gender"
            defaultValue={gender || ''}
            rules={{ required: 'Biological Sex is required' }}
          />

          {Boolean(errors.gender?.message) && (
            <FormHelperText style={{ color: 'red' }}>
              {errors.gender?.message}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.formControl}>
          <Controller
            as={
              <ThemedDatePicker
                disabled={disabled}
                error={Boolean(errors.dob)}
                maxDate={moment()
                  .subtract(18, 'years')
                  .toDate()}
              />
            }
            control={control}
            name="dob"
            defaultValue={dob || null}
            rules={{
              required: 'Date of birth is required',
              validate: {
                validDate: (value: string) => {
                  if (!moment(value).isValid()) {
                    return 'Invalid date'
                  }

                  if (!validateMinDob(value)) {
                    return 'You must create an account for parent/guardian'
                  }

                  if (!validateMaxDob(value)) {
                    return 'Minimum allowed date is 01/01/1900'
                  }

                  return true
                },
              },
            }}
          />

          {Boolean(errors.dob?.message) && (
            <FormHelperText style={{ color: 'red' }}>
              {errors.dob?.message}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.formControl}>
          <Controller
            render={({ ref, value, onChange }) => (
              <PhoneInput
                {...{ ref, value, onChange }}
                placeholder="Enter your phone number"
                inputComponent={ThemedTextField}
                onInput={({ target }: ChangeEvent<HTMLInputElement>) => {
                  if (target.value.length > MAX_PHONE_LENGTH) {
                    target.value = target.value.slice(0, MAX_PHONE_LENGTH)
                  }
                }}
                error={Boolean(errors.phoneNumber)}
              />
            )}
            name="phoneNumber"
            control={control}
            rules={{
              required: 'Phone number is required',
              validate: {
                validPhoneNumber: (value: string) => {
                  if (!isValidPhoneNumber(value)) {
                    return 'Invalid phone number'
                  }

                  return true
                },
              },
            }}
          />
          {Boolean(errors.phoneNumber?.message) && (
            <FormHelperText style={{ color: 'red' }}>
              {errors.phoneNumber?.message}
            </FormHelperText>
          )}
        </FormControl>

        <Grid
          className={classes.switchControllers}
          container
          direction="column"
        >
          <Typography className={classes.controllersTitle}>
            HIPAA & Privacy
          </Typography>

          <Grid container alignItems="center">
            <Controller
              name="tos"
              control={control}
              render={(props) => (
                <IOSSwitch
                  disabled={disabled}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    props.onChange(e.target.checked)
                  }
                  checked={props.value || false}
                />
              )}
            />
            <Box>
              <Link
                target="_blank"
                className={classes.link}
                onClick={() =>
                  window.open('https://kyla.com/p/tos-consents/', '_blank')
                }
              >
                I accept all of Kyla's policies
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    )
  }
)

export default NewAccountForm
