import React, { useCallback } from 'react'
import { Grid, Theme, Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

import FooterButtons from '../../components/FooterButtons'
import gtag from '../../utils/gtag'
import { RoutePath } from '../../routes'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(6, 0, 4),
      flex: 1,
    },
    headerContainer: {
      padding: theme.spacing(0, 4, 2),
    },
    info: {
      fontFamily: 'Arial',
      color: theme.palette.primary.dark,
      lineHeight: 1.4,
    },
    title: {
      fontWeight: 'bold',
      fontSize: '2.25rem',
      letterSpacing: '-1px',
    },
    subTitle: {
      fontFamily: 'NeurialGrotesk',
      fontWeight: 700,
      fontSize: '1.375rem',
      lineHeight: 1.3,
      color: theme.palette.primary.dark,
      padding: theme.spacing(0, 4),
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      fontWeight: 500,
      fontSize: '1.125rem',
      lineHeight: 1.3,
      color: theme.palette.primary.dark,
      padding: theme.spacing(0, 4),
    },
    content: {
      flex: 1,
      overflow: 'auto',
      marginBottom: theme.spacing(3),
    },
    mainInfo: {
      marginTop: theme.spacing(5),
    },
    listItem: {
      marginTop: theme.spacing(1),
    },
    actions: {
      padding: theme.spacing(0, 4),
    },
  }),
  { name: 'NoAppointmentsAvailable' }
)

const NoAppointmentsAvailable: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  const handleDownloadApp = useCallback(() => {
    window.open('https://onelink.to/vqjesa', '_blank')
    gtag('event', 'apptstart_getapp')
  }, [])

  const handleOpenSymptomaticPage = useCallback(() => {
    history.push({
      pathname: RoutePath.selectedClinicHome,
    })
  }, [])

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      <Grid container direction="column" className={classes.content}>
        <Grid className={classes.headerContainer}>
          <Typography className={clsx(classes.title, classes.info)}>
            No Appointments Available
          </Typography>
        </Grid>
        <Typography className={classes.text}>
          Due to the high demand for COVID-19 testing, appointments are only
          available for symptomatic patients.
        </Typography>

        <Box className={classes.mainInfo}>
          <Typography className={classes.subTitle}>
            Asymptomatic Patients Must:
          </Typography>

          <Typography className={clsx(classes.text, classes.listItem)}>
            1. Download the Kyla App.
          </Typography>

          <Typography className={clsx(classes.text, classes.listItem)}>
            2. Complete the “Get a No-Cost Covid Test” task.
          </Typography>

          <Typography className={clsx(classes.text, classes.listItem)}>
            3. Walk into any clinic and show the nurse your QR code in the app.
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <FooterButtons
          classes={{ root: classes.actions }}
          nextButtonLabel="Download Kyla App"
          backButtonLabel="Back"
          onNextButtonClick={handleDownloadApp}
          onBackButtonClick={handleOpenSymptomaticPage}
        />
      </Grid>
    </Grid>
  )
}

export default NoAppointmentsAvailable
