import React, { useCallback } from 'react'
import { Theme, Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import { useHistory } from 'react-router-dom'
import ProgressCircleWithContent from '../../components/common/ProgressCircleWIthContent'
import CardIcon from '../../assets/icons/creditCardIcons/CardIconPrimary.svg'
import ThemedButton from '../../components/common/ThemedButton'
import { RoutePath } from '../../routes'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(8, 3.5, 6),
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
    },
    title: {
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '35px',
      textAlign: 'center',
    },
    staticCircle: {
      color: '#B7CEEF',
    },
    progressCircle: {
      color: '#6C9BDA',
    },
    circleWithIcon: {
      margin: theme.spacing(5, 0, 8),
    },
  }),
  { name: 'InsuranceManualVerificationSuccess' }
)

const InsuranceManualVerificationSuccess: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  const handleProceedClick = useCallback(() => {
    history.push(RoutePath.choosePaymentMethod)
  }, [history])

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <Box display="flex" flexDirection="column" flex={1}>
        <Typography className={clsx(classes.text, classes.title)}>
          Insurance Verified
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center">
          <ProgressCircleWithContent
            withProgress
            classes={{
              root: classes.circleWithIcon,
              staticCircle: classes.staticCircle,
              progressCircle: classes.progressCircle,
            }}
            content={<img src={CardIcon} alt="" />}
          />
        </Box>

        <Typography className={clsx(classes.text)}>
          Thank you for your patience. Your insurance is verified. Click
          &quot;Proceed&quot; to finish the service ordering.
        </Typography>
      </Box>
      <ThemedButton onClick={handleProceedClick}>Proceed</ThemedButton>
    </Box>
  )
}

export default InsuranceManualVerificationSuccess
