import { LoadingContext, LoadingState } from './types'

export const makeLoading = (
  state?: LoadingState,
  message?: string | null,
  error?: Error
): LoadingContext => ({
  state: state || LoadingState.idle,
  message,
  error,
})

export enum AsyncTaskStatuses {
  failed = 'FAILED',
  successful = 'SUCCESSFUL',
  pending = 'PENDING',
}
