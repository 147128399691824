import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { Clinic } from '../store/clinics/models'
import clinicsActions from '../store/clinics/actions'
import {
  getClinicsEntries,
  getClinicsLoadingState,
} from '../store/clinics/selectors'
import { isLoading, LoadingContext } from '../utils/types'

export const useClinicsList = (): {
  clinics: Clinic[]
  isClinicsLoading: boolean
  loading: LoadingContext
} => {
  const dispatch = useDispatch()

  const loading = useSelector(getClinicsLoadingState)
  const clinics = useSelector(getClinicsEntries)
  const realClinics = clinics.filter((clinic) => clinic.position)

  const isClinicsLoading = isLoading(loading.state)

  useEffect(() => {
    if (isEmpty(realClinics)) {
      dispatch(clinicsActions.getAll.request())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { clinics: realClinics, loading, isClinicsLoading }
}
