import React, { useCallback, useRef, useState } from 'react'
import { Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import authActions from 'src/store/auth/actions'
import KylaIcon from 'src/assets/icons/KylaIcon.svg'
import FooterButtons from '../../../components/FooterButtons'
import {
  isFailure,
  isLoading,
  isSuccess,
  LoadingContext,
} from '../../../utils/types'
import { useLoadingChange } from '../../../hooks/useLoadingChange'
import { resetPasswordLoading } from '../../../store/auth/selectors'
import { RoutePath } from '../../../routes'
import ResetPasswordForm, {
  ResetPasswordFormValues,
} from '../../../components/forms/ResetPasswordForm'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(4, 4, 8),
      height: '100%',
      position: 'relative',
    },
    text: {
      color: theme.palette.primary.dark,
      fontWeight: 700,
    },
    title: {
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      lineHeight: 1.5,
      marginTop: theme.spacing(2),
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: '0.875rem',
    },
    contentWrapper: {
      paddingBottom: theme.spacing(4),
    },
    fullWidth: {
      width: '100%',
    },
  }),
  { name: 'ResetPassword' }
)

const ResetPassword: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const urlSearchParams = new URLSearchParams(window.location.search)
  const resetKey = urlSearchParams.get('reset-key')

  const resetPasswordLoadingObj = useSelector(resetPasswordLoading)
  const resetPasswordIsLoading = isLoading(resetPasswordLoadingObj.state)

  const [generalValidationError, setGeneralValidationError] = useState('')

  const handleLoadingChange = useCallback(
    (newLoading: LoadingContext) => {
      if (isSuccess(newLoading.state)) {
        history.push(RoutePath.resetPasswordConfirm)
      }
      if (isFailure(newLoading.state)) {
        setGeneralValidationError(newLoading.message as string)
      }
    },
    [history, setGeneralValidationError]
  )

  useLoadingChange(handleLoadingChange, resetPasswordLoadingObj)

  const formRef = useRef<any>(null)

  const handleGoNext = useCallback(() => {
    formRef?.current?.submit()
    setGeneralValidationError('')
  }, [formRef, setGeneralValidationError])

  const handleFormSubmit = useCallback(
    (password: ResetPasswordFormValues['password']) => {
      dispatch(
        authActions.resetPassword.request({ password, key: resetKey as string })
      )
    },
    [dispatch, resetKey]
  )

  return (
    <Grid container className={classes.root}>
      <Grid container direction="column" justifyContent="space-between">
        <Grid
          item
          xs
          container
          direction="column"
          justifyContent="space-between"
          className={classes.contentWrapper}
        >
          <Grid>
            <img src={KylaIcon} alt="kyla-avatar" />
            <Typography className={clsx(classes.text, classes.title)}>
              Reset password
            </Typography>
          </Grid>
          <Grid>
            <ResetPasswordForm
              ref={formRef}
              onSubmit={handleFormSubmit}
              disabled={resetPasswordIsLoading}
            />

            <Typography
              align="center"
              className={clsx(classes.text, classes.errorText)}
            >
              {generalValidationError}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <FooterButtons
            classes={{
              root: classes.fullWidth,
              buttonWrapper: classes.fullWidth,
              button: classes.fullWidth,
            }}
            nextOnly
            nextButtonLabel="Submit"
            onNextButtonClick={handleGoNext}
            loadingNext={resetPasswordIsLoading}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ResetPassword
