import React from 'react'
import { Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import KylaIcon from 'src/assets/icons/KylaIcon.svg'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(4, 4, 8),
      height: '100%',
      position: 'relative',
    },
    text: {
      fontSize: '1.375rem',
      color: theme.palette.primary.dark,
      fontWeight: 700,
      letterSpacing: '-0.5px',
      marginTop: theme.spacing(3),
    },
    title: {
      fontSize: '2.25rem',
      lineHeight: '2.75rem',
      marginTop: theme.spacing(2),
      letterSpacing: '-1px',
    },
    contentWrapper: {
      paddingBottom: theme.spacing(4),
    },
  }),
  { name: 'ResetPasswordConfirmation' }
)

const ResetPasswordConfirmation: React.FC = () => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <Grid container direction="column" justifyContent="space-between">
        <Grid
          item
          xs
          container
          direction="column"
          justifyContent="space-between"
          className={classes.contentWrapper}
        >
          <Grid>
            <img src={KylaIcon} alt="kyla-avatar" />
            <Typography className={clsx(classes.text, classes.title)}>
              You’re all set!
            </Typography>
            <Typography className={clsx(classes.text)}>
              Your password has been reset successfully.
            </Typography>
            <Typography className={clsx(classes.text)}>
              Open the Kyla app and sign in with your new password.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ResetPasswordConfirmation
