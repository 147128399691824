import { AxiosError } from 'axios'
import { ServerErrorResponse } from './api/types'

export enum Errors {
  DEFAULT = 'Something went wrong',
}

export enum ErrorCodes {
  VerificationRequired = 3019,
  ApptAlreadyExists = 3009,
  ApptTimeIsNotAvialable = 4000019,
  AccountAlreadyExist = 4000025,
  ReactivateAccount = 4000004,
  BlacklistedAccount = 4000010,
}

const isInternalError = (error: Error): boolean =>
  [
    EvalError,
    RangeError,
    ReferenceError,
    SyntaxError,
    TypeError,
    URIError,
  ].some((item) => error instanceof item)

const isAxiosError = <T extends ServerErrorResponse>(
  error: Error
): error is AxiosError<T> => (error as AxiosError<T>)?.isAxiosError

export const extractErrorMessage = <R extends {}>(
  error: Error | AxiosError<R> | any,
  inputDefaultMessage?: string
): string => {
  const defaultMessage = inputDefaultMessage || Errors.DEFAULT

  if (isInternalError(error)) {
    return defaultMessage
  }

  if (isAxiosError(error)) {
    return (
      error.response?.data.error.user_msg ||
      error.response?.data.error.message ||
      defaultMessage
    )
  }

  const message = error?.message || defaultMessage

  const traceId = error.response?.data?.trace_data?.trace_id

  return traceId ? `${message} (Error ID: ${traceId})` : message
}

export const isVerificationRequired = (
  error: AxiosError<ServerErrorResponse>
) => error.response?.data?.error?.error_code === ErrorCodes.VerificationRequired

export const isAccountReactivationRequired = (
  error: AxiosError<ServerErrorResponse>
) => error.response?.data?.error?.error_code === ErrorCodes.ReactivateAccount

export const isBlacklistedAccount = (error: AxiosError<ServerErrorResponse>) =>
  error.response?.data?.error?.error_code === ErrorCodes.BlacklistedAccount

export const extractErrorCode = (error: any): number | null => {
  return error.response?.data?.error?.error_code || null
}
