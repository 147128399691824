export function decorateUrlGtag(urlString: string): string {
  const tempAnchorEl = document.createElement('a')

  tempAnchorEl.setAttribute('type', 'hidden')
  tempAnchorEl.setAttribute('href', urlString)
  tempAnchorEl.setAttribute('onclick', 'event.preventDefault(); return false')

  document.body.append(tempAnchorEl)

  tempAnchorEl.dispatchEvent(new MouseEvent('mousedown', { bubbles: true }))

  const urlWithLinker = tempAnchorEl.href

  tempAnchorEl.remove()

  return urlWithLinker
}
