import { Moment } from 'moment'
import { createSelector, Selector } from '@reduxjs/toolkit'

import { VerificationOptionsTypes } from './models'
import { verificationSentTimeLabels } from './reducers/entities'
import { RootState } from '../index'

const getAuthEntities = (state: RootState): RootState['auth']['entities'] =>
  state.auth.entities

const getAuthUI = (state: RootState): RootState['auth']['ui'] => state.auth.ui

export const getUserInsuranceIsEligible = createSelector(
  getAuthEntities,
  (entities) => entities.profile?.info?.insuranceInfo?.isEligible
)

export const getCheckEmailLoading = createSelector(
  getAuthUI,
  (ui) => ui.checkEmailLoading
)

export const getGeneralInfo = createSelector(
  getAuthEntities,
  (entities) => entities.generalInfo
)

export const getGeneralInfoEmail = createSelector(
  getAuthEntities,
  (entities) => entities.profile?.info?.email
)

export const getCreateAccountLoading = createSelector(
  getAuthUI,
  (ui) => ui.createAccountLoading
)

export const getTemporaryUserId = createSelector(
  getAuthEntities,
  (entities) => entities.temporaryUserId
)

export const getCheckingKylaDuplicate = createSelector(
  getAuthUI,
  (ui) => ui.checkingKylaDuplicate
)

export const getSignInLoading = createSelector(
  getAuthUI,
  (ui) => ui.signInLoading
)

export const forgotPasswordLoading = createSelector(
  getAuthUI,
  (ui) => ui.forgotPasswordLoading
)

export const getUserProfile = createSelector(
  getAuthEntities,
  (entities) => entities.profile?.info
)

export const resetPasswordLoading = createSelector(
  getAuthUI,
  (ui) => ui.resetPasswordLoading
)

export const getUserAnalyticId = createSelector(
  getAuthEntities,
  (entities) => entities.analyticId
)

export const getAuthToken = createSelector(
  getAuthEntities,
  (entities) => entities.profile.token
)

export const getIsDeletedAccount = createSelector(
  getAuthEntities,
  (entities) => entities.accountDeleted
)

export const getIsBlacklistedAccount = createSelector(
  getAuthEntities,
  (entities) => entities.accountBlacklisted
)

export const getReactivateAccountVerificationCode = createSelector(
  getAuthEntities,
  (entities) => entities.reactivateAccountVerificationCode
)

export const getVerificationType = createSelector(
  getAuthEntities,
  (entities) => entities.verificationType
)

export const getGettingVerificationLoading = createSelector(
  getAuthUI,
  (ui) => ui.gettingVerificationLoading
)

export const getVerifyEmailCodeSentTime = createSelector(
  getAuthEntities,
  (entities) => entities.verifyEmailCodeSentTime
)

export const getReactivationCodeSentTime = createSelector(
  getAuthEntities,
  (entities) => entities.reactivationCodeSentTime
)

export const getSendingReactivationCode = createSelector(
  getAuthUI,
  (ui) => ui.sendingReactivationCode
)

export const getUpdatingPassword = createSelector(
  getAuthUI,
  (ui) => ui.updatingPassword
)

export const getVerifyingEmail = createSelector(
  getAuthUI,
  (ui) => ui.verifyingEmail
)

export const getESignatureStatusData = createSelector(
  getAuthEntities,
  (entities) => entities.eSignatureStatusData
)

export const getESignatureStatusGetting = createSelector(
  getAuthUI,
  (ui) => ui.eSignatureStatusGetting
)

export const getESignatureSubmitting = createSelector(
  getAuthUI,
  (ui) => ui.eSignatureSubmitting
)

export const getCreateChronoAccountLoading = createSelector(
  getAuthUI,
  (ui) => ui.createChronoAccountLoading
)

export const getLinkingWithChronoAccount = createSelector(
  getAuthUI,
  (ui) => ui.linkingWithChronoAccount
)

export const getProfileLoading = createSelector(
  getAuthUI,
  (ui) => ui.profileLoading
)

export const getUpdateProfileSubmitting = createSelector(
  getAuthUI,
  (ui) => ui.updateProfileSubmitting
)

export const getUpdatedPassword = createSelector(
  getAuthEntities,
  (entities) => entities.updatedPassword
)

export const getDuplicationInfo = createSelector(
  getAuthEntities,
  (entities) => entities.duplicationInfo
)

export const getTemporaryAddressDetails = createSelector(
  getAuthEntities,
  (entities) => entities.temporaryPatientAddressDetails
)

export const getSkippingChronoDuplicate = createSelector(
  getAuthUI,
  (ui) => ui.skippingChronoDuplicate
)

export const getIdCardUploading = createSelector(
  getAuthUI,
  (ui) => ui.idCardUploading
)

export const getOutdatedTosList = createSelector(
  getAuthEntities,
  (entities) => entities.outdatedTosList
)

export const makeGetRelatedVerificationSentTime = (
  verificationType: VerificationOptionsTypes
): Selector<RootState, Moment | null> =>
  createSelector(getAuthEntities, (entities) => {
    const relatedStoreKey = verificationSentTimeLabels[
      verificationType
    ] as keyof RootState['auth']['entities']

    return entities[relatedStoreKey] as Moment | null
  })
