import React from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'

import AppointmentButton from '../components/common/AppointmentButton'
import {
  getAppointmentClinic,
  getAppointmentTime,
  getIsTelemedicineClinicFlag,
} from '../store/appointment/selectors'
import { getGeneralInfoEmail } from '../store/auth/selectors'
import AuthLayout from '../components/layouts/AuthLayout'
import FooterButtons from '../components/FooterButtons'
import { useIsLabOrderingFlow } from '../hooks/labOrdering/useIsLabOrderingFlow'

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.dark,
    lineHeight: 1.5,
  },
}))

const ConfirmedAppointmentDetails: React.FC = () => {
  const history = useHistory()

  const classes = useStyles()
  const appointmentTime = useSelector(getAppointmentTime)
  const selectedClinicInfo = useSelector(getAppointmentClinic)
  const isTelemedicineClinic = useSelector(getIsTelemedicineClinicFlag)
  const email = useSelector(getGeneralInfoEmail)
  const isLabOrderingFlow = useIsLabOrderingFlow()

  return (
    <AuthLayout
      title="You're all set!"
      bottomActions={
        <FooterButtons
          nextOnly
          nextButtonLabel="Back"
          onNextButtonClick={() => history.goBack()}
        />
      }
    >
      <AppointmentButton
        withPaymentMethod
        appointmentTime={appointmentTime}
        selectedClinicInfo={selectedClinicInfo}
        isTelemedicineClinic={
          Boolean(isTelemedicineClinic) || isLabOrderingFlow
        }
      />

      {!isTelemedicineClinic && !isLabOrderingFlow && (
        <Typography className={classes.text} align="left">
          A welcome email and appointment confirmation has been sent to:{' '}
          <b>{email}</b>
        </Typography>
      )}
    </AuthLayout>
  )
}

export default ConfirmedAppointmentDetails
