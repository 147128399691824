import { createSelector } from 'reselect'

import { State } from 'src/store/rootReducer'

const getAnalyticsEntities = (state: State): State['analytics']['entities'] =>
  state.analytics.entities

export const getAnalyticsQueries = createSelector(
  getAnalyticsEntities,
  (entity) => entity.analyticsQueries
)
