import React, { useMemo } from 'react'
import parsePhoneNumber from 'libphonenumber-js'
import { Button, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        padding: theme.spacing(2),
        border: `2px solid ${theme.palette.primary.light}`,
        boxSizing: 'border-box',
        borderRadius: 12,
        color: theme.palette.primary.main,
        fontFamily: 'Arial',
        fontWeight: 'bold',
        fontSize: 18,
        lineHeight: '24px',
        '&:hover': {
          border: `2px solid ${theme.palette.primary.main}`,
          backgroundColor: 'rgba(25, 99, 198, 0.04)',
        },
      },
    }),
  { name: 'PhoneNumberButton' }
)

interface PhoneNumberButtonProps {
  isLoading?: boolean
  phoneNumber: any
  emptyPlaceholder?: string
}

const PhoneNumberButton: React.FC<PhoneNumberButtonProps> = ({
  isLoading = false,
  phoneNumber,
  emptyPlaceholder = '-',
}) => {
  const classes = useStyles()

  const parsedPhoneNumber = useMemo(() => parsePhoneNumber(phoneNumber || ''), [
    phoneNumber,
  ])

  return (
    <Button
      variant="outlined"
      color="primary"
      className={classes.root}
      disabled={isLoading || !parsedPhoneNumber}
      href={parsedPhoneNumber?.getURI() || ''}
    >
      {isLoading
        ? 'Loading...'
        : parsedPhoneNumber?.formatNational() || emptyPlaceholder}
    </Button>
  )
}

export default PhoneNumberButton
