import { SagaIterator } from 'redux-saga'
import { call, put, takeEvery } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'

// eslint-disable-next-line import/no-cycle,import/named
import { serverAxios } from '../../utils/api/axios'
import { attachmentsActions } from './actions'
import { extractErrorMessage } from '../../utils/errors'

export function* getAttachment(
  action: ActionType<typeof attachmentsActions.getAttachment.request>
): SagaIterator {
  const { attachmentId } = action.payload

  try {
    const response = yield call(
      serverAxios.get,
      `/api/v1/patient/attachment/${attachmentId}`,
      {
        responseType: 'blob',
      }
    )

    yield put(
      attachmentsActions.getAttachment.success({
        attachmentId,
        attachment: response,
      })
    )
  } catch (error) {
    yield put(
      attachmentsActions.getAttachment.failure({
        attachmentId,
        message: extractErrorMessage(
          error,
          'Failed to get insurance attachment'
        ),
      })
    )
  }
}

export const attachmentsSagas = [
  takeEvery(attachmentsActions.getAttachment.request, getAttachment),
]
