import React, { useCallback, useRef } from 'react'
import Webcam from 'react-webcam'
import { Button, Dialog, Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PhotoCamera } from '@mui/icons-material'
import clsx from 'clsx'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 10,
    },
    video: {
      width: '100%',
      height: '100%',
      position: 'relative',
      pointerEvents: 'none',
    },
    button: {
      width: 90,
      height: 30,
      margin: theme.spacing(0, 1),
      fontWeight: 'bold',
      textTransform: 'none',
    },
    errorButton: {
      color: '#fff',
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
    },
    buttonsWrapper: {
      position: 'absolute',
      bottom: 20,
    },
  }),
  { name: 'WebcamDialog' }
)

const videoConstraints = {
  height: 720,
  width: 1280,
  facingMode: 'environment',
}

interface WebcamDialogProps {
  open: boolean
  onClose(): void
  onImageAdd(file: File): void
}

const WebcamDialog: React.FC<WebcamDialogProps> = ({
  onClose,
  open,
  onImageAdd,
}) => {
  const classes = useStyles()

  const webcamRef = useRef<any>(null)

  const capture = useCallback(() => {
    if (webcamRef?.current) {
      const imageSrc = webcamRef.current.getScreenshot()
      if (!imageSrc) {
        onClose()
        alert(
          "Looks like you haven't granted permission to use the camera. Enable permissions, or use file upload from device."
        )
        return
      }

      fetch(imageSrc)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'image.png', blob)
          onImageAdd(file)
        })
    }
  }, [onImageAdd, webcamRef])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.root }}
    >
      <Webcam
        audio={false}
        className={classes.video}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />
      <Grid className={classes.buttonsWrapper}>
        <Button
          className={classes.button}
          onClick={capture}
          color="primary"
          variant="contained"
        >
          Snap
          <PhotoCamera />
        </Button>
        <Button
          className={clsx(classes.button, classes.errorButton)}
          variant="contained"
          onClick={onClose}
        >
          Close
        </Button>
      </Grid>
    </Dialog>
  )
}

export default WebcamDialog
