import { EmergencyLevels } from './general'

export enum PatientGender {
  male = 'MALE',
  female = 'FEMALE',
}

export type LabOrderingPatientInfo = {
  dateOfBirth: string
  gender: PatientGender
  nodeAnswers: { nodeId: string; outcome: string; type: string | null }[]
  multipleNodeAnswers: {
    noneOfThisList: string[]
    presentList: string[]
    type: string[]
  }[]
}

export type SymptomsCheckerParams = {
  seriousSymptoms: any[]
  emergencyLevel: EmergencyLevels
  patientInfo?: LabOrderingPatientInfo
}
