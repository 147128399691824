import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { Clinic } from '../store/clinics/models'
import clinicsActions from '../store/clinics/actions'
import {
  getClinicInfo,
  getClinicsLoadingState,
} from '../store/clinics/selectors'
import { isLoading } from '../utils/types'

export const useClinicPreview = (
  selectedClinicId: number,
  widgetType: string,
  specificClinic?: boolean
): {
  clinicInfo: Clinic
  clinicInfoIsLoading: boolean
} => {
  const dispatch = useDispatch()

  const loading = useSelector(getClinicsLoadingState)

  const makeGetClinicInfo = useMemo(() => getClinicInfo(selectedClinicId), [
    selectedClinicId,
  ])

  const selectedClinicInfo = useSelector(makeGetClinicInfo)
  const clinicInfoIsLoading = isLoading(loading.state)

  useEffect(() => {
    if (isEmpty(selectedClinicInfo)) {
      dispatch(
        clinicsActions.getClinicById.request({
          selectedClinicId,
          widgetType,
          specificClinic,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClinicId])

  return { clinicInfo: selectedClinicInfo, clinicInfoIsLoading }
}
