import React from 'react'
import { Button, CircularProgress, Grid, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Classes } from '@mui/styles/mergeClasses/mergeClasses'
import { noop } from 'lodash'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    buttonWrapper: {
      marginBottom: theme.spacing(0.5),
      '&:last-child': {
        marginBottom: 0,
      },
    },
    button: {
      borderRadius: '12px',
      fontSize: '1rem',
      fontFamily: 'NeurialGrotesk',
      fontWeight: 700,
    },
  }),
  { name: 'FooterButtons' }
)

interface ButtonsContainerProps {
  nextButtonLabel?: string
  backButtonLabel?: string
  onNextButtonClick?: () => void
  onBackButtonClick?: () => void
  disableNext?: boolean
  disableBack?: boolean
  loadingNext?: boolean
  nextOnly?: boolean
  classes?: Classes
}
const FooterButtons: React.FC<ButtonsContainerProps> = ({
  nextButtonLabel = 'Continue',
  backButtonLabel = 'Back',
  onNextButtonClick = noop,
  onBackButtonClick = noop,
  disableBack = false,
  disableNext = false,
  loadingNext = false,
  nextOnly = false,
  classes: classesProp = {},
}) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.buttonWrapper}>
        <Button
          fullWidth
          color="primary"
          className={classes.button}
          variant="contained"
          onClick={onNextButtonClick}
          disabled={disableNext || loadingNext}
        >
          {loadingNext ? <CircularProgress size={30} /> : nextButtonLabel}
        </Button>
      </Grid>
      {!nextOnly && (
        <Grid item className={classes.buttonWrapper}>
          <Button
            fullWidth
            color="primary"
            className={classes.button}
            onClick={onBackButtonClick}
            disabled={disableBack}
          >
            {backButtonLabel}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default FooterButtons
