import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import clinicsActions from 'src/store/clinics/actions'
import { formatClinic } from '../../utils/clinics'
// eslint-disable-next-line import/no-cycle
import { aucWpAxios } from '../../utils/api/aucWpAxios'
import { extractErrorMessage } from '../../utils/errors'
import { AxiosError } from 'axios'

function* getAll(): SagaIterator {
  try {
    const data = yield call(aucWpAxios.get, '/api/clinics')

    const clinics = data.map((clinic: any) => formatClinic(clinic))

    yield put(clinicsActions.getAll.success({ clinics }))
  } catch (error) {
    const axiosError = error as AxiosError

    yield put(
      clinicsActions.getAll.failure({
        message: extractErrorMessage(error, 'Failed to get clinics list'),
        error: axiosError,
      })
    )
  }
}

function* getClinicById(
  action: ActionType<typeof clinicsActions.getClinicById.request>
): SagaIterator {
  const {
    selectedClinicId: clinicId,
    widgetType,
    specificClinic,
  } = action.payload
  try {
    const response = yield call(aucWpAxios.get, `/api/clinic`, {
      params: {
        widgetType,
        id: clinicId,
        action: specificClinic
          ? 'get_clinic_page_details'
          : 'get_clinic_details',
      },
    })

    const clinicInfo = formatClinic(response)

    yield put(clinicsActions.getClinicById.success({ clinicInfo }))
  } catch (error) {
    const axiosError = error as AxiosError

    yield put(
      clinicsActions.getClinicById.failure({
        message: extractErrorMessage(error, 'Failed to get clinics list'),
        error: axiosError,
      })
    )
  }
}
export const clinicsSagas = [
  takeLatest(getType(clinicsActions.getAll.request), getAll),
  takeLatest(getType(clinicsActions.getClinicById.request), getClinicById),
]
