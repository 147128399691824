import { extractErrorMessage } from '../utils/errors'
import { LoadingContext } from '../utils/types'
import useLoadingFailure from './useLoadingFailure'

const useLoadingFailureAlert = (loading: LoadingContext) => {
  useLoadingFailure(loading, (nextLoading) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    alert(extractErrorMessage(nextLoading?.error))
  })
}

export default useLoadingFailureAlert
