import React, { useCallback } from 'react'
import { CircularProgress, Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { InClinicSlot } from 'src/store/appointment/models'
import AppointmentTimeSelection from '../../containers/appointment/AppointmentTimeSelection'
import FooterButtons from '../../components/FooterButtons'
import appointmentActions from '../../store/appointment/actions'
import {
  getAppointment,
  getAppointmentClinic,
  getScheduleApptLoading,
  getSourceWidgetType,
} from '../../store/appointment/selectors'
import { isLoading, isSuccess, LoadingContext } from '../../utils/types'
import OverlayItem from '../../components/common/OverlayItem'
import { useLoadingChange } from '../../hooks/useLoadingChange'
import { RoutePath } from '../../routes'
import { TelemedicineWidgetTypes } from '../../utils/appointment/general'
import { useIsLabOrderingFlow } from '../../hooks/labOrdering/useIsLabOrderingFlow'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      flex: 1,
      paddingBottom: theme.spacing(4),
      position: 'relative',
    },
    titleContainer: {
      padding: theme.spacing(9, 4.5, 0),
    },
    title: {
      fontFamily: 'Arial',
      fontWeight: 'bold',
      fontSize: '1.375rem',
      lineHeight: 1.3,
      letterSpacing: '-0.5px',
      color: theme.palette.primary.dark,
    },
    content: {
      flex: 1,
      overflow: 'auto',
      marginBottom: theme.spacing(3),
    },
    actions: {
      padding: theme.spacing(0, 4),
    },
  }),
  { name: 'AppointmentTime' }
)

interface AppointmentTimeProps {
  header?: string
  nextPage?: string
}

type LocationState = {
  initialPage?: string
  hideGoBack?: boolean
} & AppointmentTimeProps

const AppointmentTime: React.FC<AppointmentTimeProps> = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { state } = useLocation<LocationState>()

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const {
    header = 'Book appointment time',
    nextPage = isLabOrderingFlow
      ? RoutePath.choosePaymentCard
      : RoutePath.appointmentReasons,
  } = { ...props, ...state }

  const selectedClinic = useSelector(getAppointmentClinic)
  const currentAppointment = useSelector(getAppointment)

  const scheduleApptLoading = useSelector(getScheduleApptLoading)
  const scheduleApptIsLoading = isLoading(scheduleApptLoading.state)

  const sourceWidgetType = useSelector(getSourceWidgetType)

  const handleGoBack = useCallback(() => {
    history.goBack()
  }, [history])

  const handleAppointmentLoading = useCallback(
    (newLoading: LoadingContext) => {
      if (isSuccess(newLoading.state)) {
        history.push(nextPage)
      }
    },
    [nextPage, history]
  )

  useLoadingChange(handleAppointmentLoading, scheduleApptLoading)

  const handleAppTimeSelect = useCallback(
    (slot: InClinicSlot) => {
      dispatch(appointmentActions.updateAppointmentTime({ dateTime: slot }))
    },
    [dispatch]
  )

  const handleGoNext = useCallback(() => {
    history.push({
      pathname: nextPage,
      ...(state ? { state: { initialPage: state.initialPage } } : {}),
    })
  }, [nextPage, state, history])

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      {scheduleApptIsLoading && (
        <OverlayItem>
          <CircularProgress />
        </OverlayItem>
      )}
      <Grid container direction="column" className={classes.content}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.titleContainer}
        >
          <Typography className={classes.title}>{header}</Typography>
        </Grid>
        <AppointmentTimeSelection
          virtualVisit={
            TelemedicineWidgetTypes.includes(sourceWidgetType) ||
            isLabOrderingFlow
          }
          clinicId={selectedClinic?.id}
          examRoom={selectedClinic?.examRoom}
          selectedTime={currentAppointment?.dateTime}
          onTimeSelect={handleAppTimeSelect}
        />
      </Grid>
      <FooterButtons
        classes={{ root: classes.actions }}
        nextOnly={state?.hideGoBack}
        nextButtonLabel="Select Time"
        backButtonLabel="Back"
        onNextButtonClick={handleGoNext}
        onBackButtonClick={handleGoBack}
        disableNext={!currentAppointment?.dateTime}
      />
    </Grid>
  )
}

export default AppointmentTime
