import React from 'react'
import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { styled } from '@mui/material'

type ThemedButtonProps = {
  thickBorder?: boolean
  loading?: boolean
} & ButtonProps

const StyledButton = styled(Button)<{ thickBorder?: boolean }>(({ thickBorder }) => ({
  borderRadius: '12px',
  textTransform: 'none',
  fontSize: '1rem',
  fontFamily: 'NeurialGrotesk',
  fontWeight: 700,

  ...(thickBorder && {
    borderWidth: '2px',
    '&:hover': {
      borderWidth: '2px',
    },
  }),
}))

const ThemedButton = React.forwardRef<HTMLButtonElement, ThemedButtonProps>(
  ({ loading, disabled, children, thickBorder = false, ...props }, ref) => (
    <StyledButton
      ref={ref}
      fullWidth
      thickBorder={thickBorder}
      color="primary"
      variant="contained"
      disabled={loading || disabled}
      {...props}
    >
      {loading ? <CircularProgress size={30} /> : children}
    </StyledButton>
  )
)

export default ThemedButton
