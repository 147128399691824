import { Button, ButtonProps, CircularProgress, styled } from '@mui/material'

type StyledButtonProps = ButtonProps & {
  loading?: boolean
  children: React.ReactNode
}

export const StyledButton = styled(
  ({ children, loading, ...props }: StyledButtonProps) => (
    <Button fullWidth color="primary" {...props}>
      {loading && <CircularProgress size={20} />} {children}
    </Button>
  )
)({
  borderRadius: '12px',
  fontSize: '1rem',
  fontFamily: 'NeurialGrotesk',
  fontWeight: 700,
})
