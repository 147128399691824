import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { Clinic } from '../store/clinics/models'
import clinicsActions from '../store/clinics/actions'
import {
  getClinicsEntries,
  getClinicsLoadingState,
} from '../store/clinics/selectors'
import { isLoading, LoadingContext } from '../utils/types'

export const useClinicsById = (
  clinicsId: Clinic['id'][]
): {
  clinics: Clinic[]
  isClinicsLoading: boolean
  loading: LoadingContext
} => {
  const dispatch = useDispatch()

  const loading = useSelector(getClinicsLoadingState)
  const isClinicsLoading = isLoading(loading.state)

  const clinics = useSelector(getClinicsEntries)

  const availableClinics = clinics.filter(
    (clinic) => clinic.position && clinicsId.includes(clinic.id)
  )

  useEffect(() => {
    if (isEmpty(availableClinics)) {
      dispatch(clinicsActions.getAll.request())
    }
  }, [])

  return { clinics: availableClinics, loading, isClinicsLoading }
}
