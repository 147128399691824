import React, { useEffect, useState } from 'react'
import {
  Stack,
  Grid,
  Theme,
  Typography,
  Divider,
  LinearProgress,
  Button,
} from '@mui/material'
import { isEmpty } from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch } from 'react-redux'

import { useInsuranceCompaniesList } from '../../hooks/useInsuranceCompaniesList'
import { InsuranceCompany } from '../../store/insurance/models'
import ThemedTextField from '../../components/common/ThemedTextField'
import useDebounce from '../../hooks/useDebounce'
import { insuranceActions } from '../../store/insurance/actions'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      borderRadius: 40,
      backgroundColor: theme.palette.primary.light,
      boxShadow: `0 0 0 1px ${theme.palette.primary.light}`,
      position: 'relative',
      flex: 1,
      overflow: 'auto',
      height: '100%',
    },
    title: {
      fontFamily: 'NeurialGrotesk',
      fontWeight: 500,
      fontSize: '1.125rem',
      lineHeight: 1.3,
      color: theme.palette.primary.dark,
    },
    filterInput: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    infoContainer: {
      margin: theme.spacing(2.5, 0),
      cursor: 'pointer',
    },
    importantCompaniesContainer: {
      margin: theme.spacing(1, 0),
      cursor: 'pointer',
      paddingLeft: theme.spacing(0.5),
    },
    infoText: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '0.875rem',
      lineHeight: 1.3,
      color: theme.palette.primary.main,
    },
    progress: {
      height: 2,
      position: 'absolute',
      top: 0,
      left: 15,
      right: 15,
    },
    entriesWrapper: {
      flex: 1,
      overflow: 'auto',
      padding: theme.spacing(1, 4.5, 1),
    },
    filterWrapper: {
      padding: theme.spacing(0, 4.5),
    },
    button: {
      textTransform: 'none',
      fontSize: '1rem',
      fontFamily: 'NeurialGrotesk',
      fontWeight: 700,
    },
  }),
  { name: 'CompaniesListContainer' }
)

const IMPORTANT_COMPANIES = [
  'Medi-Cal',
  'Kaiser',
  'Blue Cross',
  'UnitedHealthcare',
  'Aetna Healthcare',
  'Blue Shield',
]

interface CompaniesListContainerProps {
  onCompanySelect: (company: InsuranceCompany) => void
  onClose: () => void
}

const CompaniesListContainer: React.FC<CompaniesListContainerProps> = ({
  onCompanySelect,
  onClose,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [filterValue, setFilterValue] = useState<string>('')

  const debouncedFilterValue = useDebounce(filterValue, 600)

  const handleReset = () => {
    setFilterValue('')

    dispatch(insuranceActions.clearCompanies())
  }

  const handleCompanySelect = (company: InsuranceCompany) => {
    handleReset()

    onCompanySelect(company)
  }

  const handleClose = () => {
    handleReset()

    onClose()
  }

  const {
    companies,
    isFetchingCompanies,
    getCompaniesList,
  } = useInsuranceCompaniesList()

  useEffect(() => {
    if (debouncedFilterValue) {
      getCompaniesList(debouncedFilterValue)
    } else if (companies.length) {
      dispatch(insuranceActions.clearCompanies())
    }
  }, [debouncedFilterValue])

  const [filteredCompanies, updateFilteredCompanies] = useState<
    InsuranceCompany[]
  >(companies)

  useEffect(() => {
    updateFilteredCompanies(companies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(companies)])

  return (
    <Grid container item xs className={classes.root} direction="column">
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        size="small"
        onClick={handleClose}
      >
        Close
      </Button>
      {isFetchingCompanies && <LinearProgress className={classes.progress} />}
      <Grid className={classes.filterWrapper}>
        <ThemedTextField
          disabled={isFetchingCompanies}
          classes={{ input: classes.filterInput }}
          value={filterValue}
          variant="outlined"
          color="primary"
          placeholder="Type here"
          onChange={(event) => {
            setFilterValue(event.target.value)
          }}
        />
      </Grid>
      <Grid item className={classes.entriesWrapper}>
        {isEmpty(filteredCompanies) && !isFetchingCompanies && (
          <Stack spacing={1} className={classes.importantCompaniesContainer}>
            {IMPORTANT_COMPANIES.map((company) => (
              <Typography
                className={classes.infoText}
                key={company}
                onClick={() => setFilterValue(company)}
              >
                {company}
              </Typography>
            ))}
          </Stack>
        )}
        {filteredCompanies.map((company, index) => (
          <React.Fragment key={`${company.id}-${company.name}`}>
            <Grid
              className={classes.infoContainer}
              onClick={() => handleCompanySelect(company)}
            >
              <Typography className={classes.infoText}>
                {company.name}
              </Typography>
            </Grid>
            {companies[index + 1] && <Divider />}
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  )
}

export default CompaniesListContainer
