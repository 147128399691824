import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingContext } from 'src/utils/types'
import { LabProduct } from '../../store/testOrdering/models'
import { getLabOrderDataAction } from '../../store/testOrdering'

import {
  getLabOrderDataSelector,
  getFetchingLabOrderData,
} from '../../store/testOrdering/selectors'

interface UseLabOrderData {
  fetching: LoadingContext
  order: LabProduct | null
  getLabOrderData(orderId: LabProduct['id']): void
}

export const useLabOrderData = (): UseLabOrderData => {
  const dispatch = useDispatch()

  const order = useSelector(getLabOrderDataSelector)
  const fetching = useSelector(getFetchingLabOrderData)

  const getLabOrderData = useCallback(
    (orderId) => {
      dispatch(getLabOrderDataAction({ orderId }))
    },
    [dispatch]
  )

  return { getLabOrderData, order, fetching }
}
