import moment from 'moment'
import Bowser from 'bowser'

import { config } from './config'
import {
  actionUrgentCareMainFlow,
  atHomeCareRoutes,
  confirmAppointmentRoutes,
  confirmLabOrderRoutes,
  inClinicMainFlow,
  labOrderInfoRoutes,
  resetPasswordRoutes,
  specificClinicRoutes,
  virtualVisitRoutes,
} from '../routes'

export const US_ZIP_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/]

export const validateMaxDob = (value: string): boolean => {
  const selectedDate = moment(value)

  return selectedDate.isSameOrAfter(moment('01-01-1900', 'DD-MM-YYYY'))
}

export const validateMinDob = (value: string): boolean => {
  const today = moment()
  const selectedDate = moment(value)

  const years = today.diff(selectedDate, 'years', true)

  return years > 18
}

export const getAbsoluteUrl = (relativeUrl: string): string => {
  return `${config.host}${relativeUrl}`
}

export enum EmergencyLevels {
  EmergencyAmbulance = 'emergency_ambulance',
  Emergency = 'emergency',
  ConsultationWithinOneDay = 'consultation_24',
  Consultation = 'consultation',
  SelfCare = 'self_care',
}

export const getFormattedAddress = (address: string): string[] =>
  address.split('\n')

export const formatDateTitle = (dateString: string): string =>
  moment(dateString).format('ddd, MMM DD')

export const isSafari =
  Bowser.getParser(window.navigator.userAgent).getBrowserName() === 'Safari'

export const validateName = (value: string, key: string) => {
  if (!value || value.trim() === '') {
    return `${key} is required`
  }

  if ((value.match(/[’'`]/g) || []).length > 1) {
    return `${key} cannot contain more than one apostrophe`
  }

  if (!/^[A-Za-z]/.test(value)) {
    return `${key} must start with a letter`
  }

  if (!/[A-Za-z]$/.test(value)) {
    return `${key} must end with a letter`
  }

  if (!/^(?!\s)([’'`A-Za-z- ]+)$/.test(value)) {
    return `${key} must contain only letters, dashes, or spaces in the middle`
  }

  if (/\s{2}/.test(value)) {
    return 'Only a single space is allowed between words'
  }

  if (/(-{2})|(-\s+)|(\s-+)/.test(value)) {
    return 'Only a single dash is allowed between words'
  }

  return true
}

export const US_STATES = [
  {
    id: 'Alabama',
    name: 'Alabama',
  },
  {
    id: 'Alaska',
    name: 'Alaska',
  },
  {
    id: 'Arizona',
    name: 'Arizona',
  },
  {
    id: 'Arkansas',
    name: 'Arkansas',
  },
  {
    id: 'California',
    name: 'California',
  },
  {
    id: 'Colorado',
    name: 'Colorado',
  },
  {
    id: 'Connecticut',
    name: 'Connecticut',
  },
  {
    id: 'Delaware',
    name: 'Delaware',
  },
  {
    id: 'Florida',
    name: 'Florida',
  },
  {
    id: 'Georgia',
    name: 'Georgia',
  },
  {
    id: 'Hawaii',
    name: 'Hawaii',
  },
  {
    id: 'Idaho',
    name: 'Idaho',
  },
  {
    id: 'Illinois',
    name: 'Illinois',
  },
  {
    id: 'Indiana',
    name: 'Indiana',
  },
  {
    id: 'Iowa',
    name: 'Iowa',
  },
  {
    id: 'Kansas',
    name: 'Kansas',
  },
  {
    id: 'Kentucky',
    name: 'Kentucky',
  },
  {
    id: 'Louisiana',
    name: 'Louisiana',
  },
  {
    id: 'Maine',
    name: 'Maine',
  },
  {
    id: 'Maryland',
    name: 'Maryland',
  },
  {
    id: 'Massachusetts',
    name: 'Massachusetts',
  },
  {
    id: 'Michigan',
    name: 'Michigan',
  },
  {
    id: 'Minnesota',
    name: 'Minnesota',
  },
  {
    id: 'Mississippi',
    name: 'Mississippi',
  },
  {
    id: 'Missouri',
    name: 'Missouri',
  },
  {
    id: 'Montana',
    name: 'Montana',
  },
  {
    id: 'Nebraska',
    name: 'Nebraska',
  },
  {
    id: 'Nevada',
    name: 'Nevada',
  },
  {
    id: 'New Hampshire',
    name: 'New Hampshire',
  },
  {
    id: 'New Jersey',
    name: 'New Jersey',
  },
  {
    id: 'New Mexico',
    name: 'New Mexico',
  },
  {
    id: 'New York',
    name: 'New York',
  },
  {
    id: 'North Carolina',
    name: 'North Carolina',
  },
  {
    id: 'North Dakota',
    name: 'North Dakota',
  },
  {
    id: 'Ohio',
    name: 'Ohio',
  },
  {
    id: 'Oklahoma',
    name: 'Oklahoma',
  },
  {
    id: 'Oregon',
    name: 'Oregon',
  },
  {
    id: 'Pennsylvania',
    name: 'Pennsylvania',
  },
  {
    id: 'Rhode Island',
    name: 'Rhode Island',
  },
  {
    id: 'South Carolina',
    name: 'South Carolina',
  },
  {
    id: 'South Dakota',
    name: 'South Dakota',
  },
  {
    id: 'Tennessee',
    name: 'Tennessee',
  },
  {
    id: 'Texas',
    name: 'Texas',
  },
  {
    id: 'Utah',
    name: 'Utah',
  },
  {
    id: 'Vermont',
    name: 'Vermont',
  },
  {
    id: 'Virginia',
    name: 'Virginia',
  },
  {
    id: 'Washington',
    name: 'Washington',
  },
  {
    id: 'West Virginia',
    name: 'West Virginia',
  },
  {
    id: 'Wisconsin',
    name: 'Wisconsin',
  },
  {
    id: 'Wyoming',
    name: 'Wyoming',
  },
]

export enum AppFlows {
  appFlow,
  labOrdering,
  labInformation,
}

export enum WidgetTypes {
  confirmAppointment = 'confirm-appointment',
  confirmLabOrder = 'confirm-lab-order',
  labOrderInfo = 'lab-order-info',
  actionUrgentCareHome = 'action-urgent-care-home',
  inClinicHome = 'in-clinic-home',
  clinicAppointment = 'clinic-appointment',
  virtualVisit = 'virtual-visit',
  atHomeCare = 'at-home-care',
  resetPassword = 'reset-password',
}

export const WIDGET_TYPE_APP_FLOWS_MAP: { [key: string]: AppFlows } = {
  [WidgetTypes.confirmAppointment]: AppFlows.appFlow,
  [WidgetTypes.confirmLabOrder]: AppFlows.labOrdering,
  [WidgetTypes.labOrderInfo]: AppFlows.labInformation,
}

export const WIDGET_TYPE_INITIAL_ROUTES_MAP: { [key: string]: string[] } = {
  [WidgetTypes.actionUrgentCareHome]: actionUrgentCareMainFlow,
  [WidgetTypes.inClinicHome]: inClinicMainFlow,
  [WidgetTypes.clinicAppointment]: specificClinicRoutes,
  [WidgetTypes.virtualVisit]: virtualVisitRoutes,
  [WidgetTypes.atHomeCare]: atHomeCareRoutes,
  [WidgetTypes.confirmAppointment]: confirmAppointmentRoutes,
  [WidgetTypes.resetPassword]: resetPasswordRoutes,
  [WidgetTypes.confirmLabOrder]: confirmLabOrderRoutes,
  [WidgetTypes.labOrderInfo]: labOrderInfoRoutes,
}

export const SUPPORT_PHONE_NUMBER = '+18887104044'
