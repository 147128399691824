import React, { useCallback, useEffect, useRef } from 'react'
import { Theme, Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import ProgressCircleWithContent from '../../components/common/ProgressCircleWIthContent'
import CardIcon from '../../assets/icons/creditCardIcons/CardIconPrimary.svg'
import ThemedButton from '../../components/common/ThemedButton'
import authActions from '../../store/auth/actions'
import { insuranceActions } from '../../store/insurance/actions'
import {
  getIsOnManualVerification,
  getManualVerificationStatusLoading,
  getManualVerificationSubmitting,
} from '../../store/insurance/selectors'
import { useLoadingChange } from '../../hooks/useLoadingChange'
import { isFailure, isSuccess, LoadingContext } from '../../utils/types'
import {
  getProfileLoading,
  getUserInsuranceIsEligible,
} from '../../store/auth/selectors'
import { RoutePath } from '../../routes'
import { IndicatedPaymentMethod } from '../../store/appointment/types'
import appointmentActions from '../../store/appointment/actions'
import gtag from '../../utils/gtag'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(8, 3.5, 6),
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
    },
    title: {
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '35px',
      textAlign: 'center',
    },
    staticCircle: {
      color: '#B7CEEF',
    },
    progressCircle: {
      color: '#6C9BDA',
    },
    circleWithIcon: {
      margin: theme.spacing(5, 0, 8),
    },
  }),
  { name: 'InsuranceManualVerification' }
)

// TODO: discuss with Maria, since we have a problem on this step because user do not have access to the chat
const InsuranceManualVerification: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const timoutRef = useRef<ReturnType<typeof setTimeout>>()
  const history = useHistory()

  const isFirstTimeStatusCheck = useRef<boolean>(true)

  const manualVerificationStatusLoading = useSelector(
    getManualVerificationStatusLoading
  )

  const isInsuranceEligible = useSelector(getUserInsuranceIsEligible)
  const profileLoading = useSelector(getProfileLoading)

  const isOnManualVerification = useSelector(getIsOnManualVerification)

  const manualVerificationSubmitting = useSelector(
    getManualVerificationSubmitting
  )

  const requestManualVerification = useCallback(() => {
    dispatch(insuranceActions.requestManualVerification.request())

    gtag('event', 'webView_manual_insurance_verification_start')
  }, [dispatch])

  useEffect(
    () => () => {
      if (!timoutRef.current) {
        return
      }

      clearTimeout(timoutRef.current)
    },
    []
  )

  const getManualVerificationStatus = useCallback(
    (ignoreTimeout = false) => {
      const callback = () => {
        dispatch(insuranceActions.getManualVerificationStatus.request())
      }

      // just to make sure
      if (timoutRef.current) {
        clearTimeout(timoutRef.current)
      }

      if (ignoreTimeout) {
        callback()
      } else {
        timoutRef.current = setTimeout(callback, 5000)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch]
  )

  useEffect(() => getManualVerificationStatus(true), [
    getManualVerificationStatus,
  ])

  useLoadingChange((loading: LoadingContext) => {
    if (isFailure(loading.state)) {
      // TODO: show alert
      return
    }

    if (isSuccess(loading.state)) {
      getManualVerificationStatus(true)
    }
  }, manualVerificationSubmitting)

  useLoadingChange((loading: LoadingContext) => {
    if (isFailure(loading.state)) {
      // TODO: show alert
      return
    }

    if (!isSuccess(loading.state)) {
      return
    }

    if (!isFirstTimeStatusCheck.current && !isOnManualVerification) {
      dispatch(authActions.getProfile.request())
      return
    }

    isFirstTimeStatusCheck.current = false

    if (isOnManualVerification) {
      getManualVerificationStatus()
      return
    }

    requestManualVerification()
  }, manualVerificationStatusLoading)

  useLoadingChange((loading) => {
    if (isFailure(loading.state)) {
      // TODO: show error
      return
    }

    if (!isSuccess(loading.state)) {
      return
    }

    gtag('event', 'webView_manual_insurance_verification_complete', {
      isEligible: isInsuranceEligible,
    })

    history.replace(
      isInsuranceEligible
        ? RoutePath.choosePaymentMethod
        : RoutePath.insuranceManualVerificationFailure
    )
  }, profileLoading)

  const handleSelfPayClick = useCallback(() => {
    // TODO: do we need to drop user from the queue?

    dispatch(
      appointmentActions.setSelectedPaymentMethod(IndicatedPaymentMethod.cash)
    )

    gtag('event', 'webView_manual_insurance_verification_cancelled')

    history.replace(RoutePath.choosePaymentMethod)
  }, [dispatch, history])

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <Box display="flex" flexDirection="column" flex={1}>
        <Typography className={clsx(classes.text, classes.title)}>
          Insurance Verification <br /> In Progress
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center">
          <ProgressCircleWithContent
            withProgress
            classes={{
              root: classes.circleWithIcon,
              staticCircle: classes.staticCircle,
              progressCircle: classes.progressCircle,
            }}
            content={<img src={CardIcon} alt="" />}
          />
        </Box>

        <Typography className={clsx(classes.text)}>
          You will receive a notification once your insurance is verified, and
          you can continue with the flow.
          <br />
          <br />
          If our team has any questions about your insurance, we will reach out
          to you through the chat.
          <br />
          <br />
          If you haven&apos;t done so, please enable notifications so you
          don&apos;t miss any messages.
        </Typography>
      </Box>
      <ThemedButton onClick={handleSelfPayClick}>Self Pay</ThemedButton>
    </Box>
  )
}

export default InsuranceManualVerification
