import { Clinic } from '../../store/clinics/models'

export const formatClinic = (clinic: any): Clinic => ({
  url: clinic.url,
  name: clinic.title,
  address: clinic.address,
  phoneNumber: clinic.phone_number,
  position: clinic.coords,
  id: clinic.dr_chrono_office_id,
  examRoom: clinic.exam_room_number,
})
