import React, { useCallback } from 'react'

import CompaniesListContainer from 'src/containers/signUp/CompaniesListContainer'
import { InsuranceCompany } from 'src/store/insurance/models'

interface SearchInsuranceCompaniesProps {
  onCompanySelect(company: InsuranceCompany): void
  onClose: () => void
}

const SearchInsuranceCompanies: React.FC<SearchInsuranceCompaniesProps> = ({
  onCompanySelect,
  onClose,
}) => {
  const handleClinicSelect = useCallback(
    (company: InsuranceCompany) => {
      onCompanySelect(company)
    },
    [onCompanySelect]
  )

  return (
    <CompaniesListContainer
      onCompanySelect={handleClinicSelect}
      onClose={onClose}
    />
  )
}

export default SearchInsuranceCompanies
