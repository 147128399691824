import React, { useCallback, useEffect, useState } from 'react'
import { CircularProgress, Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'

import appointmentActions from 'src/store/appointment/actions'
import GoogleMap from '../../components/map/GoogleMap'
import { useClinicsList } from '../../hooks/useClinicsList'
import OverlayItem from '../../components/common/OverlayItem'
import { getAppointmentClinic } from '../../store/appointment/selectors'
import FooterButtons from '../../components/FooterButtons'
import { Clinic } from '../../store/clinics/models'
import PhoneNumberButton from '../../components/common/PhoneNumberButton'
import { isSuccess, LoadingContext } from '../../utils/types'
import { useLoadingChange } from '../../hooks/useLoadingChange'
import ClinicsListContainer from '../../containers/ClinicsListContainer'
import gtag from '../../utils/gtag'
import HomePageTabs, { PageViews } from '../../components/common/HomePageTabs'
import SelectedClinicInfoButton from '../../components/common/SelectedClinicInfoButton'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      height: '100%',
      padding: theme.spacing(6, 0, 0),
    },
    headerContainer: {
      padding: theme.spacing(0, 4),
    },
    text: {
      fontFamily: 'Arial',
      color: theme.palette.primary.dark,
      lineHeight: 1.4,
    },
    title: {
      fontWeight: 'bold',
      fontSize: '2.25rem',
      letterSpacing: '-1px',
    },
    phoneButtonContainer: {
      padding: theme.spacing(3, 3, 4, 3),
    },
    buttonRoot: {
      padding: theme.spacing(2),
      border: `2px solid ${theme.palette.primary.light}`,
      boxSizing: 'border-box',
      borderRadius: 12,
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: 'rgba(25, 99, 198, 0.04)',
      },
    },
    mapContainer: {
      height: 235,
      position: 'relative',
      padding: theme.spacing(1),
    },
    defaultView: {
      backgroundColor: theme.palette.primary.light,
      paddingBottom: theme.spacing(4),
      borderRadius: '0 0 40px 40px',
    },
    actions: {
      padding: theme.spacing(0, 4),
    },
  }),
  { name: 'ActionUrgentCareHomePage' }
)

interface ActionUrgentCareHomePageProps {
  defaultClinicId: number
}

const ActionUrgentCareHomePage: React.FC<ActionUrgentCareHomePageProps> = ({
  defaultClinicId,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [pageView, updatePageView] = useState<string>(PageViews.mapView)

  const selectedClinicInfo = useSelector(getAppointmentClinic)

  const { clinics, loading, isClinicsLoading } = useClinicsList()

  const handleClinicsLoadingChange = useCallback(
    (newLoading: LoadingContext) => {
      if (isSuccess(newLoading.state)) {
        const defaultClinic = clinics.find(({ id }) => id === defaultClinicId)
        dispatch(
          appointmentActions.updateClinic({
            clinic: {
              address: defaultClinic?.address,
              examRoom: defaultClinic?.examRoom,
              id: defaultClinic?.id,
              name: defaultClinic?.name,
              phoneNumber: defaultClinic?.phoneNumber,
              url: defaultClinic?.url,
            } as Clinic,
          })
        )
      }
    },
    [defaultClinicId, JSON.stringify(clinics)]
  )

  useLoadingChange(handleClinicsLoadingChange, loading)

  const handleMapMarkerClick = useCallback((childProps: any) => {
    dispatch(
      appointmentActions.updateClinic({
        clinic: {
          address: childProps.address,
          examRoom: childProps.examRoom,
          id: childProps.id,
          name: childProps.name,
          phoneNumber: childProps.phoneNumber,
          url: childProps.url,
        } as Clinic,
      })
    )
  }, [])

  const handleOpenClinicClick = useCallback(() => {
    window.open(selectedClinicInfo?.url || '', '_blank')
  }, [selectedClinicInfo?.url])

  const handleClinicSelect = useCallback(
    (clinic) => {
      dispatch(appointmentActions.updateClinic({ clinic }))
      updatePageView(PageViews.mapView)
    },
    [dispatch]
  )

  useEffect(() => {
    gtag('event', 'main_inclinic')
  }, [])

  return (
    <Grid
      container
      direction="column"
      flexWrap="nowrap"
      className={classes.root}
    >
      <Grid item className={classes.headerContainer}>
        <Typography className={clsx(classes.title, classes.text)}>
          Book Appointment
        </Typography>
        <Typography className={classes.text}>
          Select a Clinic or Call
        </Typography>
      </Grid>
      <Grid item className={classes.phoneButtonContainer}>
        <PhoneNumberButton
          phoneNumber="+14087585650"
          emptyPlaceholder="Select clinic to see number"
        />
      </Grid>
      <Grid item xs container direction="column">
        <Grid item container>
          <HomePageTabs pageView={pageView} updatePageView={updatePageView} />
        </Grid>
        {pageView === PageViews.mapView && (
          <Grid
            item
            xs
            container
            justifyContent="space-between"
            direction="column"
            className={classes.defaultView}
          >
            <Grid item>
              <Grid className={classes.mapContainer}>
                {isClinicsLoading && (
                  <OverlayItem>
                    <CircularProgress />
                  </OverlayItem>
                )}
                <GoogleMap
                  markers={clinics}
                  center={{
                    lat: 37.336,
                    lng: -121.902,
                  }}
                  zoom={10}
                  selectedMarkerId={selectedClinicInfo?.id}
                  onMarkerClick={handleMapMarkerClick}
                />
              </Grid>
              <Grid container px={1} justifyContent="center">
                <SelectedClinicInfoButton
                  selectedClinicInfo={selectedClinicInfo}
                  onClinicInfoClick={handleOpenClinicClick}
                />
              </Grid>
            </Grid>
            <Grid item>
              <FooterButtons
                nextOnly
                classes={{ root: classes.actions }}
                nextButtonLabel="See Available Appointments"
                onNextButtonClick={handleOpenClinicClick}
                disableNext={!selectedClinicInfo?.id}
              />
            </Grid>
          </Grid>
        )}
        {pageView === PageViews.search && (
          <ClinicsListContainer
            clinics={clinics}
            isLoading={isClinicsLoading}
            onClinicSelect={handleClinicSelect}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default ActionUrgentCareHomePage
