import React, { useCallback } from 'react'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Controller, useForm } from 'react-hook-form'
import AuthLayout from '../../components/layouts/AuthLayout'
import { getIdCardUploading, getUserProfile } from '../../store/auth/selectors'
import { RoutePath } from '../../routes'
import useLoadingSuccess from '../../hooks/useLoadingSuccess'
import useLoadingFailureAlert from '../../hooks/useLoadingFailureAlert'
import ThemedImagePicker from '../../components/common/ThemedImagePicker'
import authActions from '../../store/auth/actions'
import gtag from '../../utils/gtag'
import { useIsLabOrderingFlow } from '../../hooks/labOrdering/useIsLabOrderingFlow'
import { StyledButton } from '../../components/common/styled-button'
import { ButtonStack } from '../../components/common/buttons-stack'
import { isLoading } from '../../utils/types'
// import { IndicatedPaymentMethod } from '../../store/appointment/types'
// import appointmentActions from '../../store/appointment/actions'
// import { getSelectedPaymentMethod } from '../../store/appointment/selectors'

const useStyles = makeStyles((theme) => ({
  infoText: {
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '22px',
    letterSpacing: '-0.41px',
    color: theme.palette.primary.dark,
  },
  imagePicker: {
    marginTop: theme.spacing(5),
    height: 150,
  },
}))

const IdCard: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const history = useHistory()

  const uploading = useSelector(getIdCardUploading)

  const profile = useSelector(getUserProfile)

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const submitIdCard = useCallback(
    (values: { idCard: File }) => {
      gtag('event', 'webView_id_submitted')

      dispatch(
        authActions.uploadIdCard.request({
          idCard: values.idCard,
          patientId: profile.id,
        })
      )
    },
    [profile]
  )

  const handleIdUploadingSkip = useCallback(
    (withSkip = false) => {
      if (withSkip) {
        gtag('event', 'webView_id_skipped')
      }

      history.push(
        isLabOrderingFlow
          ? RoutePath.appointmentReasons
          : RoutePath.choosePaymentMethod
      )
    },
    [history, isLabOrderingFlow]
  )

  useLoadingSuccess(uploading, handleIdUploadingSkip)

  useLoadingFailureAlert(uploading)

  const { handleSubmit, errors, control, watch, setValue } = useForm<{
    idCard: File | null
  }>({
    mode: 'onChange',
    shouldFocusError: false,
  })
  const { idCard } = watch()

  const isPageBusy = isLoading(uploading.state)
  // const paymentMethod = useSelector(getSelectedPaymentMethod)

  return (
    <AuthLayout
      title="ID Verification"
      bottomActions={
        <ButtonStack>
          <StyledButton
            variant="contained"
            onClick={handleSubmit(submitIdCard)}
            loading={isLoading(uploading.state)}
            disabled={!idCard || isPageBusy}
          >
            Next
          </StyledButton>
          <StyledButton
            onClick={() => handleIdUploadingSkip(true)}
            disabled={isPageBusy}
          >
            Skip
          </StyledButton>
          {/* <StyledButton
            onClick={() => {
              if (isLabOrderingFlow && paymentMethod === IndicatedPaymentMethod.insurance) {
                dispatch(
                  appointmentActions.setSelectedPaymentMethod(
                    IndicatedPaymentMethod.cash
                  )
                )
              }

              history.goBack()
            }}
            disabled={isPageBusy}
          >
            Back
          </StyledButton> */}
        </ButtonStack>
      }
    >
      <Box marginBottom={3}>
        <Typography className={classes.infoText}>
          A driver&apos;s license, or government ID, is required for identity
          verification purposes. If you&apos;re scheduling for a dependent, the
          legal guardian&apos;s ID is required.
        </Typography>

        <Controller
          control={control}
          name="idCard"
          rules={{ required: true }}
          render={(props) => (
            <ThemedImagePicker
              classes={{ root: classes.imagePicker }}
              label="Tap to Upload Photo of ID"
              image={idCard}
              onImageAdd={(file: File) =>
                setValue(props.name, file, { shouldValidate: true })
              }
              error={Boolean(errors.idCard)}
            />
          )}
        />
      </Box>
    </AuthLayout>
  )
}

export default IdCard
