import React, { useCallback, useState } from 'react'
import { Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

import FooterButtons from '../../components/FooterButtons'
import PhoneNumberButton from '../../components/common/PhoneNumberButton'
import { useClinicPreview } from '../../hooks/useClinicPreview'
import { config } from '../../utils/config'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(6, 4, 4),
      height: '100%',
    },
    headerContainer: {
      padding: theme.spacing(0),
    },
    text: {
      fontFamily: 'Arial',
      color: theme.palette.primary.dark,
      lineHeight: 1.4,
    },
    title: {
      fontWeight: 'bold',
      fontSize: '2.25rem',
      letterSpacing: '-1px',
    },
    phoneButtonContainer: {
      padding: theme.spacing(3, 0),
    },
    buttonRoot: {
      padding: theme.spacing(2),
      border: `2px solid ${theme.palette.primary.light}`,
      boxSizing: 'border-box',
      borderRadius: 12,
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: 'rgba(25, 99, 198, 0.04)',
      },
    },
    subTitle: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
    optionBlock: {
      background: theme.palette.background.default,
      border: `2px solid ${theme.palette.primary.light}`,
      borderRadius: '20px',
      height: 50,
      width: 160,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(1.5),
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        border: `2px solid ${theme.palette.primary.main}`,
        cursor: 'pointer',
      },
    },
    selectedOption: {
      backgroundColor: theme.palette.primary.light,
      border: `2px solid ${theme.palette.primary.main}`,
    },
    optionText: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '0.875rem',
      lineHeight: 1.3,
      textAlign: 'center',
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  }),
  { name: 'AtHomeCareFailure' }
)

const AVAILABLE_TREATMENT_OPTIONS: Record<string, string> = {
  'Virtual Visit': `${config.server.aucWpHost}/virtual-care`,
  'In-Clinic Care': `${config.server.aucWpHost}/in-clinic-care`,
}

interface AtHomeCareFailureProps {
  selectedClinicId: number
  widgetType: string
}

const AtHomeCareFailure: React.FC<AtHomeCareFailureProps> = ({
  selectedClinicId,
  widgetType,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const { clinicInfo, clinicInfoIsLoading } = useClinicPreview(
    selectedClinicId,
    widgetType,
    true
  )

  const [selectedOption, setSelectedOption] = useState('')

  const handleOpenHomePage = useCallback(() => {
    history.goBack()
  }, [history])

  const handleOpenAvailableAppointments = useCallback(() => {
    const url = AVAILABLE_TREATMENT_OPTIONS[selectedOption]
    window.open(url)
  }, [selectedOption])

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      <Grid item>
        <Grid className={classes.headerContainer}>
          <Typography className={clsx(classes.title, classes.text)}>
            At-Home Care is not available in your at this time:
          </Typography>
          <Typography className={classes.text}>
            Call or select an available treatment option below
          </Typography>
        </Grid>
        <Grid className={classes.phoneButtonContainer}>
          <PhoneNumberButton
            isLoading={clinicInfoIsLoading}
            phoneNumber={clinicInfo?.phoneNumber}
          />
        </Grid>
        <Typography className={clsx(classes.text, classes.subTitle)}>
          Available Treatment Options
        </Typography>
        <Grid container justifyContent="space-around">
          {Object.keys(AVAILABLE_TREATMENT_OPTIONS).map((option) => (
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              className={clsx(classes.optionBlock, {
                [classes.selectedOption]: option === selectedOption,
              })}
              onClick={() => setSelectedOption(option)}
            >
              <Typography className={classes.optionText}>{option}</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        <FooterButtons
          nextButtonLabel="See Available Appointments"
          backButtonLabel="Back"
          onNextButtonClick={handleOpenAvailableAppointments}
          onBackButtonClick={handleOpenHomePage}
          disableNext={!selectedOption}
        />
      </Grid>
    </Grid>
  )
}

export default AtHomeCareFailure
