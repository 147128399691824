import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import appointmentActions from 'src/store/appointment/actions'
import { getAppointmentTime } from '../../../store/appointment/selectors'
import gtag from '../../../utils/gtag'
import PhoneNumberButton from '../../../components/common/PhoneNumberButton'
import FooterButtons from '../../../components/FooterButtons'
import InClinicAppointmentTimeSelection from './InClinicAppointmentTimeSelection'
import InClinicChooseClinicPage from './InClinicChooseClinicPage'
import { InClinicSlot } from '../../../store/appointment/models'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        padding: theme.spacing(6, 0, 4),
        flex: 1,
      },
      clinicsPageRoot: {
        padding: theme.spacing(6, 0, 0),
      },
      headerContainer: {
        padding: theme.spacing(0, 4),
      },
      info: {
        fontFamily: 'Arial',
        color: theme.palette.primary.dark,
        lineHeight: 1.4,
      },
      title: {
        fontWeight: 'bold',
        fontSize: '2.25rem',
        letterSpacing: '-1px',
      },
      titleHint: {
        fontFamily: 'NeurialGrotesk',
        fontSize: '1rem',
        lineHeight: 1.3,
      },
      phoneButtonContainer: {
        padding: theme.spacing(3, 3, 4, 3),
      },
      subTitle: {
        fontFamily: 'NeurialGrotesk',
        fontWeight: 700,
        fontSize: '1.375rem',
        lineHeight: 1.3,
        color: theme.palette.primary.dark,
        padding: theme.spacing(0, 4),
      },
      text: {
        fontFamily: 'NeurialGrotesk',
        fontWeight: 500,
        fontSize: '1.125rem',
        lineHeight: 1.3,
        color: theme.palette.primary.dark,
        padding: theme.spacing(0, 4),
      },
      content: {
        flex: 1,
        overflow: 'auto',
      },
      actions: {
        padding: theme.spacing(0, 4),
      },
    }),
  { name: 'InClinicHomePage' }
)

export enum Steps {
  timeSlots = 'timeSlots',
  clinicsList = 'clinicsList',
}

interface InClinicHomePageProps {
  initialPage?: string
  eventName: string
}

const InClinicHomePage: React.FC<InClinicHomePageProps> = ({ eventName }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [step, setStep] = useState(Steps.timeSlots)

  const appointmentTime = useSelector(getAppointmentTime)

  const handleAppTimeSelect = useCallback(
    (slot: InClinicSlot) => {
      dispatch(appointmentActions.updateAppointmentTime({ dateTime: slot }))
    },
    [dispatch]
  )

  useEffect(() => {
    gtag('event', eventName)
  }, [])

  const handleBackToSlotsClick = useCallback(() => {
    setStep(Steps.timeSlots)
    dispatch(appointmentActions.clearAppointmentTime())
  }, [dispatch])

  return (
    <Grid
      container
      direction="column"
      flexWrap="nowrap"
      justifyContent="space-between"
      className={clsx(classes.root, {
        [classes.clinicsPageRoot]: step === Steps.clinicsList,
      })}
    >
      <Grid
        container
        direction="column"
        flexWrap="nowrap"
        className={classes.content}
      >
        <Grid className={classes.headerContainer}>
          <Typography className={clsx(classes.title, classes.info)}>
            Book Appointment
          </Typography>
          <Typography className={clsx(classes.titleHint, classes.info)}>
            Select an available appointment time or call
          </Typography>
        </Grid>
        {step === Steps.timeSlots && (
          <>
            <Grid className={classes.phoneButtonContainer}>
              <PhoneNumberButton phoneNumber="+14087585650" />
            </Grid>

            <Typography className={classes.subTitle}>
              Select Appointment Time
            </Typography>
            <InClinicAppointmentTimeSelection
              selectedTime={appointmentTime}
              onTimeSelect={handleAppTimeSelect}
            />
            <Grid item>
              <FooterButtons
                nextOnly
                classes={{ root: classes.actions }}
                nextButtonLabel="Select"
                disableNext={!appointmentTime}
                onNextButtonClick={() => setStep(Steps.clinicsList)}
              />
            </Grid>
          </>
        )}
        {step === Steps.clinicsList && (
          <InClinicChooseClinicPage onBackClick={handleBackToSlotsClick} />
        )}
      </Grid>
    </Grid>
  )
}

export default InClinicHomePage
