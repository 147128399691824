import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Grid, Theme, Typography, Button } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import parsePhoneNumber from 'libphonenumber-js'
import clsx from 'clsx'
import { isEmpty } from 'lodash'

import KylaIcon from 'src/assets/icons/KylaIcon.svg'
import authActions from 'src/store/auth/actions'
import moment from 'moment'
import FooterButtons from '../../components/FooterButtons'
import {
  getGettingVerificationLoading,
  getDuplicationInfo,
  makeGetRelatedVerificationSentTime,
} from '../../store/auth/selectors'
import {
  isFailure,
  isLoading,
  isSuccess,
  LoadingContext,
} from '../../utils/types'
import { useLoadingChange } from '../../hooks/useLoadingChange'
import {
  VerificationOptionValues,
  VerificationOptionsTypes,
} from '../../store/auth/models'
import { RoutePath } from '../../routes'
import { SUPPORT_PHONE_NUMBER } from '../../utils/general'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(9, 0, 4),
      flex: 1,
    },
    content: {
      padding: theme.spacing(0, 3),
      flex: 1,
    },
    text: {
      lineHeight: '20px',
      fontSize: '0.875rem',
      fontWeight: 500,
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
    },
    textWrapper: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2, 2.5),
      backgroundColor: theme.palette.primary.light,
      borderRadius: '20px 20px 20px 0px',
    },
    optionsContainer: {
      marginBottom: theme.spacing(4),
    },
    button: {
      fontFamily: 'NeurialGrotesk',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '20px',
      textAlign: 'center',
      color: theme.palette.primary.main,
      padding: theme.spacing(2, 2.5),
      border: `2px solid ${theme.palette.primary.light}`,
      boxSizing: 'border-box',
      borderRadius: '20px',
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    selectedButton: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: '#fff !important',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    errorText: {
      marginTop: theme.spacing(0.5),
      color: theme.palette.error.main,
      fontSize: '0.875rem',
    },
    disabledButton: {
      opacity: 0.7,
      pointerEvents: 'none',
    },
    actions: {
      padding: theme.spacing(0, 4),
    },
  }),
  { name: 'VerificationOptions' }
)

const AvailableOptionsLabelsMap: Record<string, string> = {
  email: 'Email Me',
  phone: 'Text Me',
}

const VerificationOptions: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const [verificationType, setVerificationType] = useState<
    VerificationOptionsTypes | ''
  >('')
  const [error, setError] = useState('')

  const duplicationInfo = useSelector(getDuplicationInfo)
  const verificationOptions = duplicationInfo.verificationInfo

  const getRelatedVerificationSentTime = useMemo(
    () =>
      makeGetRelatedVerificationSentTime(
        verificationType as VerificationOptionsTypes
      ),
    [verificationType]
  )
  const verificationSentTime = useSelector(getRelatedVerificationSentTime)

  const gettingVerificationCode = useSelector(getGettingVerificationLoading)
  const verificationIsLoading = isLoading(gettingVerificationCode.state)

  const handleGoBack = useCallback(() => {
    history.goBack()
  }, [history])

  const handleGoNext = useCallback(() => {
    if (verificationSentTime) {
      const currentTime = moment()
      const duration = moment.duration(currentTime.diff(verificationSentTime))
      const sendTimeDifference = duration.seconds()

      // Sent getting req after 1 min only
      if (sendTimeDifference > 60) {
        dispatch(
          authActions.getVerificationCode.request({
            verificationType: verificationType as VerificationOptionsTypes,
            id: verificationOptions.id,
          })
        )
      } else {
        history.push(RoutePath.verificationPage)
      }
    } else {
      dispatch(
        authActions.getVerificationCode.request({
          verificationType: verificationType as VerificationOptionsTypes,
          id: verificationOptions.id,
        })
      )
    }
  }, [verificationType, verificationOptions])

  const handleSelectVerificationType = useCallback(
    (verificationType: string) => {
      setVerificationType(VerificationOptionValues[verificationType])
    },
    [setVerificationType]
  )

  const handleLoadingChange = useCallback((newLoading: LoadingContext) => {
    if (isSuccess(newLoading.state)) {
      history.push(RoutePath.verificationPage)
    }
    if (isFailure(newLoading.state)) {
      setError(newLoading.message || '')
    }
  }, [])

  useLoadingChange(handleLoadingChange, gettingVerificationCode)

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      <Grid
        container
        className={classes.content}
        direction="column"
        justifyContent="space-between"
      >
        <Grid item>
          <img src={KylaIcon} alt="kyla-avatar" />
        </Grid>
        <Grid item container direction="column">
          <Grid item className={classes.textWrapper}>
            <Typography className={classes.text}>
              We found an existing account with our partner Action Urgent Care
              that we believe is linked to you.
            </Typography>
          </Grid>
          <Grid item className={classes.textWrapper}>
            <Typography className={classes.text}>
              To continue, please choose how you would like to receive your
              authentification code or call Kyla support.
            </Typography>
          </Grid>
          <Grid
            item
            container
            className={classes.optionsContainer}
            justifyContent="flex-end"
          >
            {isEmpty(verificationOptions) ? (
              <Button
                variant="outlined"
                className={clsx(classes.button, {
                  [classes.disabledButton]: verificationIsLoading,
                })}
                href={parsePhoneNumber(SUPPORT_PHONE_NUMBER)?.getURI() || ''}
              >
                Call Support
              </Button>
            ) : (
              <>
                {Object.keys(verificationOptions).map((optionKey) =>
                  AvailableOptionsLabelsMap[optionKey] &&
                  verificationOptions[
                    optionKey as keyof typeof verificationOptions
                  ] ? (
                    <Button
                      key={optionKey}
                      variant="outlined"
                      className={clsx(classes.button, {
                        [classes.selectedButton]:
                          optionKey === verificationType.toLowerCase(),
                        [classes.disabledButton]: verificationIsLoading,
                      })}
                      onClick={() => handleSelectVerificationType(optionKey)}
                    >
                      {AvailableOptionsLabelsMap[optionKey]}
                    </Button>
                  ) : null
                )}
              </>
            )}
            {Boolean(error) && (
              <Grid>
                <Typography className={clsx(classes.text, classes.errorText)}>
                  {error}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <FooterButtons
          classes={{ root: classes.actions }}
          nextButtonLabel="Continue"
          backButtonLabel="Back"
          onNextButtonClick={handleGoNext}
          onBackButtonClick={handleGoBack}
          disableNext={!verificationType}
          loadingNext={verificationIsLoading}
          disableBack={verificationIsLoading}
        />
      </Grid>
    </Grid>
  )
}

export default VerificationOptions
