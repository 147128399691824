import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import FooterButtons from '../../../components/FooterButtons'
import ResetPasswordForm, {
  ResetPasswordFormValues,
} from '../../../components/forms/ResetPasswordForm'
import authActions from '../../../store/auth/actions'
import { RoutePath } from '../../../routes'
import { useLoadingChange } from '../../../hooks/useLoadingChange'
import { getCreateAccountLoading } from '../../../store/auth/selectors'
import { isLoading, isSuccess } from '../../../utils/types'
import { trackSignupEvent } from '../../../utils/analytics'
import { LabOrderingPatientInfo } from '../../../utils/labOrdering'
import { PageLayout } from '../../../components/common/page-layout'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      // padding: theme.spacing(9, 4, 4),
      height: '100%',
      position: 'relative',
    },
    content: {
      flex: 1,
      paddingBottom: theme.spacing(4),
    },
    text: {
      fontFamily: 'NeurialGrotesk',
    },
    title: {
      fontWeight: 700,
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      color: theme.palette.primary.dark,
      lineHeight: 1.5,
      marginBottom: theme.spacing(2),
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: '0.875rem',
    },
    noteWrapper: {
      padding: theme.spacing(2, 2.5),
      background: 'rgba(25, 99, 198, 0.1)',
      borderRadius: '20px 20px 20px 0px',
      marginBottom: theme.spacing(1),
    },
    bold: {
      fontWeight: 700,
    },
    subTitle: {
      lineHeight: '20px',
      fontSize: '0.875rem',
      fontWeight: 500,
      color: theme.palette.primary.dark,
    },
  }),
  { name: 'CreatePasswordForNewAcc' }
)

type CreatePasswordForNewAccProps = {
  patientInfo?: LabOrderingPatientInfo
}

const CreatePasswordForNewAcc: React.FC<CreatePasswordForNewAccProps> = ({
  patientInfo,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const dispatch = useDispatch()

  const formRef = useRef<any>(null)

  const [generalValidationError, setGeneralValidationError] = useState('')

  const createAccountLoading = useSelector(getCreateAccountLoading)

  const handleGoNext = useCallback(() => {
    formRef?.current?.submit()
    setGeneralValidationError('')
  }, [formRef, setGeneralValidationError])

  const handleFormSubmit = useCallback(
    (password: ResetPasswordFormValues['password']) => {
      dispatch(authActions.createAccount.request({ password, patientInfo }))
    },
    [dispatch, patientInfo]
  )

  const handleSuccessfulProceed = useCallback(() => {
    history.push(RoutePath.addressInfo)
    trackSignupEvent()
  }, [history])

  useLoadingChange((newLoading) => {
    if (!isSuccess(newLoading.state)) {
      return
    }

    handleSuccessfulProceed()
  }, createAccountLoading)

  const pageIsLoading = useMemo(() => isLoading(createAccountLoading.state), [
    createAccountLoading,
  ])

  return (
    <PageLayout>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        className={classes.root}
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          className={classes.content}
        >
          <Typography className={clsx(classes.text, classes.title)}>
            Create Password
          </Typography>
          <Grid>
            <Box className={classes.noteWrapper}>
              <Typography className={classes.subTitle}>
                <span className={classes.bold}>Please Note:</span> Use 8+
                characters with a mix of letters & numbers.
              </Typography>
            </Box>
            <ResetPasswordForm ref={formRef} onSubmit={handleFormSubmit} />

            <Typography
              align="center"
              className={clsx(classes.text, classes.errorText)}
            >
              {generalValidationError}
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <FooterButtons
            nextOnly
            nextButtonLabel="Submit"
            loadingNext={pageIsLoading}
            onNextButtonClick={handleGoNext}
          />
        </Grid>
      </Grid>
    </PageLayout>
  )
}

export default CreatePasswordForNewAcc
