import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import usePrevious from './usePrevious'

type Location = ReturnType<typeof useLocation>

export default function useLocationChange(
  callback: ({
    location,
    prevLocation,
  }: {
    location: Location
    prevLocation?: Location
  }) => void
): void {
  const location = useLocation()
  const prevLocation = usePrevious(location)

  useEffect(() => {
    callback({ location, prevLocation })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
}
