import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest, delay } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import appointmentActions from 'src/store/appointment/actions'
import axios, { AxiosError } from 'axios'
// eslint-disable-next-line import/no-cycle
import { serverAxios } from '../../utils/api/axios'
// eslint-disable-next-line import/no-cycle
import { extractErrorMessage } from '../../utils/errors'
import { config } from '../../utils/config'
import {
  getStripePublicKey as getStripePublicKeySelector,
  getAppointment as getAppointmentSelector,
  getSelectedPaymentMethod,
  getScheduledAppointment,
  getSavedPaymentId,
  getAppointmentProductId,
  getApptOrderType,
  getIsWithoutMobileApp,
  getCopayInfoSelector,
} from './selectors'
import { IndicatedPaymentMethod } from './types'
import { AsyncTaskStatuses } from '../../utils/store'
import { getConfirmedOrderId } from '../testOrdering/selectors'

function* getAvailableSlots({
  payload: { clinicId, currentDate, examRoom, virtualVisit },
}: ActionType<
  typeof appointmentActions.getAvailableSlots.request
>): SagaIterator {
  try {
    const response = yield call(
      serverAxios.get,
      `${config.server.drChronoHost}/drchrono/public/api/v1/schedule${
        virtualVisit ? '/telemed-aggregated-slots' : ''
      }`,
      {
        params: virtualVisit
          ? { date: currentDate }
          : { clinicId, date: currentDate, examRoom },
      }
    )

    yield put(
      appointmentActions.getAvailableSlots.success({ slotsInfo: response })
    )
  } catch (error) {
    const axiosError = error as AxiosError

    yield put(
      appointmentActions.getAvailableSlots.failure({
        message: extractErrorMessage(
          axiosError,
          'Failed to get available slots'
        ),
        error: axiosError,
      })
    )
  }
}

function* getInClinicAvailableSlots({
  payload: { currentDate },
}: ActionType<
  typeof appointmentActions.getInClinicAvailableSlots.request
>): SagaIterator {
  try {
    const response = yield call(
      serverAxios.get,
      '/drchrono/public/api/v1/schedule/in-clinic/slots',
      {
        params: { date: currentDate },
      }
    )

    yield put(
      appointmentActions.getInClinicAvailableSlots.success({
        slotsInfo: response,
      })
    )
  } catch (error) {
    const axiosError = error as AxiosError

    yield put(
      appointmentActions.getInClinicAvailableSlots.failure({
        message: extractErrorMessage(
          error,
          'Failed to get available in clinic slots'
        ),
        error: axiosError,
      })
    )
  }
}

function* getClinicByZip({
  payload: { zipCode },
}: ActionType<typeof appointmentActions.getClinicByZip.request>): SagaIterator {
  try {
    const response = yield call(
      serverAxios.post,
      'mobile/api/v1/plp/check-zipcode',
      {
        zipCode,
      }
    )

    yield put(
      appointmentActions.getClinicByZip.success({
        eligible: response.eligible,
      })
    )
  } catch (error) {
    const axiosError = error as AxiosError

    yield put(
      appointmentActions.getClinicByZip.failure({
        message: extractErrorMessage(error),
        error: axiosError,
      })
    )
  }
}

function* cancelAppointment({
  payload: { appointmentId },
}: ActionType<
  typeof appointmentActions.cancelAppointment.request
>): SagaIterator {
  try {
    yield call(
      serverAxios.delete,
      `/api/v1/drchrono/appointments/${appointmentId}`
    )

    yield put(appointmentActions.cancelAppointment.success())
  } catch (error) {
    yield put(
      appointmentActions.cancelAppointment.failure({
        error: error as Error,
      })
    )
  }
}

function* getProductById({
  payload,
}: ActionType<typeof appointmentActions.getProductById.request>): SagaIterator {
  try {
    const response = yield call(
      serverAxios.post,
      `/mobile/api/v1/payments/price`,
      {
        priceMetadata: {
          id: payload,
        },
      }
    )

    yield put(
      appointmentActions.getProductById.success({
        productId: payload,
        product: response[0],
      })
    )
  } catch (error) {
    yield put(
      appointmentActions.getProductById.failure({
        productId: payload,
        error: error as Error,
      })
    )
  }
}

function* getStripePublicKey(): SagaIterator {
  try {
    const response = yield call(
      serverAxios.get,
      `/payment-service/api/v1/mobile/collectly/stripe-express-public-key`
    )

    yield put(appointmentActions.getStripePublicKey.success(response))
  } catch (error) {
    yield put(
      appointmentActions.getStripePublicKey.failure({
        error: error as Error,
      })
    )
  }
}

function* createPaymentToken({
  payload,
}: ActionType<
  typeof appointmentActions.createPaymentToken.request
>): SagaIterator {
  try {
    const publicKey = yield select(getStripePublicKeySelector)

    const [month, year] = payload.expiration.split('/')

    const response = yield call(
      axios.post,
      'https://api.stripe.com/v1/tokens',
      new URLSearchParams({
        'card[number]': payload.number.replace(/ /gi, '').replace(/_/gi, ''),
        'card[exp_month]': Number(month).toString(),
        'card[exp_year]': (Number(year) + 2000).toString(),
        'card[cvc]': payload.cvv,
      }),
      {
        headers: {
          authorization: `Bearer ${publicKey}`,
          'content-type': 'application/x-www-form-urlencoded',
        },
      }
    )

    yield put(appointmentActions.createPaymentToken.success(response.data))
  } catch (error) {
    yield put(
      appointmentActions.createPaymentToken.failure({
        error: error as Error,
      })
    )
  }
}

function* scheduleAppointment(): SagaIterator {
  try {
    const { reason, dateTime, clinic } = yield select(getAppointmentSelector)

    const isWithoutMobileApp = yield select(getIsWithoutMobileApp)
    const orderType = yield select(getApptOrderType)
    const selectedPaymentMethod = yield select(getSelectedPaymentMethod)
    const copayInfo = yield select(getCopayInfoSelector)

    const paymentMethodId = yield select(getSavedPaymentId)

    const isCashPayment = selectedPaymentMethod === IndicatedPaymentMethod.cash

    const confirmedOrderId = yield select(getConfirmedOrderId)

    const response = yield call(
      serverAxios.post,
      '/webview/api/v1/appointments/schedule',
      {
        orderType,
        billingType: isCashPayment ? 'CASH' : 'INSURANCE',
        isWithoutMobileApp,
        reason,
        ...(orderType === 'IN_CLINIC_WEBVIEW'
          ? {
              officeId: clinic.id,
            }
          : {}),
        ...(!isCashPayment && !copayInfo
          ? {
              paymentMethodId,
            }
          : {}),
        scheduledTime: `${dateTime.start}.000`,
        ...(confirmedOrderId ? { testOrderId: confirmedOrderId } : {}),
      },
      {
        params: {
          isDraft: Boolean(isCashPayment || (!isCashPayment && copayInfo)),
        },
      }
    )

    yield put(appointmentActions.scheduleAppointment.success(response))
  } catch (error) {
    yield put(appointmentActions.scheduleAppointment.failure({ error }))
  }
}

function* getPaymentMethods(): SagaIterator {
  try {
    const response = yield call(
      serverAxios.get,
      '/mobile/api/v1/payments/payment-methods'
    )

    yield put(
      appointmentActions.getPaymentMethods.success({ methods: response })
    )
  } catch (error) {
    yield put(
      appointmentActions.getPaymentMethods.failure({
        error: error as Error,
      })
    )
  }
}

// eslint-disable-next-line require-yield
function* savePaymentMethod({
  payload: { id, type, card, saveCard },
}: ActionType<
  typeof appointmentActions.savePaymentMethod.request
>): SagaIterator {
  try {
    const response = yield call(
      serverAxios.post,
      '/mobile/api/v1/payments/attach',
      {
        type: card!.brand,
        data: {
          autoChargeEnabled: true,
          cardType: type,
          country: card?.country,
          expMonth: card?.exp_month,
          expYear: card?.exp_year,
          last4: card?.last4,
          name: `${card?.brand} ${card?.last4}`,
          userToken: id,
        },
        ...(saveCard ? { saveCard: true } : {}),
      }
    )

    yield put(
      appointmentActions.savePaymentMethod.success(response.collectlyId)
    )
  } catch (error) {
    yield put(appointmentActions.savePaymentMethod.failure({ error }))
  }
}

export function* checkApptPaymentStatus(
  action: ActionType<typeof appointmentActions.checkApptPaymentStatus.request>
): SagaIterator {
  const { asyncTaskId } = action.payload

  try {
    const response = yield call(
      serverAxios.get,
      `/mobile/api/v2/payments/async-task/${asyncTaskId}`
    )

    if (response.asyncTaskStatus === AsyncTaskStatuses.failed) {
      yield put(
        appointmentActions.checkApptPaymentStatus.failure({
          error: new Error(
            response.asyncTaskErrorMessage || 'Failed to pay for appointment'
          ),
        })
      )
    } else if (response.asyncTaskStatus === AsyncTaskStatuses.successful) {
      yield put(
        appointmentActions.checkApptPaymentStatus.success({
          appointmentId: response.appointmentId,
        })
      )
    } else {
      yield delay(2000)
      yield put(
        appointmentActions.checkApptPaymentStatus.request({
          asyncTaskId,
        })
      )
    }
  } catch (error) {
    yield put(
      appointmentActions.checkApptPaymentStatus.failure({
        error,
      })
    )
  }
}

function* payForAppointment(): SagaIterator {
  try {
    const { paymentOrderId } = yield select(getScheduledAppointment)
    const paymentMethodId = yield select(getSavedPaymentId)
    const externalPriceId = yield select(getAppointmentProductId)
    const copayInfo = yield select(getCopayInfoSelector)
    const selectedPaymentMethod = yield select(getSelectedPaymentMethod)
    const isCashPayment = selectedPaymentMethod === IndicatedPaymentMethod.cash

    const response = yield call(
      serverAxios.post,
      '/mobile/api/v2/payments/async-task',
      {
        paymentMethodId,
        paymentOrderId,
        externalPriceId,
        ...(!isCashPayment ? { type: 'copay' } : {}),
        ...(copayInfo && !isCashPayment
          ? { copayAmount: copayInfo.paymentAmount }
          : {}),
      }
    )

    yield put(
      appointmentActions.checkApptPaymentStatus.request({
        asyncTaskId: response.id,
      })
    )
  } catch (error) {
    yield put(appointmentActions.payForAppointment.failure({ error }))
  }
}

function* checkForPendingAppointment(): SagaIterator {
  try {
    const response = yield call(
      serverAxios.get,
      '/mobile/api/v1/appointments',
      {
        params: {
          isCompleted: false,
        },
      }
    )

    yield put(
      appointmentActions.checkForPendingAppointment.success(
        response.length > 0 ? response[0] : null
      )
    )
  } catch (error) {
    yield put(appointmentActions.checkForPendingAppointment.failure({ error }))
  }
}

function* getCopayStatus(
  action: ActionType<typeof appointmentActions.getCopayStatus.request>
): SagaIterator {
  const { isVirtual } = action.payload

  try {
    const response = yield call(
      serverAxios.get,
      '/mobile/api/v2/payments/is-copay-valid',
      {
        params: {
          flowSourceType: 'DOCTORS_CONSULTATION',
          orderType: isVirtual ? 'APPOINTMENT' : 'IN_CLINIC_WEBVIEW',
        },
      }
    )

    yield put(
      appointmentActions.getCopayStatus.success({
        copayStatus: response,
      })
    )
  } catch (error) {
    yield put(
      appointmentActions.getCopayStatus.failure({
        error: error as Error,
      })
    )
  }
}

function* getCopayInfo(
  action: ActionType<typeof appointmentActions.getCopayInfo.request>
): SagaIterator {
  const { isVirtual } = action.payload
  try {
    const response = yield call(
      serverAxios.get,
      '/mobile/api/v2/payments/copay-amount',
      {
        params: {
          orderType: isVirtual ? 'APPOINTMENT' : 'IN_CLINIC_WEBVIEW',
        },
      }
    )

    yield put(
      appointmentActions.getCopayInfo.success({
        copayInfo: response,
      })
    )
  } catch (error) {
    yield put(
      appointmentActions.getCopayInfo.failure({
        error: error as Error,
      })
    )
  }
}

function* getReasonsForConsultation({
  payload: { consultationType },
}: ActionType<
  typeof appointmentActions.getReasonsForConsultation.request
>): SagaIterator {
  try {
    const response = yield call(
      serverAxios.get,
      '/mobile/public/api/v1/appointments/reasons/with-urgency',
      {
        params: { consultationType },
      }
    )

    yield put(
      appointmentActions.getReasonsForConsultation.success({
        reasonsForConsultation: response,
      })
    )
  } catch (error) {
    const axiosError = error as AxiosError

    yield put(
      appointmentActions.getReasonsForConsultation.failure({
        error: axiosError,
      })
    )
  }
}

export const appointmentSagas = [
  takeLatest(
    getType(appointmentActions.getAvailableSlots.request),
    getAvailableSlots
  ),
  takeLatest(
    getType(appointmentActions.getInClinicAvailableSlots.request),
    getInClinicAvailableSlots
  ),
  takeLatest(
    getType(appointmentActions.getClinicByZip.request),
    getClinicByZip
  ),
  takeLatest(appointmentActions.cancelAppointment.request, cancelAppointment),
  takeLatest(appointmentActions.getStripePublicKey.request, getStripePublicKey),
  takeLatest(appointmentActions.createPaymentToken.request, createPaymentToken),
  takeLatest(
    appointmentActions.scheduleAppointment.request,
    scheduleAppointment
  ),
  takeLatest(appointmentActions.savePaymentMethod.request, savePaymentMethod),
  takeLatest(appointmentActions.payForAppointment.request, payForAppointment),
  takeLatest(
    appointmentActions.checkApptPaymentStatus.request,
    checkApptPaymentStatus
  ),
  takeLatest(appointmentActions.getProductById.request, getProductById),
  takeLatest(
    appointmentActions.checkForPendingAppointment.request,
    checkForPendingAppointment
  ),
  takeLatest(appointmentActions.getPaymentMethods.request, getPaymentMethods),
  takeLatest(appointmentActions.getCopayStatus.request, getCopayStatus),
  takeLatest(appointmentActions.getCopayInfo.request, getCopayInfo),
  takeLatest(
    appointmentActions.getReasonsForConsultation.request,
    getReasonsForConsultation
  ),
]
