import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CircularProgress, Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import FooterButtons from '../../components/FooterButtons'
import ReasonsContainer from '../../containers/ReasonsContainer'
import {
  getAppointment,
  getSelectedPaymentMethod,
} from '../../store/appointment/selectors'
import { config } from '../../utils/config'
import gtag from '../../utils/gtag'
import { Reason } from '../../store/appointment/models'
import { getAnalyticsQueries } from '../../store/analytics/selectors'
import OverlayItem from '../../components/common/OverlayItem'
import useConsultationReasons from '../../hooks/useConsultationReasons'
import { isLoading } from '../../utils/types'
import { decorateUrlGtag } from '../../utils/decorateUrlGtag'
import { useIsLabOrderingFlow } from '../../hooks/labOrdering/useIsLabOrderingFlow'
import { useDispatch } from '../../store'
import { RoutePath } from '../../routes'
import appointmentActions from '../../store/appointment/actions'
import { IndicatedPaymentMethod } from '../../store/appointment/types'
import axios from 'axios'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      flex: 1,
      paddingBottom: theme.spacing(4),
      position: 'relative',
    },
    titleContainer: {
      padding: theme.spacing(9, 4.5, 0),
    },
    title: {
      fontFamily: 'Arial',
      fontWeight: 'bold',
      fontSize: '1.375rem',
      lineHeight: 1.4,
      letterSpacing: '-0.5px',
      color: theme.palette.primary.dark,
    },
    content: {
      flex: 1,
      overflow: 'auto',
      marginBottom: theme.spacing(3),
    },
    actions: {
      padding: theme.spacing(0, 4),
    },
  }),
  { name: 'ReasonsPage' }
)

interface LocationState {
  initialPage?: string
  symptomsCheckerFlow?: boolean
}

interface ReasonsPageProps {
  widgetType: string
}

const ReasonsPage: React.FC<ReasonsPageProps> = ({ widgetType }) => {
  const classes = useStyles()
  const history = useHistory()
  const { state } = useLocation<LocationState>()

  const dispatch = useDispatch()

  const formRef = useRef<any>(null)

  const currentAppointment = useSelector(getAppointment)
  const analyticsQueries = useSelector(getAnalyticsQueries)

  const { fetching } = useConsultationReasons()
  const isFetchingConsultationReasons = isLoading(fetching.state)

  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const isCashPayment = selectedPaymentMethod === IndicatedPaymentMethod.cash

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const handleGoBack = useCallback(() => {
    history.goBack()
  }, [history])

  useEffect(() => {
    if (!isLabOrderingFlow) {
      return
    }

    dispatch(
      appointmentActions.preselectAppointment({
        appointment: { reason: 'Lab Order' },
      })
    )

    history.replace(
      isCashPayment ? RoutePath.appointmentTime : RoutePath.choosePaymentMethod
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = useCallback(
    async ({ reason }: Reason) => {
      if (isLabOrderingFlow) {
        return
      }

      if (submitting) {
        return
      }

      setSubmitting(true)

      try {
        const response = await axios.post(
          `${config.server.kylaWpHost}/api/appointment`,
          {
            appointment: {
              ...currentAppointment,
              reason,
              symptomsCheckerFlow: state?.symptomsCheckerFlow,
            },
            sourceWidgetType: widgetType,
            clinicId: currentAppointment.clinic.id,
          }
        )

        const url = queryString.stringifyUrl({
          url: `${config.server.kylaWpHost}/confirm-appointment`,
          query: {
            appointment: response.data.key,
            analyticsQueries: window.btoa(JSON.stringify(analyticsQueries)),
            sourceWidgetType: widgetType,
          },
        })

        // if (state?.initialPage) {
        //   history.push(state.initialPage)
        // }

        if (state?.symptomsCheckerFlow) {
          gtag('event', 'sc_openAppointmentConfirmationFlow')
        }

        // https://stackoverflow.com/questions/73066384/manually-generate-ga-linker-parameter-with-gtag-ga4/76123946#76123946
        // https://support.google.com/analytics/answer/10071811?hl=en#zippy=%2Cmanual-setup
        window.location.href = decorateUrlGtag(url)
      } finally {
        setSubmitting(false)
      }
    },
    [
      currentAppointment,
      widgetType,
      state,
      JSON.stringify(analyticsQueries),
      isLabOrderingFlow,
      isCashPayment,
    ]
  )

  const triggerSubmit = useCallback(() => {
    formRef.current.submit()
  }, [])

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      {isFetchingConsultationReasons && (
        <OverlayItem>
          <CircularProgress />
        </OverlayItem>
      )}

      <Grid container direction="column" className={classes.content}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.titleContainer}
        >
          <Typography className={classes.title}>
            Reason for Consultation
          </Typography>
        </Grid>

        <ReasonsContainer
          innerRef={formRef}
          onSubmit={handleSubmit}
          widgetType={widgetType}
        />
      </Grid>
      <FooterButtons
        classes={{ root: classes.actions }}
        nextButtonLabel="Continue"
        backButtonLabel="Back"
        onNextButtonClick={triggerSubmit}
        onBackButtonClick={handleGoBack}
      />
    </Grid>
  )
}

export default ReasonsPage
