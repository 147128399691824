import React, {
  useCallback,
  useImperativeHandle,
  useState,
  useMemo,
} from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Grid, FormControl, FormHelperText, Typography } from '@mui/material'
import MaskedInput from 'react-text-mask'
import { useSelector } from 'react-redux'
import { grey } from '@mui/material/colors'

import ThemedTextField from '../common/ThemedTextField'
import { US_STATES, US_ZIP_MASK } from '../../utils/general'
import ThemedSelect from '../common/ThemedSelect'
import IOSSwitch from '../common/IOSSwitch'
import { getUserProfile } from '../../store/auth/selectors'

export type AddressFormValues = {
  address: string
  state: string
  city: string
  zipCode: string
}

export type AddressFormActions = {
  submit(): void
}

export type AddressFormProps = {
  disabled: boolean
  withPreselection: boolean
  onSubmit(data: AddressFormValues): void
  initialValues?: AddressFormValues
}

const AddressForm = React.forwardRef<AddressFormActions, AddressFormProps>(
  (
    {
      withPreselection,
      disabled,
      onSubmit,
      initialValues = { address: '', zipCode: '', state: '', city: '' },
    },
    ref
  ) => {
    const profile = useSelector(getUserProfile)

    const [useShippingAddress, setUseShippingAddress] = useState(false)

    const { register, handleSubmit, errors, control, watch, reset } = useForm<
      AddressFormValues
    >({
      mode: 'onChange',
      shouldFocusError: false,
      defaultValues: initialValues,
    })

    const { state } = watch()

    const handleNewAccountSubmit = useCallback(
      (values: AddressFormValues) => {
        onSubmit(values)
      },
      [onSubmit]
    )

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(handleNewAccountSubmit),
    }))

    const handleUseProfileInfoForShippingAddress = useCallback(
      ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
        setUseShippingAddress(checked)

        if (checked && profile) {
          reset({
            address: profile.street || '',
            state: profile.state || '',
            city: profile.city || '',
            zipCode: profile.zipCode || '',
          })
        } else {
          reset({
            address: '',
            state: '',
            city: '',
            zipCode: '',
          })
        }
      },
      [setUseShippingAddress, reset, profile]
    )

    const profileAddressExist = useMemo(
      () => profile.street || profile.state || profile.city || profile.zipCode,
      [JSON.stringify(profile)]
    )

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ThemedTextField
            disabled={disabled}
            name="address"
            autoComplete="off"
            fullWidth
            inputRef={register({
              required: 'Required',
              minLength: {
                value: 10,
                message:
                  'The length of the address cannot be less than 10 characters',
              },
            })}
            color="primary"
            placeholder="Address line 1"
            error={Boolean(errors.address)}
          />
          {Boolean(errors.address?.message) && (
            <FormHelperText style={{ color: 'red', paddingLeft: '8px' }}>
              {errors.address?.message}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <ThemedTextField
            disabled={disabled}
            name="city"
            autoComplete="off"
            fullWidth
            inputRef={register({
              required: 'Required',
            })}
            color="primary"
            placeholder="City"
            error={Boolean(errors.city)}
          />
          {Boolean(errors.city?.message) && (
            <FormHelperText style={{ color: 'red', paddingLeft: '8px' }}>
              {errors.city?.message}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={(props) => (
              <MaskedInput
                disabled={disabled}
                mask={US_ZIP_MASK}
                render={(ref: React.Ref<any>, props: any) => (
                  <ThemedTextField
                    autoComplete="off"
                    ref={ref}
                    fullWidth
                    placeholder="Zip Code"
                    error={Boolean(errors.zipCode)}
                    {...props}
                  />
                )}
                {...props}
              />
            )}
            control={control}
            name="zipCode"
            rules={{
              required: 'Required',
              pattern: {
                value: /^\d{5}$/,
                message: 'Invalid zip',
              },
            }}
          />

          {Boolean(errors.zipCode?.message) && (
            <FormHelperText style={{ color: 'red', paddingLeft: '8px' }}>
              {errors.zipCode?.message}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Controller
              as={
                <ThemedSelect
                  disabled={disabled}
                  placeholder="State"
                  values={US_STATES}
                  error={Boolean(errors.state)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                />
              }
              control={control}
              name="state"
              defaultValue={state || ''}
              rules={{ required: 'Required' }}
            />
          </FormControl>

          {Boolean(errors.state?.message) && (
            <FormHelperText style={{ color: 'red', paddingLeft: '8px' }}>
              {errors.state?.message}
            </FormHelperText>
          )}
        </Grid>

        {withPreselection && profileAddressExist && (
          <Grid item container xs={12} gap={1} alignItems="center">
            <IOSSwitch
              disabled={disabled}
              onChange={handleUseProfileInfoForShippingAddress}
              checked={useShippingAddress}
            />

            <Typography lineHeight={1.25} color={grey[600]}>
              Use address info
            </Typography>
          </Grid>
        )}
      </Grid>
    )
  }
)

export default AddressForm
