import { ActionType, createReducer } from 'typesafe-actions'

import { oneInsert } from '../../mutators/relations'
import { attachmentsActions } from '../actions'

interface State {
  attachmentsById: Record<number, File>
}

const initialState = {
  attachmentsById: {},
}

export default createReducer<State, ActionType<typeof attachmentsActions>>(
  initialState
).handleAction(
  attachmentsActions.getAttachment.success,
  (state, { payload: { attachmentId, attachment } }): State => ({
    ...state,
    attachmentsById: oneInsert(
      attachment,
      attachmentId,
      state.attachmentsById
    ),
  })
)
