import { AxiosError } from 'axios'

export enum LoadingState {
  idle,
  loading,
  success,
  failure,
}

export interface LoadingContext {
  state: LoadingState
  message?: string | null
  error?: Error | AxiosError
}

export const LoadingContextDefault: LoadingContext = {
  state: LoadingState.idle,
  message: undefined,
}

export type OneToOne<T, K extends string | number | symbol = number> = {
  [P in K]: T
}

export type ById<D> = OneToOne<D>

export const isLoading = (currentState: LoadingState): boolean =>
  currentState === LoadingState.loading
export const isSuccess = (currentState: LoadingState): boolean =>
  currentState === LoadingState.success
export const isFailure = (currentState: LoadingState): boolean =>
  currentState === LoadingState.failure

export const getLoadingState = (loading: LoadingContext) => ({
  pending: isLoading(loading.state),
  success: isSuccess(loading.state),
  failure: isFailure(loading.state),
})
