import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LoadingContext } from 'src/utils/types'
import { LabOrderDraft } from '../../store/testOrdering/models'
import { createLabOrderDraftAction } from '../../store/testOrdering'

import {
  getLabOrderDraft,
  getCreatingLabOrderDraft,
  getLabOrderDataForDraftCreation,
} from '../../store/testOrdering/selectors'

interface UseLabOrderDraftCreate {
  creating: LoadingContext

  labOrderDraft: LabOrderDraft | null
  createLabOrderDraft(): void
}

export const useLabOrderDraftCreate = (): UseLabOrderDraftCreate => {
  const dispatch = useDispatch()

  const labOrderDataForDraftCreation = useSelector(
    getLabOrderDataForDraftCreation
  )

  const creating = useSelector(getCreatingLabOrderDraft)
  const labOrderDraft = useSelector(getLabOrderDraft)

  const createLabOrderDraft = useCallback(() => {
    if (labOrderDataForDraftCreation) {
      dispatch(createLabOrderDraftAction(labOrderDataForDraftCreation))
    }
  }, [dispatch, JSON.stringify(labOrderDataForDraftCreation)])

  return { createLabOrderDraft, labOrderDraft, creating }
}
