import { combineReducers } from 'redux'
import { StateType } from 'typesafe-actions'

import appointment from './appointment/reducers/index'
import clinics from './clinics/reducers'
import auth from './auth/reducers/index'
import insurance from './insurance/reducers'
import attachments from './attachments/reducers'
import analytics from './analytics/reducers'
import testOrdering from './testOrdering'

export const reducer = combineReducers({
  clinics,
  appointment,
  auth,
  insurance,
  attachments,
  analytics,
  testOrdering,
})

export type State = StateType<typeof reducer>
