import React from 'react'
import clsx from 'clsx'
import { Button, Grid, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import gtag from '../../utils/gtag'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 180,
      margin: 'auto',
      width: '80%',
      background: theme.palette.background.default,
      borderRadius: 10,
      boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)',
      padding: theme.spacing(2),
      display: 'none',
      transition: 'opacity 0.15s linear',
    },
    showedRoot: {
      display: 'inherit',
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
      marginBottom: theme.spacing(2),
    },
    dialogTitle: {
      marginBottom: 0,
      fontWeight: 700,
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      lineHeight: 1.5,
    },
    button: {
      marginBottom: theme.spacing(1.5),
      borderRadius: '12px',
      height: 56,
      fontSize: '1rem',
      fontFamily: 'NeurialGrotesk',
      fontWeight: 700,
    },
    buttonsContainer: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'ChronoDuplicationDialog' }
)

interface ChronoDuplicationDialogProps {
  open: boolean
  email: string
  phone: string

  onClose(): void
  validateAccount(): void
  skipDuplicationSafeguards(): void
}

const ChronoDuplicationDialog: React.FC<ChronoDuplicationDialogProps> = ({
  open,
  email,
  phone,
  onClose,
  validateAccount,
  skipDuplicationSafeguards,
}) => {
  const classes = useStyles()

  const handleValidateAccount = () => {
    gtag('event', 'duplicate_validate_my_medical_account')

    validateAccount()
  }

  const handleThisIsNotMyAcc = () => {
    gtag('event', 'duplicate_not_my_account')

    skipDuplicationSafeguards()
  }

  const handleBackClick = () => {
    gtag('event', 'duplicate_back')

    onClose()
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={clsx(classes.root, { [classes.showedRoot]: open })}
    >
      <Grid>
        <Typography className={clsx(classes.text)}>
          We have found an existing medical account with our partners at Action
          Urgent Care
        </Typography>
        {email && (
          <Typography className={clsx(classes.text)}>
            <b>Email:</b>
            {email}
          </Typography>
        )}
        {phone && (
          <Typography className={clsx(classes.text)}>
            <b>Phone Number:</b>
            {phone}
          </Typography>
        )}
        <Typography className={clsx(classes.text)}>
          Would you like to?
        </Typography>
      </Grid>
      <Grid container>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={handleValidateAccount}
        >
          Validate My Medical Account
        </Button>

        <Button
          fullWidth
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={handleThisIsNotMyAcc}
        >
          This is Not My Account
        </Button>

        <Button
          fullWidth
          color="primary"
          className={classes.button}
          onClick={handleBackClick}
        >
          Back
        </Button>
      </Grid>
    </Grid>
  )
}

export default ChronoDuplicationDialog
