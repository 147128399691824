import { Box, BoxProps } from '@mui/material'

const Centered: React.FC<BoxProps> = (props) => {
  return (
    <Box
      display="flex"
      flex={1}
      alignItems="center"
      justifyContent="center"
      {...props}
    />
  )
}

export default Centered
