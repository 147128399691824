import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import authActions from 'src/store/auth/actions'
import FooterButtons from '../../../components/FooterButtons'
import NewAccountForm, {
  NewAccountFormValues,
} from '../../../components/forms/NewAccountForm'
import {
  getCheckingKylaDuplicate,
  getDuplicationInfo,
  getGeneralInfo,
} from '../../../store/auth/selectors'
import {
  isFailure,
  isLoading,
  isSuccess,
  LoadingContext,
} from '../../../utils/types'
import gtag from '../../../utils/gtag'
import { useLoadingChange } from '../../../hooks/useLoadingChange'
import { RoutePath } from '../../../routes'
import KylaDuplicationDialog from '../../../components/dialogs/KylaDuplicationDialog'
import { TypeOfSkip } from '../../../store/auth/models'
import { LabOrderingPatientInfo } from '../../../utils/labOrdering'
import moment from 'moment'
import { PageLayout } from '../../../components/common/page-layout'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      // padding: theme.spacing(9, 4, 4),
      height: '100%',
      position: 'relative',
    },
    disabled: {
      opacity: 0.7,
      pointerEvents: 'none',
    },
    text: {
      fontFamily: 'NeurialGrotesk',
    },
    title: {
      fontWeight: 700,
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      color: theme.palette.primary.dark,
      lineHeight: 1.5,
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: '0.875rem',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      marginBottom: theme.spacing(2),
    },
    errorWrapper: {
      maxHeight: 100,
      overflow: 'auto',
    },
  }),
  { name: 'CreateAccount' }
)

export type CreateAccountProps = {
  defaultValues?: LabOrderingPatientInfo
}

const CreateAccount: React.FC<CreateAccountProps> = ({ defaultValues }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const generalInfo = useSelector(getGeneralInfo)
  const checkingKylaDuplicate = useSelector(getCheckingKylaDuplicate)
  const isCheckingKylaDuplicate = isLoading(checkingKylaDuplicate.state)

  const duplicationInfo = useSelector(getDuplicationInfo)

  const [error, setError] = useState('')
  const [openDuplicationDialog, setOpenDuplicationDialog] = useState(false)

  const handleCheckingKylaDuplicateLoadingChange = useCallback(
    (newLoading: LoadingContext) => {
      if (isSuccess(newLoading.state)) {
        history.push(RoutePath.verifyEmail)
      }

      if (isFailure(newLoading.state)) {
        gtag('event', 'webView_signup_failed', {
          message: newLoading.message,
        })

        if (duplicationInfo?.kylaId) {
          setOpenDuplicationDialog(true)
        } else {
          setError(newLoading.message as string)
        }
      }
    },
    [history, duplicationInfo]
  )

  useLoadingChange(
    handleCheckingKylaDuplicateLoadingChange,
    checkingKylaDuplicate
  )

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)

  const formRef = useRef<any>(null)

  const handleGoNext = useCallback(() => {
    formRef?.current?.submit()
    setError('')
  }, [formRef, setError])

  const handleGoBack = useCallback(() => {
    history.goBack()
  }, [history])

  const handleFormSubmit = useCallback(
    (data: NewAccountFormValues) => {
      dispatch(authActions.updateGeneralInfo({ info: data }))
      dispatch(authActions.checkKylaDuplicate.request({ typeOfSkip: null }))
    },
    [dispatch]
  )

  const handleSkipLogin = useCallback(() => {
    setOpenDuplicationDialog(false)
    dispatch(authActions.clearData())
    history.push(RoutePath.signIn)
  }, [])

  const handleSkipDuplicationSafeguards = useCallback(
    (typeOfSkip: TypeOfSkip) => {
      setOpenDuplicationDialog(false)
      dispatch(authActions.checkKylaDuplicate.request({ typeOfSkip }))
    },
    []
  )

  const handleCloseDuplicationDialog = useCallback(() => {
    setOpenDuplicationDialog(false)
    dispatch(authActions.clearDuplicationInfo())
  }, [])

  useEffect(() => {
    gtag('event', 'apptstart_createnew')
  }, [])

  const values = useMemo(() => {
    if (defaultValues) {
      return {
        ...defaultValues,
        ...generalInfo,
        dob: moment(defaultValues.dateOfBirth).format(),
      }
    }

    return generalInfo
  }, [defaultValues, generalInfo])

  return (
    <PageLayout>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        className={clsx(classes.root, {
          [classes.disabled]: openDuplicationDialog,
        })}
        wrap="nowrap"
      >
        <Grid className={classes.content}>
          <Typography className={clsx(classes.text, classes.title)}>
            Create an account
          </Typography>
          <NewAccountForm
            ref={formRef}
            disabled={isCheckingKylaDuplicate}
            onSubmit={handleFormSubmit}
            enableNextButton={() => setNextButtonDisabled(false)}
            disableNextButton={() => setNextButtonDisabled(true)}
            defaultValues={values}
          />
          {error && (
            <Grid className={classes.errorWrapper}>
              <Typography className={clsx(classes.text, classes.errorText)}>
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid>
          <FooterButtons
            nextButtonLabel="Continue"
            backButtonLabel="Back"
            onNextButtonClick={handleGoNext}
            onBackButtonClick={handleGoBack}
            disableNext={nextButtonDisabled}
            loadingNext={isCheckingKylaDuplicate}
            disableBack={isCheckingKylaDuplicate}
          />
        </Grid>
      </Grid>
      <KylaDuplicationDialog
        open={openDuplicationDialog}
        email={duplicationInfo?.kylaEmail as any}
        skipSignUp={handleSkipLogin}
        skipDuplicationSafeguards={handleSkipDuplicationSafeguards}
        onClose={handleCloseDuplicationDialog}
      />
    </PageLayout>
  )
}

export default CreateAccount
