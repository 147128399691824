import React, {
  useCallback,
  useMemo,
  useState,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react'
import { Grid, Theme, Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import ThemedTextField from '../components/common/ThemedTextField'
import RoundedButton from '../components/common/RoundedButton'
import { TelemedicineWidgetTypes } from '../utils/appointment/general'
import IOSSwitch from '../components/common/IOSSwitch'
import appointmentActions from '../store/appointment/actions'
import { Reason } from '../store/appointment/models'
import useConsultationReasons from '../hooks/useConsultationReasons'
import { useIsLabOrderingFlow } from '../hooks/labOrdering/useIsLabOrderingFlow'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      flex: 1,
      overflow: 'auto',
      position: 'relative',
    },
    currentReason: {
      width: '100%',
      marginTop: theme.spacing(2),
      borderRadius: '10px',
    },
    appointmentReason: {
      padding: theme.spacing(0, 4.5),
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      lineHeight: 1.25,
      color: theme.palette.primary.dark,
    },
    switchContainer: {
      marginTop: theme.spacing(2),
    },
    hidden: {
      display: 'none',
    },
  }),
  { name: 'ReasonsContainer' }
)

const APPOINTMENT_REASON_OTHER = 'Other'

type FormValues = {
  notes: string
}

interface ReasonsContainerProps {
  innerRef?: any
  onSubmit(reason: Reason): void
  widgetType: string
}
const ReasonsContainer: React.FC<ReasonsContainerProps> = ({
  innerRef,
  widgetType,
  onSubmit,
}) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const rootBoxRef = useRef<null | HTMLDivElement>(null)

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const isTelemedicineClinic = useMemo(
    () => TelemedicineWidgetTypes.includes(widgetType),
    [widgetType]
  )

  const { reasonsForConsultation, fetchReasons } = useConsultationReasons()

  useEffect(() => {
    fetchReasons(
      isTelemedicineClinic || isLabOrderingFlow ? 'online' : 'in-clinic'
    )
  }, [])

  const [withoutPhone, setWithoutPhone] = useState(false)

  const [selectedReason, setSelectedReason] = useState<Reason>({
    reason: '',
    urgentReason: false,
  })

  const { control, errors, setValue, handleSubmit: handleFormSubmit } = useForm<
    FormValues
  >({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      notes: '',
    },
  })

  const otherFieldVisible = APPOINTMENT_REASON_OTHER === selectedReason.reason

  const handleReasonSubmit = useCallback(
    ({ notes }) => {
      if (notes && otherFieldVisible && notes !== selectedReason.reason) {
        onSubmit({ ...selectedReason, reason: notes })
      } else {
        onSubmit(selectedReason)
      }
    },
    [onSubmit, selectedReason, otherFieldVisible]
  )

  useImperativeHandle(innerRef, () => ({
    submit: handleFormSubmit(handleReasonSubmit),
  }))

  const handleWithoutPhoneToggleChange = useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setWithoutPhone(checked)
      dispatch(
        appointmentActions.addWillNotUseMobilePhoneFlag({ flag: checked })
      )
    },
    []
  )

  const errorMsg = (errors.notes as any)?.message && (
    <Typography variant="caption" color="error">
      {(errors.notes as any).message}
    </Typography>
  )

  useEffect(() => {
    if (errorMsg && rootBoxRef?.current) {
      rootBoxRef.current.scrollTop = rootBoxRef.current.scrollHeight
    }
  }, [errorMsg])

  return (
    <Grid
      container
      item
      xs
      className={classes.root}
      direction="column"
      ref={rootBoxRef}
    >
      <Grid item className={classes.appointmentReason}>
        <Grid container spacing={3} direction="column">
          {Boolean(reasonsForConsultation.length) && (
            <Grid item xs={12}>
              {[
                ...(isTelemedicineClinic
                  ? []
                  : [{ reason: 'Annual Physical', urgentReason: false }]),
                ...reasonsForConsultation,
                {
                  reason: 'Other',
                  urgentReason: false,
                },
              ].map(({ reason, urgentReason }) => (
                <Box key={reason} mb={1.5} mt={1.5}>
                  <RoundedButton
                    active={reason === selectedReason.reason}
                    onClick={() => {
                      const isOtherReason = reason === APPOINTMENT_REASON_OTHER
                      setValue('notes', isOtherReason ? '' : reason, {
                        shouldValidate: !isOtherReason,
                      })

                      setSelectedReason({ reason, urgentReason })
                    }}
                  >
                    {reason}
                  </RoundedButton>
                </Box>
              ))}

              {!otherFieldVisible && errorMsg}
            </Grid>
          )}

          <Grid
            item
            className={clsx({
              [classes.hidden]: !otherFieldVisible,
            })}
          >
            <Typography className={classes.text}>
              Explain your reason (up to 100 characters)
            </Typography>

            <Controller
              render={(props) => (
                <ThemedTextField
                  multiline
                  rows={3}
                  classes={{ input: classes.currentReason }}
                  variant="outlined"
                  color="primary"
                  placeholder="Type here"
                  error={Boolean(errors.notes)}
                  {...props}
                />
              )}
              control={control}
              name="notes"
              rules={{
                required: 'Please enter reason for visit',
                maxLength: {
                  value: 100,
                  message:
                    'Reason for visit field cannot contain more than 100 characters.',
                },
                validate: {
                  checkCharacters: (value: string) => {
                    return /[ЁА-яё]/.test(value)
                      ? 'Please enter valid value'
                      : true
                  },
                },
              }}
            />
            {errorMsg}
          </Grid>

          {isTelemedicineClinic && (
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              className={classes.switchContainer}
            >
              <IOSSwitch
                onChange={handleWithoutPhoneToggleChange}
                checked={withoutPhone}
              />

              <Typography className={classes.text}>
                I don&apos;t have a smartphone
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReasonsContainer
