import React from 'react'
import { Button, Grid, Theme, Typography } from '@mui/material'
import clsx from 'clsx'
import { truncate } from 'lodash'
import makeStyles from '@mui/styles/makeStyles'

import { getAbsoluteUrl } from '../../utils/general'
import ArrowIcon from '../../assets/icons/Arrow.svg'
import { Clinic } from '../../store/clinics/models'

const useStyles = makeStyles(
  (theme: Theme) => ({
    selectedClinicInfoButton: {
      display: 'block',
      backgroundColor: theme.palette.background.default,
      margin: theme.spacing(4, 0, 2, 0),
      padding: theme.spacing(3, 6.5, 3, 3),
      border: '2px solid rgba(25, 99, 198, 0.2)',
      borderRadius: 20,
      maxWidth: 335,
      width: '100%',
      maxHeight: 150,
      position: 'relative',
      cursor: 'pointer',
      overflow: 'auto',

      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2, 0),
        padding: theme.spacing(2, 3.5, 2, 2),
      },
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: 'rgba(25, 99, 198, 0.04)',
      },
    },
    arrowIcon: {
      position: 'absolute',
      top: '14px',
      right: '14px',
    },
    clinicInfoTitle: {
      letterSpacing: '-0.5px',
      fontWeight: 700,
    },
    clinicInfo: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '1.375rem',
      lineHeight: 1.3,
    },
    clinicInfoOverflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    text: {
      fontFamily: 'Arial',
      color: theme.palette.primary.dark,
      lineHeight: 1.4,
    },
  }),
  {
    name: 'SelectedClinicInfoButton',
  }
)

type SelectedClinicInfoButtonProps = {
  selectedClinicInfo: Clinic
  onClinicInfoClick(): void
}

const SelectedClinicInfoButton: React.FC<SelectedClinicInfoButtonProps> = ({
  selectedClinicInfo,
  onClinicInfoClick,
}) => {
  const classes = useStyles()

  return (
    <Button
      variant="outlined"
      color="primary"
      disabled={!selectedClinicInfo?.id}
      className={classes.selectedClinicInfoButton}
      onClick={onClinicInfoClick}
    >
      <img
        src={getAbsoluteUrl(ArrowIcon)}
        alt=""
        className={classes.arrowIcon}
      />
      <Grid container>
        <Typography
          align="left"
          className={clsx(
            classes.clinicInfoTitle,
            classes.clinicInfo,
            classes.text,
            {
              [classes.clinicInfoOverflow]: selectedClinicInfo?.name,
            }
          )}
        >
          {selectedClinicInfo?.name || "Choose clinic to see it's info"}
        </Typography>
        <Typography
          align="left"
          className={clsx(classes.clinicInfo, classes.text)}
        >
          {truncate(selectedClinicInfo?.address, { length: 42 }) || ''}
        </Typography>
      </Grid>
    </Button>
  )
}

export default SelectedClinicInfoButton
