import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { insuranceActions } from 'src/store/insurance/actions'
import { isLoading } from '../utils/types'
import { InsuranceCompany } from '../store/insurance/models'
import {
  getFetchingCompanies,
  getInsuranceCompanies,
} from '../store/insurance/selectors'

export const useInsuranceCompaniesList = (): {
  companies: InsuranceCompany[]
  isFetchingCompanies: boolean
  getCompaniesList(filterValue: string): void
} => {
  const dispatch = useDispatch()

  const fetchingCompanies = useSelector(getFetchingCompanies)
  const companies = useSelector(getInsuranceCompanies)

  const isFetchingCompanies = isLoading(fetchingCompanies.state)

  const getCompaniesList = useCallback(
    (filterValue: string) => {
      dispatch(insuranceActions.getCompanies.request({ query: filterValue }))
    },
    [dispatch]
  )

  return { companies, isFetchingCompanies, getCompaniesList }
}
