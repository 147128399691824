import { memo } from 'react'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { isEmpty } from 'lodash'

import {
  getAppointment,
  getIndicatedInsuranceStatus,
  getSelectedPaymentMethod,
} from '../store/appointment/selectors'
import {
  getUserAnalyticId,
  getUserInsuranceIsEligible,
  getUserProfile,
} from '../store/auth/selectors'
import gtag from '../utils/gtag'
import useLocationChange from '../hooks/useLocationChange'
import { getAnalyticsQueries } from '../store/analytics/selectors'

type NavigationAnalyticsProps = {
  widgetType: string
}

const formatPageName = (path: string) =>
  `Scheduler: ${path
    .replace('/', '')
    .split('-')
    .join(' ')}`

const NavigationAnalytics: React.FC<NavigationAnalyticsProps> = () => {
  const appointment = useSelector(getAppointment)
  const initialPaymentMethod = useSelector(getIndicatedInsuranceStatus)
  const paymentMethod = useSelector(getSelectedPaymentMethod)
  const insuranceStatus = useSelector(getUserInsuranceIsEligible)
  const profile = useSelector(getUserProfile)
  const analyticId = useSelector(getUserAnalyticId)
  const analyticsQueries = useSelector(getAnalyticsQueries)

  useLocationChange(({ location, prevLocation }) => {
    try {
      const title = formatPageName(location.pathname)
      const prevPageTitle = prevLocation
        ? formatPageName(prevLocation.pathname)
        : undefined

      const payload = {
        ...(!appointment?.isTelemedicineClinic
          ? {
              appointment_clinic: appointment?.clinic?.id,
            }
          : {
              appointment_has_smartphone: !appointment?.withoutMobileApp,
            }),

        ...(!isEmpty(profile)
          ? {
              user_id: analyticId,
              kyla_id: profile.id,
            }
          : {}),

        ...(isEmpty(analyticsQueries) ? {} : { ...analyticsQueries }),

        appointment_explanation: appointment.reason,
        fromSymptomsChecker: appointment.symptomsCheckerFlow !== undefined,
        insurance_coverage: insuranceStatus,
        appointment_telemedicine: appointment?.isTelemedicineClinic,
        desired_payment_method: initialPaymentMethod,
        actual_payment_method: paymentMethod,
        appointment_reason: appointment?.reason,
        appointment_date: appointment?.dateTime?.start,

        prev_page_title: prevPageTitle,
        current_page_location: location.pathname,
        prev_page_location: prevLocation?.pathname,
      }

      if (window.analytics) {
        window.analytics.page(location.pathname, title, payload)
      }

      gtag('event', 'page_view', {
        page_title: title,
        page_location: location.pathname,
        ...payload,
      })
    } catch (error) {
      Sentry.captureException(error)
      console.error(error)
    }
  })

  return null
}

export default memo(NavigationAnalytics)
