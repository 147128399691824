import { createAsyncAction } from 'typesafe-actions'

export const attachmentsActions = {
  getAttachment: createAsyncAction(
    'attachment/GET_ATTACHMENT',
    'attachment/GET_ATTACHMENT_SUCCESS',
    'attachment/GET_ATTACHMENT_FAILURE'
  )<
    {
      attachmentId: number
    },
    { attachmentId: number; attachment: File },
    { attachmentId: number; message: string }
  >(),
}
