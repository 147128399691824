import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'

import appointmentActions from 'src/store/appointment/actions'
import { ReasonForConsultation } from '../store/appointment/models'
import { LoadingContext } from '../utils/types'
import {
  getFetchingReasonsForConsultation,
  getReasonsForConsultation,
} from '../store/appointment/selectors'

type UseConsultationReasons = {
  reasonsForConsultation: ReasonForConsultation[]
  fetching: LoadingContext
  fetchReasons(consultationType: string): void
}

const useConsultationReasons = (): UseConsultationReasons => {
  const dispatch = useDispatch()

  const reasonsForConsultation = useSelector(getReasonsForConsultation)

  const fetching = useSelector(getFetchingReasonsForConsultation)

  const fetchReasons = useCallback((consultationType) => {
    dispatch(
      appointmentActions.getReasonsForConsultation.request({
        consultationType,
      })
    )
  }, [dispatch])

  return { reasonsForConsultation, fetchReasons, fetching }
}

export default useConsultationReasons
