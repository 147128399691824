import { ActionType, createReducer } from 'typesafe-actions'

import { analyticsActions } from '../actions'

interface State {
  analyticsQueries: Record<string, string>
}

const initialState = {
  analyticsQueries: {},
}

export default createReducer<State, ActionType<typeof analyticsActions>>(
  initialState
).handleAction(
  analyticsActions.storeAnalyticsQueries,
  (state, { payload: { queries } }): State => ({
    ...state,
    analyticsQueries: queries,
  })
)
