import React from 'react'
import clsx from 'clsx'
import { Button, Grid, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 180,
      margin: 'auto',
      width: '80%',
      background: theme.palette.background.default,
      borderRadius: 10,
      boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)',
      padding: theme.spacing(2),
      display: 'none',
      transition: 'opacity 0.15s linear',
    },
    showedRoot: {
      display: 'inherit',
    },
    disabled: {
      opacity: 0.7,
      pointerEvents: 'none',
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
      marginBottom: theme.spacing(2),
    },
    dialogTitle: {
      marginBottom: 0,
      fontWeight: 700,
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      lineHeight: 1.5,
    },
    button: {
      marginBottom: theme.spacing(1.5),
      borderRadius: '12px',
      height: 56,
      fontSize: '1rem',
      fontFamily: 'NeurialGrotesk',
      fontWeight: 700,
    },
    buttonsContainer: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'ChronoValidationAccount' }
)

interface ChronoValidationAccountProps {
  open: boolean
  email: string
  phone: string
  loadingInProgress: boolean

  onClose(): void
  validateByPhone(): void
  validateByEmail(): void
}

const ChronoValidationAccountDialog: React.FC<ChronoValidationAccountProps> = ({
  open,
  email,
  phone,
  loadingInProgress,
  onClose,
  validateByPhone,
  validateByEmail,
}) => {
  const classes = useStyles()

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={clsx(classes.root, {
        [classes.showedRoot]: open,
        [classes.disabled]: loadingInProgress,
      })}
    >
      <Grid>
        <Typography className={clsx(classes.text)}>
          Please choose how you would like to validate your account
        </Typography>
      </Grid>
      <Grid container>
        {phone && (
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={validateByPhone}
          >
            {`By Phone ${phone}`}
          </Button>
        )}

        {email && (
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={validateByEmail}
          >
            {`By Email ${email}`}
          </Button>
        )}

        <Button
          fullWidth
          color="primary"
          className={classes.button}
          onClick={onClose}
        >
          Back
        </Button>
      </Grid>
    </Grid>
  )
}

export default ChronoValidationAccountDialog
