import { createAsyncAction } from 'typesafe-actions'
import { AxiosError } from 'axios'

import { Clinic } from './models'
import { ServerErrorResponse } from '../../utils/api/types'

const actions = {
  getAll: createAsyncAction(
    'clinics/GET_ALL',
    'clinics/GET_ALL_SUCCESS',
    'clinics/GET_ALL_FAILURE'
  )<
    void,
    { clinics: Clinic[] },
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
  getClinicById: createAsyncAction(
    'clinics/GET_BY_ID',
    'clinics/GET_BY_ID_SUCCESS',
    'clinics/GET_BY_ID_FAILURE'
  )<
    { selectedClinicId: number; widgetType: string; specificClinic?: boolean },
    { clinicInfo: Clinic },
    { message: string; error: AxiosError<ServerErrorResponse> }
  >(),
}

export default actions
