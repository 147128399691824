import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Classes } from '@mui/styles/mergeClasses/mergeClasses'

const useStyles = makeStyles((theme) => ({
  root: {},
  top: {
    position: 'absolute',
    left: 0,
    color: theme.palette.primary.main,
  },
  staticCircle: {},
  progressCircle: {
    strokeLinecap: 'round',
  },
  crossLine: {
    height: 20,
    width: '99%',
    transform: 'rotate(45deg)',
  },
}))

interface ProgressCircleWithContentProps {
  content: any
  classes?: Classes
  withProgress?: boolean
  crossed?: boolean
  size?: number
}

const ProgressCircleWithContent: React.FC<ProgressCircleWithContentProps> = ({
  content,
  classes: classesProp = {},
  withProgress = false,
  crossed = false,
  size = 200,
}) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Box position="relative" display="flex" className={classes.root}>
      <CircularProgress
        variant="determinate"
        size={size}
        classes={{
          circle: classes.staticCircle,
        }}
        value={100}
      />
      {withProgress && (
        <CircularProgress
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.progressCircle,
          }}
          size={size}
        />
      )}
      {crossed && (
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box className={classes.crossLine} />
        </Box>
      )}
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {content}
      </Box>
    </Box>
  )
}

export default ProgressCircleWithContent
