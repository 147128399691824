import React from 'react'
import { Box, IconButton, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { noop } from 'lodash'
import { BasePickerProps } from '@mui/x-date-pickers/internals'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

import ThemedTextField from './ThemedTextField'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      borderRadius: '10px',
      border: '2px solid #BFD4F0',
      // padding: theme.spacing(1.25, 0, 1.25, 2),
      '&:hover': {
        borderColor: `${theme.palette.primary.dark} !important`,
      },
      backgroundColor: theme.palette.primary.light,
      '& input': {
        fontFamily: 'NeurialGrotesk',
        fontSize: '0.875rem',
        lineHeight: '1.3',
        color: theme.palette.primary.dark,
      },
      '& input:disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
      },
      '& label': {
        fontFamily: 'NeurialGrotesk',
        fontSize: '0.875rem',
        opacity: 0.5,
        color: theme.palette.primary.dark,
      },
    },
  }),
  { name: 'DatePicker' }
)

interface DatePickerProps
  extends Omit<BasePickerProps<any, any>, 'onChange' | 'value'> {
  error?: boolean
  onChange?(): void
  maxDate?: any
  value?: BasePickerProps<any, any>
}

const ThemedDatePicker = React.forwardRef<any, DatePickerProps>(
  (
    { error = false, value = '', onChange = noop, ...props },
    ref: React.Ref<any>
  ) => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const DEFAULT_CALENDAR_MONTH = new Date(1985, 1, 1)

    return (
      <DesktopDatePicker
        disableFuture
        className={classes.root}
        InputProps={{
          disableUnderline: true,
        }}
        open={open}
        onClose={() => setOpen(false)}
        inputFormat="MM/dd/yyyy"
        inputRef={ref}
        defaultCalendarMonth={DEFAULT_CALENDAR_MONTH}
        renderInput={(params: any) => (
          <ThemedTextField
            {...params}
            {...{ error }}
            margin="none"
            placeholder="Date of birth"
            endAdornment={
              <Box>
                <IconButton onClick={() => setOpen(true)}>
                  <CalendarTodayIcon />
                </IconButton>
              </Box>
            }
          />
        )}
        {...{ onChange, value }}
        {...props}
      />
    )
  }
)

export default ThemedDatePicker
