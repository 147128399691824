import { red } from '@mui/material/colors'
import { Theme } from '@mui/material'
import { createTheme } from '@mui/material/styles'

export default createTheme({
  palette: {
    success: {
      main: '#52d869',
    },
    primary: {
      main: '#1963C6',
      dark: '#1F3075',
      light: '#E9F0FA',
      contrastText: '#fff',
    },
    secondary: {
      main: '#80A9E0',
      contrastText: '#fff',
    },
    error: {
      light: '#e8453c',
      main: red.A400,
      dark: red.A700,
    },
    background: {
      default: '#fff',
    },
  },
  components: {
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          minHeight: '56px',
        },
        root: {
          textTransform: 'none',
        },
        outlined: {
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          color: '#1963C6 !important',
        },
      },
    },
  },
})

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
