import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { RoutePath } from '../../routes'
import { EmergencyLevels } from '../../utils/general'
import gtag from '../../utils/gtag'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      flex: 1,
      padding: theme.spacing(4),
    },
    title: {
      fontFamily: 'Inter',
      fontWeight: 600,
      fontSize: '2.25rem',
      lineHeight: '44px',
      color: theme.palette.primary.dark,
      marginBottom: theme.spacing(2),
    },
    explanation: {
      fontFamily: 'Inter',
      color: theme.palette.primary.dark,
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '24px',
      letterSpacing: '-0.5px',
      marginBottom: theme.spacing(5),
    },
    button: {
      borderRadius: '14px',
      height: '60px',
      marginBottom: theme.spacing(5),
      fontFamily: 'Inter',
      fontWeight: 700,
      fontSize: '1rem',
    },
    text: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: '1.25rem',
      color: theme.palette.primary.dark,
      marginBottom: theme.spacing(2),
    },
    seriousSymptomsWrapper: {
      flex: 1,
      overflow: 'auto',
      padding: theme.spacing(0.5),
    },
    list: {
      paddingLeft: theme.spacing(2),
    },
    listItem: {
      fontFamily: 'Inter',
      color: theme.palette.primary.dark,
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '24px',
      letterSpacing: '-0.5px',
      '&::marker': {
        color: 'rgba(235, 38, 78, 0.6)',
      },
    },
  }),
  { name: 'SymptomsCheckerResults' }
)

const Results_Page_Titles: Record<
  EmergencyLevels,
  { title: string; explanation: string }
> = {
  [EmergencyLevels.EmergencyAmbulance]: {
    title: 'An Emergency Ambulance is advised immediately!',
    explanation:
      'The reported symptoms are very serious and may require emergency assistance. Please call an ambulance immediately.',
  },
  [EmergencyLevels.Emergency]: {
    title: 'An Emergency Department visit is advised!',
    explanation:
      'The reported symptoms are serious and require immediate assistance. We advise you (the patient) should visit the nearest emergency room. If you are not able to get transportation, please call an ambulance.',
  },
  [EmergencyLevels.ConsultationWithinOneDay]: {
    title: 'A medical consultation is advised within the next 24 hours.',
    explanation:
      'The reported symptoms should be evaluated by the doctor within the next 24 hours. If symptoms worsen, please seek emergency assistance.',
  },
  [EmergencyLevels.Consultation]: {
    title: 'A medical consultation is advised.',
    explanation:
      'Due to the reported symptoms, a medical consultation with a doctor is advised. If symptoms worsen, please seek medical attention as soon as possible.',
  },
  [EmergencyLevels.SelfCare]: {
    title: 'A virtual consultation is advised, but not necessary.',
    explanation:
      'A virtual consultation is advised, but not medically necessary. Monitor symptoms at home and take precautions. If symptoms worsen or persist, please schedule a consultation with a doctor.',
  },
}

interface SymptomsCheckerResultsProps {
  symptomsCheckerParams: {
    seriousSymptoms: any[]
    emergencyLevel: EmergencyLevels
  }
}

const SymptomsCheckerResults: React.FC<SymptomsCheckerResultsProps> = ({
  symptomsCheckerParams,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const openVirtualVisit = useCallback(() => {
    history.push({
      pathname: RoutePath.virtualVisit,
      state: {
        symptomsCheckerFlow: true,
      },
    })

    gtag('event', 'sc_getTreatedVirtually')
  }, [history])

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <Typography className={classes.title}>
        {Results_Page_Titles[symptomsCheckerParams.emergencyLevel].title}
      </Typography>
      <Typography className={classes.explanation}>
        {Results_Page_Titles[symptomsCheckerParams.emergencyLevel].explanation}
      </Typography>
      <Button
        color="primary"
        className={classes.button}
        variant="contained"
        onClick={openVirtualVisit}
      >
        Get Treated Virtually
      </Button>
      {Boolean(symptomsCheckerParams?.seriousSymptoms?.length) && (
        <Typography className={classes.text}>
          Symptoms requiring further examination:
        </Typography>
      )}
      <div className={classes.seriousSymptomsWrapper}>
        <ul className={classes.list}>
          {(symptomsCheckerParams.seriousSymptoms || []).map((symptom) => (
            <li className={classes.listItem}>{symptom.common_name}</li>
          ))}
        </ul>
      </div>
    </Box>
  )
}

export default SymptomsCheckerResults
