/* eslint-disable import/no-cycle */
import { SagaIterator } from 'redux-saga'
import { all } from 'redux-saga/effects'

import { appointmentSagas } from './appointment/sagas'
import { clinicsSagas } from './clinics/sagas'
import { authSagas } from './auth/sagas'
import { insuranceSagas } from './insurance/sagas'
import { attachmentsSagas } from './attachments/sagas'

export function* rootSaga(): SagaIterator {
  yield all([
    ...clinicsSagas,
    ...appointmentSagas,
    ...authSagas,
    ...insuranceSagas,
    ...attachmentsSagas,
  ])
}
