import React, { useCallback } from 'react'
import { Theme, Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import ProgressCircleWithContent from '../../components/common/ProgressCircleWIthContent'
import CardIcon from '../../assets/icons/creditCardIcons/CardIconSuccess.svg'
import ThemedButton from '../../components/common/ThemedButton'
import { RoutePath } from '../../routes'
import { getAbsoluteUrl } from '../../utils/general'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(15, 3.5, 6),
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
      fontWeight: 500,
    },
    title: {
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '35px',
      textAlign: 'center',
    },
    staticCircle: {
      color: '#06BA63',
    },
    progressCircle: {
      color: '#6C9BDA',
    },
    circleWithIcon: {
      margin: theme.spacing(5, 0, 8),
    },
  }),
  { name: 'PaymentSuccessful' }
)

const PaymentSuccessful: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  const handleProceedClick = useCallback(() => {
    history.push(RoutePath.appointmentConfirmed)
  }, [history])

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <Box flex={1}>
        <Typography className={clsx(classes.text, classes.title)}>
          Appointment is scheduled
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center">
          <ProgressCircleWithContent
            withProgress={false}
            classes={{
              root: classes.circleWithIcon,
              staticCircle: classes.staticCircle,
              progressCircle: classes.progressCircle,
            }}
            content={<img src={getAbsoluteUrl(CardIcon)} alt="card" />}
          />
        </Box>

        <Typography className={classes.text}>
          Thank you for your payment. Your appointment is scheduled, click{' '}
          <b>Proceed</b> to finish the process.
        </Typography>
      </Box>
      <Box>
        <ThemedButton onClick={handleProceedClick}>Proceed</ThemedButton>
      </Box>
    </Box>
  )
}

export default PaymentSuccessful
