import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  MouseEventHandler,
} from 'react'
import {
  Button,
  Menu,
  MenuItem,
  Typography,
  Box,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import { Classes } from '@mui/styles/mergeClasses/mergeClasses'

import clsx from 'clsx'
import theme from '../../utils/theme'
import WebcamDialog from '../dialogs/WebcamDialog'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(1),
      width: '100%',
      height: 190,
      backgroundColor: theme.palette.primary.light,
      borderRadius: '10px',
      padding: 0,
      border: '2px solid #BFD4F0',
      '&:hover': {
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.light,
      },
    },
    rootInvalid: {
      borderColor: theme.palette.error.main,
    },
    imageLabel: {
      fontFamily: 'NeurialGrotesk',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: '20px',
      textAlign: 'center',
      opacity: 0.6,
      color: theme.palette.primary.dark,
    },
    icon: {
      margin: '0 auto',
    },
  }),
  { name: 'ThemedImagePicker' }
)

interface ThemedImagePickerProps {
  disabled?: boolean
  onImageAdd(event: File): void
  image: File | null
  classes?: Classes
  label?: string
  error?: boolean
}

const getImageSrc = (image: File | null) => {
  if (!image) return ''

  const blob = new Blob([image], { type: 'image' })
  return window.URL.createObjectURL(blob)
}

const ThemedImagePicker: React.FC<ThemedImagePickerProps> = ({
  disabled,
  onImageAdd,
  image,
  label,
  classes: classesProp = {},
  error,
}) => {
  const [showWebcamDialog, setShowWebcamDialog] = useState(false)
  const [hasWebcam, setHasWebcam] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const inputFileRef = useRef<HTMLInputElement>(null)

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const classes = useStyles({ classes: classesProp })

  const imageSrc = getImageSrc(image)
  const imageCoverStyles = {
    backgroundImage: `url(${imageSrc})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  }

  const handleFileUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event?.target?.files?.[0]
      if (!file) return

      const fileSizeInMb = file.size / 1000000
      if (fileSizeInMb > 10) {
        alert(
          'Maximum file size exceeded. Please upload files up to 10 megabytes.'
        )

        // eslint-disable-next-line no-param-reassign
        event.target.value = ''
        return
      }

      onImageAdd(file)
    },
    [onImageAdd]
  )

  const handleOpenDropdown = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget as HTMLElement)
  }

  const handleCloseDropdown = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices?.().then((devices) => {
      const hasWebcam = devices.some((device) => device.kind === 'videoinput')
      setHasWebcam(hasWebcam)
    })
  }, [])

  return (
    <>
      <Button
        disabled={disabled}
        component="label"
        className={clsx(classes.root, { [classes.rootInvalid]: error })}
        {...(imageSrc ? { style: imageCoverStyles } : {})}
        onClick={
          isDesktop
            ? (handleOpenDropdown as MouseEventHandler)
            : inputFileRef.current?.click
        }
      >
        {!imageSrc && (
          <Box display="flex" flexDirection="column">
            <PhotoCameraIcon
              className={classes.icon}
              htmlColor={theme.palette.primary.dark}
              opacity="0.6"
            />
            <Typography className={classes.imageLabel}>
              {label || 'Please click or press to add image'}
            </Typography>
          </Box>
        )}
        <input
          ref={inputFileRef}
          type="file"
          style={{ display: 'none' }}
          accept="image/*"
          onClick={(event) => event.stopPropagation()}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleFileUpload(event)
          }
        />
      </Button>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'center', horizontal: 'center' }}
        onClose={handleCloseDropdown}
      >
        {hasWebcam && (
          <MenuItem
            onClick={() => {
              handleCloseDropdown()
              setShowWebcamDialog(true)
            }}
          >
            Use webcam
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleCloseDropdown()
            inputFileRef.current?.click()
          }}
        >
          Upload from device
        </MenuItem>
      </Menu>
      <WebcamDialog
        open={showWebcamDialog}
        onClose={() => setShowWebcamDialog(false)}
        onImageAdd={(file) => {
          onImageAdd(file)
          setShowWebcamDialog(false)
        }}
      />
    </>
  )
}

export default ThemedImagePicker
