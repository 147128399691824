import React, { useCallback, useEffect, useState } from 'react'
import { Box, Theme, Typography } from '@mui/material'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'

import { useIsLabOrderingFlow } from 'src/hooks/labOrdering/useIsLabOrderingFlow'
import ProgressCircleWithContent from '../../components/common/ProgressCircleWIthContent'
import { RoutePath } from '../../routes'
import authActions from '../../store/auth/actions'
import ClipboardList from '../../assets/icons/ClipboardList.svg'
import {
  getCreateChronoAccountLoading,
  getDuplicationInfo,
  getGettingVerificationLoading,
  getSkippingChronoDuplicate,
} from '../../store/auth/selectors'
import useLoadingSuccess from '../../hooks/useLoadingSuccess'
import useLoadingFailure from '../../hooks/useLoadingFailure'
import { getAbsoluteUrl } from '../../utils/general'
import { extractErrorMessage } from '../../utils/errors'
import {
  isFailure,
  isLoading,
  isSuccess,
  LoadingContext,
} from '../../utils/types'
import { TypeOfSkip, VerificationOptionsTypes } from '../../store/auth/models'
import { useLoadingChange } from '../../hooks/useLoadingChange'
import ChronoDuplicationDialog from '../../components/dialogs/ChronoDuplicationDialog'
import ChronoValidationAccountDialog from '../../components/dialogs/ChronoValidationAccountDialog'
import gtag from '../../utils/gtag'
import { useLabOrderDraftCreate } from '../../hooks/labOrdering/useLabOrderDraftCreate'
import useLoadingFailureAlert from '../../hooks/useLoadingFailureAlert'
import { setConsultationExistence } from '../../store/testOrdering'
import { IndicatedPaymentMethod } from '../../store/appointment/types'
import appointmentActions from '../../store/appointment/actions'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(15, 3.5, 6),
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
      fontWeight: 500,
    },
    title: {
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '35px',
      textAlign: 'center',
    },
    staticCircle: {
      color: '#B7CEEF',
    },
    progressCircle: {
      color: '#6C9BDA',
    },
    circleWithIcon: {
      margin: theme.spacing(5, 0, 8),
    },
  }),
  { name: 'CreateMedicalAccount' }
)

const CreateMedicalAccount: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const {
    creating: creatingLabOrderDraft,
    createLabOrderDraft,
  } = useLabOrderDraftCreate()

  const creatingChronoAccount = useSelector(getCreateChronoAccountLoading)

  const [openDuplicationDialog, setOpenDuplicationDialog] = useState(false)
  const [openValidationDialog, setOpenValidationDialog] = useState(false)

  const duplicationInfo = useSelector(getDuplicationInfo)

  const gettingVerificationCode = useSelector(getGettingVerificationLoading)
  const isGettingVerificationCode = isLoading(gettingVerificationCode.state)

  const skippingChronoDuplicate = useSelector(getSkippingChronoDuplicate)

  useEffect(() => {
    dispatch(authActions.clearDuplicationInfo())
    dispatch(authActions.createChronoAccount.request())
  }, [])

  const handleGoNext = useCallback(() => {
    if (isLabOrderingFlow) {
      createLabOrderDraft()
    } else {
      history.replace(RoutePath.checkPendingAppt)
    }
  }, [history, isLabOrderingFlow, createLabOrderDraft])

  useLoadingSuccess(creatingLabOrderDraft, () => {
    dispatch(
      appointmentActions.setSelectedPaymentMethod(IndicatedPaymentMethod.cash)
    )
    dispatch(setConsultationExistence(false))

    history.push(RoutePath.addressInfo)

    // history.replace(RoutePath.chooseLabOrderPaymentMethod)
  })

  useLoadingFailureAlert(creatingLabOrderDraft)

  useLoadingSuccess(skippingChronoDuplicate, handleGoNext)

  useLoadingFailure(skippingChronoDuplicate, ({ error }) => {
    alert(extractErrorMessage(error))
  })

  useLoadingSuccess(creatingChronoAccount, handleGoNext)

  useLoadingFailure(creatingChronoAccount, ({ error }) => {
    if (duplicationInfo?.verificationInfo?.id && !duplicationInfo?.kylaId) {
      setOpenDuplicationDialog(true)
      return
    }

    if (duplicationInfo?.kylaId) {
      history.push(RoutePath.checkCronoAccountFailure)
      gtag('event', 'med_account_creation_failure')

      return
    }

    alert(extractErrorMessage(error))
  })

  const handleCloseDuplicationDialog = useCallback(() => {
    setOpenDuplicationDialog(false)
    dispatch(authActions.clearDuplicationInfo())
  }, [dispatch, setOpenDuplicationDialog])

  const handleSkipDuplicationSafeguards = useCallback(() => {
    setOpenDuplicationDialog(false)

    dispatch(
      authActions.skipChronoDuplicate.request({
        duplicationLogId: duplicationInfo.duplicationResultId,
        typeOfSkip: TypeOfSkip.callSupport,
      })
    )
  }, [dispatch, duplicationInfo])

  const handleOpenValidationDialog = useCallback(() => {
    setOpenDuplicationDialog(false)
    setOpenValidationDialog(true)
  }, [])

  const handleCloseValidationDialog = useCallback(() => {
    setOpenValidationDialog(false)
    setOpenDuplicationDialog(true)
  }, [])

  const handleAccountValidate = useCallback(
    (verificationType: VerificationOptionsTypes) => {
      dispatch(
        authActions.getVerificationCode.request({
          verificationType: verificationType as VerificationOptionsTypes,
          id: duplicationInfo.verificationInfo.id,
        })
      )
    },
    [dispatch, duplicationInfo]
  )

  const handleLoadingChange = useCallback(
    (newLoading: LoadingContext) => {
      if (isSuccess(newLoading.state)) {
        history.push(RoutePath.verificationPage)
      }
      if (isFailure(newLoading.state)) {
        alert(newLoading.message || '')
      }
    },
    [history]
  )

  useLoadingChange(handleLoadingChange, gettingVerificationCode)

  return (
    <>
      <Box display="flex" flexDirection="column" className={classes.root}>
        <Typography className={clsx(classes.text, classes.title)}>
          Creating Your <br /> Medical Account
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center">
          <ProgressCircleWithContent
            withProgress
            classes={{
              root: classes.circleWithIcon,
              staticCircle: classes.staticCircle,
              progressCircle: classes.progressCircle,
            }}
            content={<img src={getAbsoluteUrl(ClipboardList)} alt="" />}
          />
        </Box>

        <Typography className={clsx(classes.text)}>
          We are partnered with Action Urgent Care clinics. To provide you
          quality service, we need to create a medical account for you.
        </Typography>
        <br />
        <Typography className={clsx(classes.text)}>
          We will check if you already have an account with our clinics, please
          wait up to 2 minutes.
        </Typography>
      </Box>
      <ChronoDuplicationDialog
        open={openDuplicationDialog}
        email={duplicationInfo?.verificationInfo?.email as string}
        phone={duplicationInfo?.verificationInfo?.phone as string}
        validateAccount={handleOpenValidationDialog}
        skipDuplicationSafeguards={handleSkipDuplicationSafeguards}
        onClose={handleCloseDuplicationDialog}
      />
      <ChronoValidationAccountDialog
        loadingInProgress={isGettingVerificationCode}
        open={openValidationDialog}
        email={duplicationInfo?.verificationInfo?.email as string}
        phone={duplicationInfo?.verificationInfo?.phone as string}
        validateByPhone={() =>
          handleAccountValidate(VerificationOptionsTypes.phone)
        }
        validateByEmail={() =>
          handleAccountValidate(VerificationOptionsTypes.email)
        }
        onClose={handleCloseValidationDialog}
      />
    </>
  )
}

export default CreateMedicalAccount
