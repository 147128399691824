import { SagaIterator } from 'redux-saga'
import { select, put, takeLatest, call } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'
import moment from 'moment'
import pick from 'lodash/pick'
import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'

import authActions from 'src/store/auth/actions'
import {
  extractErrorMessage,
  isVerificationRequired,
  isAccountReactivationRequired,
  isBlacklistedAccount,
} from '../../utils/errors'
import {
  getDuplicationInfo,
  getGeneralInfo,
  getTemporaryUserId,
  getUserProfile,
} from './selectors'
// eslint-disable-next-line import/no-cycle
import { serverAxios } from '../../utils/api/axios'
import { config } from '../../utils/config'
import { clearLabPatientInfoStore } from '../../utils/labPatientInfoStore'

function* checkEmail({
  payload: { email },
}: ActionType<typeof authActions.checkEmail.request>): SagaIterator {
  try {
    const response = yield call(
      serverAxios.get,
      '/mobile/api/v4/auth/signup/check',
      {
        params: {
          email,
        },
      }
    )

    if (response.exist && !response.deleted) {
      throw new Error(
        'Selected email is already taken, please use Sign In flow.'
      )
    }

    yield put(
      authActions.storeAccountDeletedState({
        accountDeleted: response.deleted && !response.blacklisted,
        analyticId: response.analyticId,
        accountBlacklisted: response.blacklisted,
      })
    )
    yield put(
      authActions.checkEmail.success({ analyticId: response.analyticId })
    )
  } catch (error) {
    const axiosError = error as AxiosError
    const message = extractErrorMessage(axiosError)
    yield put(
      authActions.checkEmail.failure({
        message,
        error: axiosError,
      })
    )
  }
}

function* checkKylaDuplicate({
  payload: { typeOfSkip },
}: ActionType<typeof authActions.checkKylaDuplicate.request>): SagaIterator {
  const info = yield select(getGeneralInfo)
  const duplicationInfo = yield select(getDuplicationInfo)

  try {
    const response = yield call(
      serverAxios.post,
      '/mobile/api/v4/auth/signup/check',
      {
        firstName: info.firstName,
        lastName: info.lastName,
        email: info.email,
        sex: info.gender,
        dateOfBirth: moment(info.dob).format('YYYY-MM-DD'),
        phoneNumber: info.phoneNumber,
        ...(duplicationInfo.duplicationResultId
          ? {
              duplicationResultId: duplicationInfo.duplicationResultId,
              typeOfSkip,
            }
          : {}),
      }
    )

    if (duplicationInfo.duplicationResultId) {
      yield put(authActions.clearDuplicationInfo())
    }

    yield put(
      authActions.checkKylaDuplicate.success({
        temporaryUserId: response.id,
      })
    )
  } catch (error) {
    const axiosError = error as AxiosError

    if (isVerificationRequired(axiosError)) {
      yield put(
        authActions.storeDuplicationInfo({
          duplicationInfo: axiosError.response?.data.data,
        })
      )
    }

    const message = extractErrorMessage(axiosError)

    yield put(
      authActions.checkKylaDuplicate.failure({
        message,
        error: axiosError,
      })
    )
  }
}

function* createAccount({
  payload: { password, patientInfo },
}: ActionType<typeof authActions.createAccount.request>): SagaIterator {
  const generalInfo = yield select(getGeneralInfo)
  const tempUserId = yield select(getTemporaryUserId)

  try {
    const response = yield call(serverAxios.post, 'mobile/api/v4/auth/signup', {
      tempUserId,
      email: generalInfo.email,
      password,
    })

    Sentry.setUser({
      id: response.patient.id,
    })

    yield put(
      authActions.createAccount.success({
        id: response.patient.id,
        token: response.auth.accessToken,
        profile: response.patient,
      })
    )

    if (patientInfo) {
      try {
        yield call(serverAxios.get, 'mobile/api/v2/milestone')

        yield call(serverAxios.post, 'mobile/api/v1/milestone/1/answer', {
          answers: patientInfo.nodeAnswers,
          multipleNodeAnswers: patientInfo.multipleNodeAnswers,
        })
      } catch (error) {
        console.error(error)
      } finally {
        clearLabPatientInfoStore()
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError
    const message = extractErrorMessage(axiosError)

    yield put(
      authActions.createAccount.failure({
        message,
        error: axiosError,
      })
    )
  }
}

function* signIn({
  payload: { password, email },
}: ActionType<typeof authActions.signIn.request>): SagaIterator {
  try {
    const response = yield call(serverAxios.post, '/mobile/api/v1/auth/login', {
      email,
      password,
      appVersion: 'webView',
    })

    Sentry.setUser({
      id: response.profile.id,
    })

    if (response.outdatedTos?.length) {
      yield put(
        authActions.storeOutdatedTos({
          outdatedTosList: response.outdatedTos,
        })
      )
    }

    yield put(
      authActions.signIn.success({
        id: response.profile.id,
        token: response.auth.accessToken,
        profile: response.profile,
        analyticId: response.profile.analyticId,
      })
    )
  } catch (error) {
    const axiosError = error as AxiosError
    const message = extractErrorMessage(axiosError)

    yield put(
      authActions.storeAccountDeletedState({
        accountDeleted: isAccountReactivationRequired(axiosError),
        accountBlacklisted: isBlacklistedAccount(axiosError),
        analyticId: '',
      })
    )

    yield put(
      authActions.signIn.failure({
        message,
        error: axiosError,
      })
    )
  }
}

function* forgotPassword({
  payload: { email },
}: ActionType<typeof authActions.forgotPassword.request>): SagaIterator {
  try {
    yield call(serverAxios.post, '/mobile/api/v1/auth/forgot-password', {
      email,
      redirectUrl: config.host,
    })

    yield put(authActions.forgotPassword.success())
  } catch (error) {
    const axiosError = error as AxiosError
    const message = extractErrorMessage(axiosError)
    yield put(
      authActions.forgotPassword.failure({
        message,
      })
    )
  }
}

function* resetPassword({
  payload: { password, key },
}: ActionType<typeof authActions.resetPassword.request>): SagaIterator {
  try {
    yield call(serverAxios.post, '/mobile/api/v1/auth/reset-password', {
      password,
      key,
    })

    yield put(authActions.resetPassword.success())
  } catch (error) {
    const axiosError = error as AxiosError
    const message = extractErrorMessage(axiosError)
    yield put(
      authActions.resetPassword.failure({
        message,
      })
    )
  }
}

function* getVerificationCode({
  payload: { verificationType, id },
}: ActionType<typeof authActions.getVerificationCode.request>): SagaIterator {
  try {
    yield call(serverAxios.put, 'mobile/api/v1/auth/verify-code', {
      type: verificationType,
      id,
    })

    yield put(authActions.getVerificationCode.success({ verificationType }))
  } catch (error) {
    const axiosError = error as AxiosError
    const message = extractErrorMessage(axiosError)
    yield put(
      authActions.getVerificationCode.failure({
        message,
        error: axiosError,
      })
    )
  }
}

function* linkWithChronoAccount({
  payload: { code, id, patientId },
}: ActionType<typeof authActions.linkWithChronoAccount.request>): SagaIterator {
  try {
    yield call(serverAxios.put, 'mobile/api/v1/verification', {
      patientId,
      code,
      id,
    })

    yield put(authActions.linkWithChronoAccount.success())
  } catch (error) {
    const axiosError = error as AxiosError
    const message = extractErrorMessage(axiosError)
    yield put(
      authActions.linkWithChronoAccount.failure({
        message,
        error: axiosError,
      })
    )
  }
}

function* sendVerifyEmailCode(): SagaIterator {
  const generalInfo = yield select(getGeneralInfo)
  const tempUserId = yield select(getTemporaryUserId)

  try {
    yield call(serverAxios.post, 'mobile/api/v4/auth/signup/activation/send ', {
      email: generalInfo.email,
      tempUserId,
      verificationType: 'EMAIL',
    })

    yield put(authActions.sendVerifyEmailCode.success())
  } catch (error) {
    const axiosError = error as AxiosError
    const message = extractErrorMessage(axiosError)
    yield put(
      authActions.sendVerifyEmailCode.failure({
        message,
        error: axiosError,
      })
    )
  }
}

function* verifyEmail({
  payload: { verificationCode },
}: ActionType<typeof authActions.verifyEmail.request>): SagaIterator {
  const tempUserId = yield select(getTemporaryUserId)

  try {
    yield call(
      serverAxios.post,
      'mobile/api/v4/auth/signup/activation/verify',
      {
        tempUserId,
        code: verificationCode,
      }
    )

    yield put(authActions.verifyEmail.success())
  } catch (error) {
    const axiosError = error as AxiosError
    const message = extractErrorMessage(axiosError)
    yield put(
      authActions.verifyEmail.failure({
        message,
        error: axiosError,
      })
    )
  }
}

function* sendReactivationCode(): SagaIterator {
  const generalInfo = yield select(getGeneralInfo)

  try {
    yield call(serverAxios.post, 'mobile/api/v1/users/reactivation/send', {
      email: generalInfo.email,
      verificationType: 'EMAIL',
    })

    yield put(authActions.sendReactivationCode.success())
  } catch (error) {
    const axiosError = error as AxiosError
    const message = extractErrorMessage(axiosError)
    yield put(
      authActions.sendReactivationCode.failure({
        message,
        error: axiosError,
      })
    )
  }
}

function* reactivateAccount({
  payload: { verificationCode },
}: ActionType<typeof authActions.reactivateAccount.request>): SagaIterator {
  const generalInfo = yield select(getGeneralInfo)

  try {
    yield call(serverAxios.post, 'mobile/api/v1/users/reactivation/verify', {
      email: generalInfo.email,
      code: verificationCode,
    })

    yield put(authActions.reactivateAccount.success({ verificationCode }))
  } catch (error) {
    const axiosError = error as AxiosError
    const message = extractErrorMessage(axiosError)
    yield put(
      authActions.reactivateAccount.failure({
        message,
        error: axiosError,
      })
    )
  }
}

function* updatePassword({
  payload: { password, email, reactivationCode },
}: ActionType<typeof authActions.updatePassword.request>): SagaIterator {
  try {
    yield call(serverAxios.post, '/mobile/api/v1/users/reactivation', {
      newPassword: password,
      email,
      code: reactivationCode,
    })

    yield put(authActions.updatePassword.success())
  } catch (error) {
    const axiosError = error as AxiosError
    const message = extractErrorMessage(axiosError)
    yield put(
      authActions.updatePassword.failure({
        message,
      })
    )
  }
}

function* checkESignatureStatus(): SagaIterator {
  try {
    const response = yield call(
      serverAxios.get,
      '/mobile/api/v2/esignature/check',
      {
        params: {
          tosTypes: ['TOS_PRIVACY_PRACTICE', 'TOS_AUC_CONSENT'].join(','),
        },
      }
    )

    yield put(authActions.checkESignatureStatus.success({ data: response }))
  } catch (error) {
    const axiosError = error as AxiosError
    yield put(
      authActions.checkESignatureStatus.failure({ error: axiosError as Error })
    )
  }
}

function* uploadESignature({
  payload: { signature, tosTypes },
}: ActionType<typeof authActions.uploadESignature.request>): SagaIterator {
  try {
    const data = new FormData()

    data.append('esignature', signature)

    yield call(serverAxios.post, 'mobile/api/v2/esignature/upload', data, {
      params: {
        tosTypes: tosTypes.join(','),
      },
    })

    yield put(authActions.uploadESignature.success())
  } catch (error) {
    const axiosError = error as AxiosError
    yield put(
      authActions.uploadESignature.failure({ error: axiosError as Error })
    )
  }
}

function* createChronoAccount(): SagaIterator {
  try {
    yield call(serverAxios.post, 'mobile/api/v1/patient/drchrono/check')

    yield put(authActions.createChronoAccount.success())
  } catch (error) {
    const axiosError = error as AxiosError
    if (isVerificationRequired(axiosError)) {
      yield put(
        authActions.storeDuplicationInfo({
          duplicationInfo: axiosError.response?.data.data,
        })
      )
    }

    yield put(
      authActions.createChronoAccount.failure({ error: error as Error })
    )
  }
}

function* skipChronoDuplicate({
  payload: { duplicationLogId, typeOfSkip },
}: ActionType<typeof authActions.skipChronoDuplicate.request>): SagaIterator {
  try {
    yield call(serverAxios.post, 'mobile/api/v1/patient/duplicates/skipped', {
      duplicationLogId,
      typeOfSkip,
      webView: true,
    })

    yield put(authActions.skipChronoDuplicate.success())
  } catch (error) {
    const axiosError = error as AxiosError

    yield put(
      authActions.skipChronoDuplicate.failure({
        error: axiosError,
      })
    )
  }
}

function* getProfile(): SagaIterator {
  try {
    const response = yield call(
      serverAxios.get,
      'mobile/api/v1/patient/profile'
    )

    yield put(authActions.getProfile.success({ profile: response.profile }))
  } catch (error) {
    const axiosError = error as AxiosError
    yield put(authActions.getProfile.failure({ error: axiosError as Error }))
  }
}

function* updateProfile({
  payload: { address, zipCode, state, city },
}: ActionType<typeof authActions.updateProfile.request>): SagaIterator {
  try {
    const profile = yield select(getUserProfile)

    const response = yield call(
      serverAxios.put,
      'mobile/api/v2/patient/profile',
      {
        ...pick(profile, ['firstName', 'lastName', 'phoneNumber', 'gender']),
        street: address,
        city,
        zipCode,
        state,
        dateOfBirth: profile.dob || profile.dateOfBirth,
      }
    )

    yield put(authActions.updateProfile.success({ profile: response.profile }))
  } catch (error) {
    const axiosError = error as AxiosError
    if (isVerificationRequired(axiosError)) {
      yield put(
        authActions.storeDuplicationInfo({
          duplicationInfo: axiosError.response?.data.data,
        })
      )
    }

    yield put(authActions.updateProfile.failure({ error: error as Error }))
  }
}

function* uploadIdCard({
  payload: { idCard, patientId },
}: ActionType<typeof authActions.uploadIdCard.request>): SagaIterator {
  try {
    const formData = new FormData()
    formData.append('uploadFile', idCard, idCard.name || 'ID')

    yield call(serverAxios.post, '/api/v1/patient/attachment', formData, {
      params: { attachmentType: 'ID_CARD', secure: true, patientId },
    })

    yield put(authActions.uploadIdCard.success())
  } catch (error) {
    const axiosError = error as AxiosError
    yield put(authActions.uploadIdCard.failure({ error: axiosError as Error }))
  }
}

function* updateTos({
  payload: { guardianInfo },
}: ActionType<typeof authActions.updateTos.request>): SagaIterator {
  try {
    const response = yield call(
      serverAxios.put,
      '/mobile/api/v1/auth/confirm-tos',
      {
        ...(guardianInfo ? { mobileTosConfirmationTO: { guardianInfo } } : {}),
      }
    )

    Sentry.setUser({
      id: response.profile.id,
    })

    yield put(
      authActions.updateTos.success({
        id: response.profile.id,
        token: response.auth.accessToken,
        profile: response.profile,
      })
    )
  } catch (error) {
    const axiosError = error as AxiosError
    const message = extractErrorMessage(axiosError)
    yield put(
      authActions.updateTos.failure({
        message,
        error: axiosError,
      })
    )
  }
}

export const authSagas = [
  takeLatest(getType(authActions.checkEmail.request), checkEmail),

  takeLatest(
    getType(authActions.checkKylaDuplicate.request),
    checkKylaDuplicate
  ),
  takeLatest(getType(authActions.createAccount.request), createAccount),
  takeLatest(getType(authActions.signIn.request), signIn),
  takeLatest(getType(authActions.forgotPassword.request), forgotPassword),
  takeLatest(getType(authActions.resetPassword.request), resetPassword),
  takeLatest(
    getType(authActions.getVerificationCode.request),
    getVerificationCode
  ),
  takeLatest(
    getType(authActions.sendVerifyEmailCode.request),
    sendVerifyEmailCode
  ),
  takeLatest(
    getType(authActions.sendReactivationCode.request),
    sendReactivationCode
  ),
  takeLatest(getType(authActions.reactivateAccount.request), reactivateAccount),
  takeLatest(getType(authActions.updatePassword.request), updatePassword),
  takeLatest(getType(authActions.verifyEmail.request), verifyEmail),
  takeLatest(authActions.checkESignatureStatus.request, checkESignatureStatus),
  takeLatest(authActions.uploadESignature.request, uploadESignature),
  takeLatest(authActions.createChronoAccount.request, createChronoAccount),
  takeLatest(authActions.skipChronoDuplicate.request, skipChronoDuplicate),
  takeLatest(authActions.linkWithChronoAccount.request, linkWithChronoAccount),
  takeLatest(authActions.getProfile.request, getProfile),
  takeLatest(authActions.updateProfile.request, updateProfile),
  takeLatest(authActions.uploadIdCard.request, uploadIdCard),
  takeLatest(authActions.updateTos.request, updateTos),
]
