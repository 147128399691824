import React from 'react'
import { Button, Grid, Theme } from '@mui/material'
import clsx from 'clsx'
import { head, last } from 'lodash'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(
  (theme: Theme) => ({
    tabButton: {
      fontSize: '1rem',
      fontWeight: 'bold',
      height: 53,
      borderRadius: 0,
      border: '1px solid transparent',
      '&:hover': {
        border: '1px solid transparent',
      },
    },
    activeTabButton: {
      backgroundColor: theme.palette.primary.light,
      borderTop: `1px solid ${theme.palette.primary.main}`,
      '&:hover': {
        borderTop: `1px solid ${theme.palette.primary.main}`,
      },
    },
    inactiveTabButton: {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      '&:hover': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
    },
    firstTabButton: {
      borderTopRightRadius: '10px',
      '&:hover': {
        borderTopRightRadius: '10px',
      },
    },
    lastTabButton: {
      borderTopLeftRadius: '10px',
      '&:hover': {
        borderTopLeftRadius: '10px',
      },
    },
    activeTabFirstButton: {
      borderRight: `1px solid ${theme.palette.primary.main}`,
      '&:hover': {
        borderRight: `1px solid ${theme.palette.primary.main}`,
      },
    },
    activeTabLastButton: {
      borderLeft: `1px solid ${theme.palette.primary.main}`,
      '&:hover': {
        borderLeft: `1px solid ${theme.palette.primary.main}`,
      },
    },
  }),
  { name: 'HomePageTabs' }
)

export enum PageViews {
  mapView = 'mapView',
  search = 'searchClinicsView',
}

const TabsButtons: Array<{ label: string; key: string }> = [
  { label: 'Clinic Map', key: PageViews.mapView },
  { label: 'Clinic List', key: PageViews.search },
]

type HomePageTabsProps = {
  pageView: string
  updatePageView(view: string): void
}

const HomePageTabs: React.FC<HomePageTabsProps> = ({
  pageView,
  updatePageView,
}) => {
  const classes = useStyles()

  return (
    <>
      {TabsButtons.map((button) => {
        const isActive = button.key === pageView
        return (
          <Grid item xs={6} key={button.key}>
            <Button
              fullWidth
              classes={{
                root: clsx(classes.tabButton, {
                  [classes.activeTabButton]: isActive,
                  [classes.inactiveTabButton]: !isActive,
                  [classes.firstTabButton]:
                    button.key === head(TabsButtons)?.key,
                  [classes.lastTabButton]:
                    button.key === last(TabsButtons)?.key,
                  [classes.activeTabFirstButton]:
                    isActive && button.key === head(TabsButtons)?.key,
                  [classes.activeTabLastButton]:
                    isActive && button.key === last(TabsButtons)?.key,
                }),
              }}
              size="large"
              color="primary"
              variant="outlined"
              onClick={() => updatePageView(button.key)}
            >
              {button.label}
            </Button>
          </Grid>
        )
      })}
    </>
  )
}

export default HomePageTabs
