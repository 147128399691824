import React from 'react'
import { Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import clsx from 'clsx'
import { InClinicSlot } from '../../store/appointment/models'

const useStyles = makeStyles(
  (theme: Theme) => ({
    timeBlock: {
      background: theme.palette.background.default,
      border: `2px solid ${theme.palette.primary.light}`,
      borderRadius: '20px',
      height: 50,
      maxWidth: 160,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(1.5),
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        border: `2px solid ${theme.palette.primary.main}`,
        cursor: 'pointer',
      },
    },
    selected: {
      backgroundColor: theme.palette.primary.light,
      border: `2px solid ${theme.palette.primary.main}`,
    },
    timeText: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '0.875rem',
      lineHeight: 1.3,
      textAlign: 'center',
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
  }),
  { name: 'AppointmentTimeSelection' }
)

const formatTime = (appointmentTime: string) => {
  return moment(appointmentTime).format('hh:mma')
}

interface AppointmentTimeSlotProps {
  selectedTime: InClinicSlot
  slot: InClinicSlot
  onSelect: (slot: InClinicSlot) => void
}

const AppointmentTimeSlot: React.FC<AppointmentTimeSlotProps> = ({
  selectedTime,
  onSelect,
  slot,
}) => {
  const classes = useStyles()

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={clsx(classes.timeBlock, {
        [classes.selected]: selectedTime
          ? moment(selectedTime.start).isSame(slot.start)
          : false,
      })}
      onClick={() => onSelect(slot)}
    >
      <Typography className={classes.timeText}>
        {formatTime(slot.start)}
      </Typography>
    </Grid>
  )
}

export default AppointmentTimeSlot
