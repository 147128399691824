import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { noop } from 'lodash'

import { PaymentMethod } from '../../store/appointment/models'
import { getAbsoluteUrl } from '../../utils/general'
import mastercardIcon from '../../assets/icons/creditCardIconsByType/mastercard.svg'
import visaIcon from '../../assets/icons/creditCardIconsByType/visa.svg'
import amexIcon from '../../assets/icons/creditCardIconsByType/amex.svg'
import discoverIcon from '../../assets/icons/creditCardIconsByType/discover.svg'
import dinersClubIcon from '../../assets/icons/creditCardIconsByType/dinersClub.svg'
import jcbIcon from '../../assets/icons/creditCardIconsByType/jcb.svg'
import unionPayIcon from '../../assets/icons/creditCardIconsByType/unionPay.png'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(1.5, 2),
    background: theme.palette.background.default,
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      border: `2px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
    },
  },
  disabled: {
    cursor: 'default',
  },
  selectedWrapper: {
    backgroundColor: theme.palette.primary.light,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  icon: {
    height: 26,
    marginRight: theme.spacing(1),
  },
  text: {
    color: theme.palette.primary.dark,
  },
}))

type PaymentMethodsListProps = {
  disabled: boolean
  selectedPaymentId: PaymentMethod['collectlyId'] | null
  payments: PaymentMethod[]
  onSelectPayment: (paymentId: PaymentMethod['collectlyId']) => void
}

const PaymentMethodsList: React.FC<PaymentMethodsListProps> = ({
  disabled,
  selectedPaymentId,
  payments,
  onSelectPayment,
}) => {
  const classes = useStyles()

  const creditCardTypeIcon: Record<string, string> = useMemo(() => {
    return {
      mastercard: mastercardIcon,
      visa: visaIcon,
      amex: amexIcon,
      discover: discoverIcon,
      dinersclub: dinersClubIcon,
      jcb: jcbIcon,
      unionpay: unionPayIcon,
    }
  }, [])

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {payments.map(({ collectlyId, last4, type }) => (
        <Box
          key={collectlyId}
          display="flex"
          alignItems="center"
          onClick={disabled ? noop : () => onSelectPayment(collectlyId)}
          className={clsx(classes.wrapper, {
            [classes.disabled]: disabled,
            [classes.selectedWrapper]: collectlyId === selectedPaymentId,
          })}
        >
          {creditCardTypeIcon[type] && (
            <img
              src={getAbsoluteUrl(creditCardTypeIcon[type])}
              alt=""
              className={classes.icon}
            />
          )}

          <Typography className={classes.text}>{`****${last4}`}</Typography>
        </Box>
      ))}
    </Box>
  )
}

export default PaymentMethodsList
