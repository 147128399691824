import { useEffect, useRef } from 'react'
import { LoadingContext } from 'src/utils/types'

export const useLoadingChange = (
  callback: (loading: LoadingContext) => void,
  loading: LoadingContext
): void => {
  const previousLoading = useRef(loading)

  useEffect(() => {
    if (previousLoading.current.state !== loading.state) {
      previousLoading.current = loading
      callback(loading)
    }
  }, [loading])
}
