import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { Button, Grid, Theme, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { Controller, useForm } from 'react-hook-form'
import MaskedInput from 'react-text-mask'
import parsePhoneNumber from 'libphonenumber-js'

import KylaIcon from 'src/assets/icons/KylaIcon.svg'
import authActions from 'src/store/auth/actions'
import FooterButtons from '../../components/FooterButtons'
import {
  getVerificationType,
  makeGetRelatedVerificationSentTime,
  getGettingVerificationLoading,
  getLinkingWithChronoAccount,
  getDuplicationInfo,
  getTemporaryAddressDetails,
  getUserProfile,
} from '../../store/auth/selectors'
import {
  isFailure,
  isLoading,
  isSuccess,
  LoadingContext,
} from '../../utils/types'
import { useLoadingChange } from '../../hooks/useLoadingChange'
import ThemedTextField from '../../components/common/ThemedTextField'
import DisablingTicker from '../../components/common/DisablingTicker'
import { VerificationOptionsTypes } from '../../store/auth/models'
import { RoutePath } from '../../routes'
import { SUPPORT_PHONE_NUMBER } from '../../utils/general'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(9, 0, 4),
      flex: 1,
    },
    content: {
      padding: theme.spacing(0, 3),
      flex: 1,
    },
    text: {
      lineHeight: '20px',
      fontSize: '0.875rem',
      fontWeight: 500,
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
    },
    textWrapper: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2, 2.5),
      backgroundColor: theme.palette.primary.light,
      borderRadius: '20px 20px 20px 0px',
    },
    optionsContainer: {
      marginBottom: theme.spacing(4),
    },
    button: {
      fontFamily: 'NeurialGrotesk',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '20px',
      textAlign: 'center',
      color: theme.palette.primary.main,
      padding: theme.spacing(2, 2.5),
      border: `2px solid ${theme.palette.primary.light}`,
      boxSizing: 'border-box',
      borderRadius: '20px',
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    disabledButton: {
      opacity: 0.7,
      pointerEvents: 'none',
    },
    formInput: {
      width: '100%',
      marginBottom: theme.spacing(1.25),
    },
    selectedButton: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      '& .MuiButton-label': {
        color: '#fff !important',
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    errorText: {
      marginTop: theme.spacing(0.5),
      color: theme.palette.error.main,
      fontSize: '0.875rem',
    },
    actions: {
      padding: theme.spacing(0, 4),
    },
  }),
  { name: 'VerificationPage' }
)

const VerificationPage: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  const dispatch = useDispatch()

  const [error, setError] = useState('')

  const temporaryAddressDetails = useSelector(getTemporaryAddressDetails)

  const duplicationInfo = useSelector(getDuplicationInfo)
  const verificationOptions = duplicationInfo.verificationInfo

  const profile = useSelector(getUserProfile)

  const verificationType = useSelector(getVerificationType)
  const linkingWithChronoAccount = useSelector(getLinkingWithChronoAccount)
  const isLinkingWithChronoAccount = isLoading(linkingWithChronoAccount.state)

  const getRelatedVerificationSentTime = useMemo(
    () =>
      makeGetRelatedVerificationSentTime(
        verificationType as VerificationOptionsTypes
      ),
    [verificationType]
  )
  const verificationSentTime = useSelector(getRelatedVerificationSentTime)

  const { handleSubmit, errors, control, watch } = useForm<{
    verificationCode: string
  }>({
    mode: 'onChange',
    shouldFocusError: false,
  })

  const { verificationCode } = watch()

  const gettingVerificationLoading = useSelector(getGettingVerificationLoading)
  const gettingVerificationIsLoading = isLoading(
    gettingVerificationLoading.state
  )

  const handleGoBack = useCallback(() => {
    history.goBack()
  }, [history])

  const handleGoNext = useCallback(
    ({ verificationCode }) => {
      dispatch(
        authActions.linkWithChronoAccount.request({
          code: verificationCode,
          id: verificationOptions.id,
          patientId: profile.id,
        })
      )
    },
    [verificationOptions, profile]
  )

  const handleResendCode = useCallback(() => {
    dispatch(
      authActions.getVerificationCode.request({
        verificationType: verificationType as VerificationOptionsTypes,
        id: verificationOptions.id,
      })
    )
  }, [verificationOptions, verificationType])

  const handleGettingVerificationLoadingChange = useCallback(
    (newLoading: LoadingContext) => {
      if (isFailure(newLoading.state)) {
        setError(newLoading.message || '')
      }
    },
    []
  )

  useLoadingChange(
    handleGettingVerificationLoadingChange,
    gettingVerificationLoading
  )

  const handleLinkingWithChronoAccount = useCallback(
    (newLoading: LoadingContext) => {
      if (isSuccess(newLoading.state)) {
        dispatch(authActions.updateProfile.request(temporaryAddressDetails))

        history.replace(RoutePath.checkPendingAppt)
      }
      if (isFailure(newLoading.state)) {
        setError(newLoading.message || '')
      }
    },
    [temporaryAddressDetails]
  )

  useLoadingChange(handleLinkingWithChronoAccount, linkingWithChronoAccount)

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      <Grid
        container
        className={classes.content}
        direction="column"
        justifyContent="space-between"
      >
        <Grid item>
          <img src={KylaIcon} alt="kyla-avatar" />
        </Grid>
        <Grid item container direction="column">
          <Grid item className={classes.textWrapper}>
            <Typography className={classes.text}>
              {`To verify your identity, please enter the verification code sent
              to ${
                verificationOptions[
                  verificationType.toLowerCase() as keyof typeof verificationOptions
                ]
              } `}
            </Typography>
          </Grid>
          <Grid item>
            <Controller
              render={(props) => (
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                  render={(ref: React.Ref<any>, props: any) => (
                    <ThemedTextField
                      autoComplete="off"
                      ref={ref}
                      disabled={
                        gettingVerificationIsLoading ||
                        isLinkingWithChronoAccount
                      }
                      classes={{ input: classes.formInput }}
                      placeholder="Verification Code"
                      error={Boolean(errors.verificationCode)}
                      {...props}
                    />
                  )}
                  {...props}
                />
              )}
              control={control}
              name="verificationCode"
              rules={{
                required: true,
                pattern: {
                  value: /^\d{6}$/,
                  message: 'Invalid verification code',
                },
              }}
            />
          </Grid>

          {Boolean(error) && (
            <Grid>
              <Typography className={clsx(classes.text, classes.errorText)}>
                {error}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            container
            className={classes.optionsContainer}
            justifyContent="flex-end"
          >
            <DisablingTicker
              time={verificationSentTime}
              disablingTimeInSeconds={60}
            >
              {({ disabled }) => (
                <Button
                  variant="outlined"
                  className={clsx(classes.button, {
                    [classes.disabledButton]:
                      disabled ||
                      gettingVerificationIsLoading ||
                      isLinkingWithChronoAccount,
                  })}
                  onClick={handleResendCode}
                >
                  Resend code
                </Button>
              )}
            </DisablingTicker>
            <Button
              variant="outlined"
              className={clsx(classes.button, {
                [classes.disabledButton]:
                  gettingVerificationIsLoading || isLinkingWithChronoAccount,
              })}
              href={parsePhoneNumber(SUPPORT_PHONE_NUMBER)?.getURI() || ''}
            >
              Call Support
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <FooterButtons
          classes={{ root: classes.actions }}
          nextButtonLabel="Continue"
          backButtonLabel="Back"
          onNextButtonClick={handleSubmit(handleGoNext)}
          onBackButtonClick={handleGoBack}
          disableNext={!verificationCode || gettingVerificationIsLoading}
          loadingNext={isLinkingWithChronoAccount}
          disableBack={
            gettingVerificationIsLoading || isLinkingWithChronoAccount
          }
        />
      </Grid>
    </Grid>
  )
}

export default VerificationPage
