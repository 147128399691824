import { useCallback, useState } from 'react'

const useGoogleMapApi = () => {
  const [googleMapObj, setGoogleMapObj] = useState<any>()
  const [googleMapsObj, setGoogleMapsObj] = useState<any>()

  const updateGoogleMapObjects = useCallback(
    (map, maps) => {
      setGoogleMapObj(map)
      setGoogleMapsObj(maps)
    },
    [setGoogleMapObj, setGoogleMapsObj]
  )

  return { googleMapObj, googleMapsObj, updateGoogleMapObjects }
}

export default useGoogleMapApi
