import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Classes } from '@mui/styles/mergeClasses/mergeClasses'
import clsx from 'clsx'
import { useIsLabOrderingFlow } from '../../hooks/labOrdering/useIsLabOrderingFlow'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(6, 4, 4),
      flex: 1,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: theme.spacing(4),
    },
    title: {
      fontWeight: 700,
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      color: theme.palette.primary.dark,
      lineHeight: 1.5,
      fontFamily: 'NeurialGrotesk',
    },
    labOrderingRoot: {
      paddingTop: theme.spacing(4),
    },
  }),
  { name: 'AuthLayout' }
)

export type AuthLayoutProps = {
  title?: string | React.ReactNode
  titleRight?: string
  children: React.ReactNode
  bottomActions?: React.ReactNode
  topActions?: React.ReactNode
  contentClass?: string
  classes?: Classes
  contentProps?: Record<string, any>
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  title,
  titleRight,
  children,
  bottomActions,
  topActions,
  contentClass,
  classes: classesProps = {},
  contentProps = {},
}) => {
  const classes = useStyles({ classes: classesProps })

  const isLabOrderingFlow = useIsLabOrderingFlow()

  return (
    <Box
      className={clsx(
        classes.root,
        isLabOrderingFlow ? classes.labOrderingRoot : ''
      )}
    >
      {title && (
        <Box display="flex" flexDirection="row" mb={2}>
          <Typography className={classes.title}>{title}</Typography>
          {titleRight && (
            <Typography ml="auto" className={classes.title}>
              {titleRight}
            </Typography>
          )}
        </Box>
      )}

      {topActions && <Box>{topActions}</Box>}

      <Box flex={1} className={contentClass} {...contentProps}>
        {children}
      </Box>
      {bottomActions && <Box>{bottomActions}</Box>}
    </Box>
  )
}

export default AuthLayout
