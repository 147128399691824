import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Stack, Box } from '@mui/material'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import InfoIcon from '@mui/icons-material/Info'
import { useDispatch, useSelector } from 'react-redux'

import AuthLayout from '../../components/layouts/AuthLayout'
import { IndicatedPaymentMethod } from '../../store/appointment/types'
import { getAbsoluteUrl } from '../../utils/general'
import InsuranceLight from '../../assets/icons/labOrdering/InsuranceLight.svg'
import InsuranceBold from '../../assets/icons/labOrdering/InsuranceBold.svg'
import SelfPayLight from '../../assets/icons/labOrdering/SelfPayLight.svg'
import SelfPayBold from '../../assets/icons/labOrdering/SelfPayBold.svg'
import { useLabOrderData } from '../../hooks/labOrdering/useLabOrderData'
import FooterButtons from '../../components/FooterButtons'
import { RoutePath } from '../../routes'
import appointmentActions from '../../store/appointment/actions'
import {
  getAppointmentProductId,
  useProductById,
} from '../../store/appointment/selectors'
import { isLoading } from '../../utils/types'

const useStyles = makeStyles(
  (theme) => ({
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
    },
    subTitle: {
      lineHeight: '20px',
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    wrapper: {
      padding: theme.spacing(1.5, 2),
      background: theme.palette.background.default,
      border: `2px solid ${theme.palette.primary.light}`,
      borderRadius: '20px',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        border: `2px solid ${theme.palette.primary.main}`,
        cursor: 'pointer',
      },
    },
    selectedWrapper: {
      backgroundColor: theme.palette.primary.light,
      border: `2px solid ${theme.palette.primary.main}`,
    },
  }),
  { name: 'ChooseLabOrderPaymentMethod' }
)

const ChooseLabOrderPaymentMethod: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const { order } = useLabOrderData()

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    IndicatedPaymentMethod.insurance
  )

  const handleProceed = useCallback(() => {
    dispatch(appointmentActions.setSelectedPaymentMethod(selectedPaymentMethod))

    if (selectedPaymentMethod === IndicatedPaymentMethod.insurance) {
      history.push(RoutePath.addSignature)
    } else {
      history.push(RoutePath.chooseConsultationExistence)
    }
  }, [dispatch, history, selectedPaymentMethod])

  const appointmentProductId = useSelector(getAppointmentProductId)
  const { loading: consultationProductLoading } = useProductById(
    appointmentProductId
  )
  const isLoadingConsultationProduct = isLoading(
    consultationProductLoading.state
  )

  const loadProduct = useCallback(() => {
    dispatch(appointmentActions.getProductById.request(appointmentProductId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentProductId])

  useEffect(loadProduct, [loadProduct])

  return (
    <AuthLayout
      title="Payment Method"
      bottomActions={
        <FooterButtons
          nextOnly
          nextButtonLabel="Continue"
          loadingNext={isLoadingConsultationProduct}
          onNextButtonClick={handleProceed}
        />
      }
    >
      <Stack spacing={6}>
        <Box display="flex" gap={1}>
          <InfoIcon color="primary" />
          <Typography className={clsx(classes.text, classes.subTitle)}>
            Insurance-covered lab tests are available only in California and
            require a doctor consultation.
          </Typography>
        </Box>

        <Stack spacing={1.5}>
          <Stack
            spacing={2}
            direction="row"
            className={clsx(classes.wrapper, {
              [classes.selectedWrapper]:
                selectedPaymentMethod === IndicatedPaymentMethod.insurance,
            })}
            onClick={() =>
              setSelectedPaymentMethod(IndicatedPaymentMethod.insurance)
            }
          >
            <Box>
              <img
                src={
                  selectedPaymentMethod === IndicatedPaymentMethod.insurance
                    ? getAbsoluteUrl(InsuranceBold)
                    : getAbsoluteUrl(InsuranceLight)
                }
                height={55}
                alt="insurance-sheild"
              />
            </Box>

            <Stack>
              <Typography className={classes.text} sx={{ fontWeight: 500 }}>
                I have insurance
              </Typography>
              <Typography className={classes.text}>
                Insurance coverage may vary
              </Typography>
            </Stack>
          </Stack>

          <Stack
            spacing={2}
            direction="row"
            className={clsx(classes.wrapper, {
              [classes.selectedWrapper]:
                selectedPaymentMethod === IndicatedPaymentMethod.cash,
            })}
            onClick={() =>
              setSelectedPaymentMethod(IndicatedPaymentMethod.cash)
            }
          >
            <Box>
              <img
                src={
                  selectedPaymentMethod === IndicatedPaymentMethod.cash
                    ? getAbsoluteUrl(SelfPayBold)
                    : getAbsoluteUrl(SelfPayLight)
                }
                height={55}
                alt="self-pay-pocket"
              />
            </Box>
            <Stack>
              <Typography className={classes.text} sx={{ fontWeight: 500 }}>
                I will self-pay
              </Typography>
              <Typography className={classes.text}>
                {`Self - pay price is $${Number(order!.price)}`}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </AuthLayout>
  )
}

export default ChooseLabOrderPaymentMethod
