import React from 'react'
import { Box, BoxProps, Grid } from '@mui/material'

type FooterControlsProps = {
  children: React.ReactNode
} & BoxProps

const FooterControls: React.FC<FooterControlsProps> = ({
  children,
  ...props
}) => (
  <Box paddingX={0} {...props}>
    <Grid container direction="column" spacing={1}>
      {React.Children.map(children, (item) => {
        if (!item) {
          return null
        }

        return <Grid item>{item}</Grid>
      })}
    </Grid>
  </Box>
)

export default FooterControls
