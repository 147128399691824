import { Box, BoxProps } from '@mui/material'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'

export type PaperProps = BoxProps

const useStyles = makeStyles((theme) => ({
  root: {
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: 20,
    overflow: 'hidden',
  },
}))

const Paper: React.FC<PaperProps> = ({ className, ...props }) => {
  const classes = useStyles()

  return <Box className={clsx(classes.root, className)} {...props} />
}

export default Paper
