import { ActionType, createReducer } from 'typesafe-actions'

import authActions from 'src/store/auth/actions'
import { makeLoading } from '../../../utils/store'
import {
  LoadingContext,
  LoadingContextDefault,
  LoadingState,
} from '../../../utils/types'
import { extractErrorMessage } from '../../../utils/errors'

interface State {
  checkEmailLoading: LoadingContext
  createAccountLoading: LoadingContext
  signInLoading: LoadingContext
  forgotPasswordLoading: LoadingContext
  resetPasswordLoading: LoadingContext
  gettingVerificationLoading: LoadingContext
  checkingKylaDuplicate: LoadingContext
  sendingReactivationCode: LoadingContext
  updatingPassword: LoadingContext
  verifyingEmail: LoadingContext
  eSignatureStatusGetting: LoadingContext
  eSignatureSubmitting: LoadingContext
  createChronoAccountLoading: LoadingContext
  skippingChronoDuplicate: LoadingContext
  linkingWithChronoAccount: LoadingContext
  profileLoading: LoadingContext
  updateProfileSubmitting: LoadingContext
  idCardUploading: LoadingContext
}

const initialState: State = {
  checkEmailLoading: LoadingContextDefault,
  createAccountLoading: LoadingContextDefault,
  signInLoading: LoadingContextDefault,
  forgotPasswordLoading: LoadingContextDefault,
  resetPasswordLoading: LoadingContextDefault,
  gettingVerificationLoading: LoadingContextDefault,
  checkingKylaDuplicate: LoadingContextDefault,
  sendingReactivationCode: LoadingContextDefault,
  updatingPassword: LoadingContextDefault,
  verifyingEmail: LoadingContextDefault,
  eSignatureStatusGetting: LoadingContextDefault,
  eSignatureSubmitting: LoadingContextDefault,
  createChronoAccountLoading: LoadingContextDefault,
  skippingChronoDuplicate: LoadingContextDefault,
  linkingWithChronoAccount: LoadingContextDefault,
  profileLoading: LoadingContextDefault,
  updateProfileSubmitting: LoadingContextDefault,
  idCardUploading: LoadingContextDefault,
}

export default createReducer<State, ActionType<typeof authActions>>(
  initialState
)
  .handleAction(
    authActions.checkEmail.request,
    (state): State => ({
      ...state,
      checkEmailLoading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.checkEmail.success,
    (state): State => ({
      ...state,
      checkEmailLoading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.checkEmail.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      checkEmailLoading: makeLoading(LoadingState.failure, message),
    })
  )
  .handleAction(
    authActions.checkKylaDuplicate.request,
    (state): State => ({
      ...state,
      checkingKylaDuplicate: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.checkKylaDuplicate.success,
    (state): State => ({
      ...state,
      checkingKylaDuplicate: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.checkKylaDuplicate.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      checkingKylaDuplicate: makeLoading(LoadingState.failure, message),
    })
  )
  .handleAction(
    authActions.createAccount.request,
    (state): State => ({
      ...state,
      createAccountLoading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.createAccount.success,
    (state): State => ({
      ...state,
      createAccountLoading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.createAccount.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      createAccountLoading: makeLoading(LoadingState.failure, message),
    })
  )
  .handleAction(
    [authActions.signIn.request, authActions.updateTos.request],
    (state): State => ({
      ...state,
      signInLoading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    [authActions.signIn.success, authActions.updateTos.success],
    (state): State => ({
      ...state,
      signInLoading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    [authActions.signIn.failure, authActions.updateTos.failure],
    (state, { payload: { message } }): State => ({
      ...state,
      signInLoading: makeLoading(LoadingState.failure, message),
    })
  )
  .handleAction(
    authActions.forgotPassword.request,
    (state): State => ({
      ...state,
      forgotPasswordLoading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.forgotPassword.success,
    (state): State => ({
      ...state,
      forgotPasswordLoading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.forgotPassword.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      forgotPasswordLoading: makeLoading(LoadingState.failure, message),
    })
  )
  .handleAction(
    authActions.resetPassword.request,
    (state): State => ({
      ...state,
      resetPasswordLoading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.resetPassword.success,
    (state): State => ({
      ...state,
      resetPasswordLoading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.resetPassword.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      resetPasswordLoading: makeLoading(LoadingState.failure, message),
    })
  )
  .handleAction(
    authActions.getVerificationCode.request,
    (state): State => ({
      ...state,
      gettingVerificationLoading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.getVerificationCode.success,
    (state): State => ({
      ...state,
      gettingVerificationLoading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.getVerificationCode.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      gettingVerificationLoading: makeLoading(LoadingState.failure, message),
    })
  )
  .handleAction(
    authActions.reactivateAccount.request,
    (state): State => ({
      ...state,
      sendingReactivationCode: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.reactivateAccount.success,
    (state): State => ({
      ...state,
      sendingReactivationCode: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.reactivateAccount.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      sendingReactivationCode: makeLoading(LoadingState.failure, message),
    })
  )
  .handleAction(
    authActions.updatePassword.request,
    (state): State => ({
      ...state,
      updatingPassword: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.updatePassword.success,
    (state): State => ({
      ...state,
      updatingPassword: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.updatePassword.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      updatingPassword: makeLoading(LoadingState.failure, message),
    })
  )
  .handleAction(
    authActions.verifyEmail.request,
    (state): State => ({
      ...state,
      verifyingEmail: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.verifyEmail.success,
    (state): State => ({
      ...state,
      verifyingEmail: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.verifyEmail.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      verifyingEmail: makeLoading(LoadingState.failure, message),
    })
  )
  .handleAction(
    authActions.checkESignatureStatus.request,
    (state): State => ({
      ...state,
      eSignatureStatusGetting: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.checkESignatureStatus.success,
    (state): State => ({
      ...state,
      eSignatureStatusGetting: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.checkESignatureStatus.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      eSignatureStatusGetting: makeLoading(LoadingState.failure, null, error),
    })
  )

  .handleAction(
    authActions.uploadESignature.request,
    (state): State => ({
      ...state,
      eSignatureSubmitting: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.uploadESignature.success,
    (state): State => ({
      ...state,
      eSignatureSubmitting: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.uploadESignature.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      eSignatureSubmitting: makeLoading(LoadingState.failure, null, error),
    })
  )

  .handleAction(
    authActions.createChronoAccount.request,
    (state): State => ({
      ...state,
      createChronoAccountLoading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.createChronoAccount.success,
    (state): State => ({
      ...state,
      createChronoAccountLoading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.createChronoAccount.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      createChronoAccountLoading: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )

  .handleAction(
    authActions.linkWithChronoAccount.request,
    (state): State => ({
      ...state,
      linkingWithChronoAccount: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.linkWithChronoAccount.success,
    (state): State => ({
      ...state,
      linkingWithChronoAccount: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.linkWithChronoAccount.failure,
    (state, { payload: { error, message } }): State => ({
      ...state,
      linkingWithChronoAccount: makeLoading(
        LoadingState.failure,
        message,
        error
      ),
    })
  )
  .handleAction(
    authActions.getProfile.request,
    (state): State => ({
      ...state,
      profileLoading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.getProfile.success,
    (state): State => ({
      ...state,
      profileLoading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.getProfile.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      profileLoading: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )

  .handleAction(
    authActions.updateProfile.request,
    (state): State => ({
      ...state,
      updateProfileSubmitting: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.updateProfile.success,
    (state): State => ({
      ...state,
      updateProfileSubmitting: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.updateProfile.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      updateProfileSubmitting: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )

  .handleAction(
    authActions.skipChronoDuplicate.request,
    (state): State => ({
      ...state,
      skippingChronoDuplicate: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.skipChronoDuplicate.success,
    (state): State => ({
      ...state,
      skippingChronoDuplicate: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.skipChronoDuplicate.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      skippingChronoDuplicate: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )

  .handleAction(
    authActions.uploadIdCard.request,
    (state): State => ({
      ...state,
      idCardUploading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    authActions.uploadIdCard.success,
    (state): State => ({
      ...state,
      idCardUploading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    authActions.uploadIdCard.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      idCardUploading: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )
