import React from 'react'
import { Button, Grid, Theme, Typography } from '@mui/material'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'

import { TypeOfSkip } from '../../store/auth/models'
import gtag from '../../utils/gtag'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 180,
      margin: 'auto',
      width: '80%',
      background: theme.palette.background.default,
      borderRadius: 10,
      boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)',
      padding: theme.spacing(2),
      display: 'none',
      transition: 'opacity 0.15s linear',
    },
    showedRoot: {
      display: 'inherit',
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
      marginBottom: theme.spacing(2),
    },
    dialogTitle: {
      marginBottom: 0,
      fontWeight: 700,
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      lineHeight: 1.5,
    },
    button: {
      marginBottom: theme.spacing(1.5),
      borderRadius: '12px',
      height: 56,
      fontSize: '1rem',
      fontFamily: 'NeurialGrotesk',
      fontWeight: 700,
    },
    buttonsContainer: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'KylaDuplicationDialog' }
)

interface KylaDuplicationDialogProps {
  open: boolean
  email: string

  onClose(): void
  skipSignUp(): void
  skipDuplicationSafeguards(typeOfSkip: TypeOfSkip): void
}

const KylaDuplicationDialog: React.FC<KylaDuplicationDialogProps> = ({
  open,
  email,
  onClose,
  skipSignUp,
  skipDuplicationSafeguards,
}) => {
  const classes = useStyles()

  const handleLogInWithThisAcc = () => {
    gtag('event', 'duplicate_login_proposed_account')

    skipSignUp()
  }

  const handleThisIsNotMyAcc = (typeOfSkip: TypeOfSkip) => {
    if (typeOfSkip === TypeOfSkip.notMineAccount) {
      gtag('event', 'duplicate_not_my_account')
    } else {
      gtag('event', 'duplicate_cant_access_account')
    }

    skipDuplicationSafeguards(typeOfSkip)
  }

  const handleBackClick = () => {
    gtag('event', 'duplicate_back')

    onClose()
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={clsx(classes.root, { [classes.showedRoot]: open })}
    >
      <Grid>
        <Typography className={clsx(classes.text)}>
          We have found an existing Kyla account with information matching to
          yours in our system under another email:
        </Typography>
        <Typography className={clsx(classes.text)}>{email}</Typography>
        <Typography className={clsx(classes.text)}>
          Would you like to?
        </Typography>
      </Grid>
      <Grid container>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={handleLogInWithThisAcc}
        >
          Log in With This Account
        </Button>

        <Button
          fullWidth
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={() => handleThisIsNotMyAcc(TypeOfSkip.notMineAccount)}
        >
          This is Not My Account
        </Button>

        <Button
          fullWidth
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={() => handleThisIsNotMyAcc(TypeOfSkip.noAccess)}
        >
          I Can&apos;t Access This Account
        </Button>

        <Button
          fullWidth
          color="primary"
          className={classes.button}
          onClick={handleBackClick}
        >
          Back
        </Button>
      </Grid>
    </Grid>
  )
}

export default KylaDuplicationDialog
