import { ActionType, createReducer } from 'typesafe-actions'

import {
  LoadingContext,
  LoadingContextDefault,
  LoadingState,
} from '../../../utils/types'
import { makeLoading } from '../../../utils/store'
import { insuranceActions } from '../actions'

interface State {
  fetchingCompanies: LoadingContext

  insuranceVerifying: LoadingContext

  manualVerificationStatusLoading: LoadingContext
  manualVerificationSubmitting: LoadingContext

  fetchingPatientOfficeAllyInfo: LoadingContext
}

const initialState: State = {
  fetchingCompanies: LoadingContextDefault,

  insuranceVerifying: LoadingContextDefault,

  manualVerificationStatusLoading: LoadingContextDefault,
  manualVerificationSubmitting: LoadingContextDefault,

  fetchingPatientOfficeAllyInfo: LoadingContextDefault,
}

export default createReducer<State, ActionType<typeof insuranceActions>>(
  initialState
)
  .handleAction(
    insuranceActions.getCompanies.request,
    (state): State => ({
      ...state,
      fetchingCompanies: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    insuranceActions.getCompanies.success,
    (state): State => ({
      ...state,
      fetchingCompanies: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    insuranceActions.getCompanies.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      fetchingCompanies: makeLoading(LoadingState.failure, message),
    })
  )
  .handleAction(
    [insuranceActions.verifyInsurance.request],
    (state): State => ({
      ...state,
      insuranceVerifying: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    insuranceActions.verifyInsurance.success,
    (state): State => ({
      ...state,
      insuranceVerifying: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    insuranceActions.verifyInsurance.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      insuranceVerifying: makeLoading(LoadingState.failure, message),
    })
  )

  .handleAction(
    [insuranceActions.getManualVerificationStatus.request],
    (state): State => ({
      ...state,
      manualVerificationStatusLoading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    insuranceActions.getManualVerificationStatus.success,
    (state): State => ({
      ...state,
      manualVerificationStatusLoading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    insuranceActions.getManualVerificationStatus.failure,
    (state): State => ({
      ...state,
      manualVerificationStatusLoading: makeLoading(LoadingState.failure),
    })
  )

  .handleAction(
    insuranceActions.requestManualVerification.request,
    (state): State => ({
      ...state,
      manualVerificationSubmitting: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    insuranceActions.requestManualVerification.success,
    (state): State => ({
      ...state,
      manualVerificationSubmitting: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    insuranceActions.requestManualVerification.failure,
    (state): State => ({
      ...state,
      manualVerificationSubmitting: makeLoading(LoadingState.failure),
    })
  )

  .handleAction(
    insuranceActions.getPatientOfficeAlly.request,
    (state): State => ({
      ...state,
      fetchingPatientOfficeAllyInfo: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    insuranceActions.getPatientOfficeAlly.success,
    (state): State => ({
      ...state,
      fetchingPatientOfficeAllyInfo: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    insuranceActions.getPatientOfficeAlly.failure,
    (state): State => ({
      ...state,
      fetchingPatientOfficeAllyInfo: makeLoading(LoadingState.failure),
    })
  )
