import { createSelector } from 'reselect'

import { State } from 'src/store/rootReducer'
import { Clinic } from './models'

const getClinicsEntities = (state: State): State['clinics']['entities'] =>
  state.clinics.entities

const getClinicsUI = (state: State): State['clinics']['ui'] => state.clinics.ui

export const getClinicsLoadingState = createSelector(
  getClinicsUI,
  (ui) => ui.loading
)

export const getClinicsEntries = createSelector(getClinicsEntities, (entity) =>
  entity.list.map((id) => entity.byId[id])
)

export const getClinicInfo = (clinicId: Clinic['id']) =>
  createSelector(getClinicsEntities, (entity) => entity.byId[clinicId])

