import * as yup from 'yup'
import packageJson from '../../package.json'

const schema = yup.object({
  version: yup.string().required(),
  googleMapsApiKey: yup.string().required(),
  host: yup.string().required(),

  server: yup
    .object({
      host: yup
        .string()
        .url()
        .required(),
      chatHost: yup
        .string()
        .url()
        .required(),
      aucWpHost: yup
        .string()
        // .url()
        .required(),
      kylaWpHost: yup
        .string()
        // .url()
        .required(),
      drChronoHost: yup
        .string()
        .url()
        .required(),
    })
    .required(),
})

export const config = schema.validateSync({
  version: packageJson.version,
  host: process.env.REACT_APP_HOST,
  server: {
    host: process.env.REACT_APP_SERVER_API_URL,
    chatHost: process.env.REACT_APP_CHAT_API_URL,
    aucWpHost: process.env.REACT_APP_AUC_WP_HOST,
    kylaWpHost: process.env.REACT_APP_KYLA_WP_HOST,
    drChronoHost: process.env.REACT_APP_DR_CHRONO_BASE_URL,
  },
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})
