import React, { useCallback, useEffect, useState } from 'react'
import { Grid, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'

import { isLoading } from '../../../utils/types'
import { isSafari } from '../../../utils/general'
import { useControlSlots } from '../../../hooks/useControlSlots'
import { useInClinicAvailableSlots } from '../../../hooks/useInClinicAvailableSlots'
import AppointmentTimeSlot from '../../../containers/appointment/AppointmentTimeSlot'
import AppointmentTimeSlotsControllers from '../../../components/appointmentCreation/AppointmentTimeSlotsControllers'
import AppointmentSlotsLoadingOverlayItem from '../../../components/appointmentCreation/AppointmentSlotsLoadingOverlayItem'
import useLoadingFailure from '../../../hooks/useLoadingFailure'
import { extractErrorMessage } from '../../../utils/errors'
import { InClinicSlot } from '../../../store/appointment/models'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      flex: 1,
      overflow: 'auto',
      position: 'relative',
      marginBottom: theme.spacing(8),
    },
    slotsColumns: {
      flex: 1,
      overflow: 'auto',
      padding: theme.spacing(0, 4),
    },
    noApptsPlaceholder: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '0.875rem',
      lineHeight: 1.5,
      textAlign: 'center',
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    reducedMarginRight: {
      padding: theme.spacing(0, 2, 0, 4),
    },
  }),
  { name: 'InClinicAppointmentTimeSelection' }
)

interface InClinicAppointmentTimeSelectionProps {
  selectedTime: InClinicSlot
  onTimeSelect: (time: InClinicSlot) => void
}

const InClinicAppointmentTimeSelection: React.FC<InClinicAppointmentTimeSelectionProps> = ({
  selectedTime,
  onTimeSelect,
}) => {
  const classes = useStyles()

  const [error, setError] = useState('')

  const { dateRange, handleShowPrev, handleShowNext } = useControlSlots()

  const {
    slotsInfo,
    loading,
    withScrolling,
    getSlots,
  } = useInClinicAvailableSlots(dateRange.current, 5)

  const apptSlotsIsLoading = isLoading(loading.state)

  useEffect(() => {
    getSlots()
    setError('')
  }, [dateRange.current])

  const handleRetry = useCallback(() => {
    setError('')
    getSlots()
  }, [getSlots])

  useLoadingFailure(loading, ({ error }) => {
    setError(extractErrorMessage(error))
  })

  return (
    <Grid container direction="column" className={classes.root}>
      {apptSlotsIsLoading && <AppointmentSlotsLoadingOverlayItem />}
      <AppointmentTimeSlotsControllers
        error={error}
        dateRange={dateRange}
        onRetry={handleRetry}
        onShowNext={handleShowNext}
        onShowPrev={handleShowPrev}
      />
      {!error && (
        <Grid
          container
          item
          className={clsx(classes.slotsColumns, {
            [classes.reducedMarginRight]: withScrolling && !isSafari,
          })}
          justifyContent="center"
        >
          <Grid item xs={6}>
            {slotsInfo.currentDateHasFreeSlots ? (
              <>
                {slotsInfo?.currentDate.map((slot) => (
                  <AppointmentTimeSlot
                    key={slot.start}
                    selectedTime={selectedTime}
                    slot={slot}
                    onSelect={onTimeSelect}
                  />
                ))}
              </>
            ) : (
              <Typography className={classes.noApptsPlaceholder}>
                No available timing
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            {slotsInfo.nextDateHasFreeSlots ? (
              <>
                {slotsInfo?.nextDate.map((slot) => (
                  <AppointmentTimeSlot
                    key={slot.start}
                    selectedTime={selectedTime}
                    slot={slot}
                    onSelect={onTimeSelect}
                  />
                ))}
              </>
            ) : (
              <Typography className={classes.noApptsPlaceholder}>
                No available timing
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default InClinicAppointmentTimeSelection
