import { State } from 'src/store/rootReducer'
import { createSelector } from 'reselect'
import { LoadingContextDefault } from '../../utils/types'

const getAttachmentEntities = (
  state: State
): State['attachments']['entities'] => state.attachments.entities

const getAttachmentsUI = (state: State): State['attachments']['ui'] =>
  state.attachments.ui

export const makeGetAttachmentById = (attachmentId: number | null) =>
  createSelector(getAttachmentEntities, (entities) =>
    attachmentId ? entities.attachmentsById[attachmentId] : null
  )

export const makeGetAttachmentFetchingById = (attachmentId: number | null) =>
  createSelector(getAttachmentsUI, (ui) =>
    attachmentId
      ? ui.fetchingAttachmentsById[attachmentId] ||
        LoadingContextDefault
      : LoadingContextDefault
  )
