import { useLoadingChange } from '../hooks/useLoadingChange'
import { LoadingState, LoadingContext } from './types'

const makeLoadingChange = (loadingState: LoadingState) => (
  loading: LoadingContext,
  callback: (loading: LoadingContext) => void
): void => {
  useLoadingChange((nextLoading) => {
    if (nextLoading.state !== loadingState) {
      return
    }

    callback(nextLoading)
  }, loading)
}

export default makeLoadingChange
