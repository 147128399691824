import React from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'

import OverlayItem from '../common/OverlayItem'

const useStyles = makeStyles(
  () => ({
    progress: {
      margin: '0 auto',
    },
    progressText: {
      fontFamily: 'NeurialGrotesk',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '24px',
      textAlign: 'center',
      color: '#2066C3',
    },
    progressTextSmall: {
      fontSize: '0.875rem',
    },
  }),
  {
    name: 'AppointmentSlotsLoadingOverlayItem',
  }
)

const AppointmentSlotsLoadingOverlayItem: React.FC = () => {
  const classes = useStyles()

  return (
    <OverlayItem>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <CircularProgress className={classes.progress} />
        <Typography className={classes.progressText}>
          Checking for available appointments
        </Typography>
        <Typography
          className={clsx(classes.progressText, classes.progressTextSmall)}
        >
          (this may take up to 20 seconds)
        </Typography>
      </Box>
    </OverlayItem>
  )
}

export default AppointmentSlotsLoadingOverlayItem
