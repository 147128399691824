import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Box, Button, Typography, styled } from '@mui/material'
import usePrevious from '../../hooks/usePrevious'
import Paper from './Paper'

export type SignatureProps = {
  disabled?: boolean
  onChange(touched: boolean): void
}

export type SignatureRef = {
  getBlob(options: { mime: string; quality: number }): Promise<Blob | null>
}

const CanvasWrapper = styled(Paper)<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
    position: 'relative',

    ...(disabled && {
      pointerEvents: 'none',
      opacity: 0.5,
    }),
  })
)

const HintText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  userSelect: 'none',
  color: theme.palette.grey[500],
  pointerEvents: 'none',
  '& span': {
    display: 'block',
    whiteSpace: 'nowrap',
  },
}))

const Signature = forwardRef<SignatureRef, SignatureProps>(
  ({ disabled, onChange }, ref) => {
    const [touched, setTouched] = useState(false)
    const prevTouched = usePrevious(touched)

    const canvas = useRef<SignatureCanvas | null>(null)

    const handleBegin = useCallback(() => {
      if (touched) {
        return
      }
      setTouched(true)
    }, [touched])

    const handleClearClick = useCallback(() => {
      canvas.current?.clear()
      setTouched(false)
    }, [])

    useEffect(() => {
      if (prevTouched === undefined) {
        return
      }

      onChange(touched)
    }, [touched, onChange, prevTouched])

    useImperativeHandle(ref, () => ({
      getBlob: (options) =>
        new Promise((resolve) => {
          canvas.current?.getCanvas().toBlob(
            (blob) => {
              resolve(blob)
            },
            options.mime,
            options.quality
          )
        }),
    }))

    return (
      <Box>
        <CanvasWrapper disabled={disabled}>
          <SignatureCanvas
            ref={canvas}
            penColor="#000"
            minWidth={2}
            maxWidth={2}
            dotSize={2}
            minDistance={0}
            onBegin={handleBegin}
            canvasProps={{ width: 337, height: 200 }}
          />
          {!touched && (
            <HintText align="center">
              <span>Please Sign Here</span>
              <span>Using TouchPad or Mouse</span>
            </HintText>
          )}
        </CanvasWrapper>
        <Button
          color="primary"
          variant="text"
          fullWidth
          disabled={!touched || disabled}
          onClick={handleClearClick}
        >
          Clear
        </Button>
      </Box>
    )
  }
)

export default memo(Signature)
