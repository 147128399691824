import React from 'react'
import { InputBase, InputBaseProps, TextFieldProps, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Classes } from '@mui/styles/mergeClasses/mergeClasses'
import clsx from 'clsx'
import { InputProps as StandardInputProps } from '@mui/material/Input/Input'

const useStyles = makeStyles(
  (theme: Theme) => ({
    input: {
      '&.Mui-disabled': {
        borderColor: '#adb2ba',
      },
      backgroundColor: theme.palette.primary.light,
      borderRadius: '10px',
      color: theme.palette.primary.dark,
      border: '2px solid #BFD4F0',
      '&:hover': {
        borderColor: theme.palette.primary.dark,
      },
      '& input': {
        '&[type=number]': {
          '-moz-appearance': 'textfield',
        },
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
    },
    inputRoot: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '0.875rem',
      lineHeight: '1.3',
      borderRadius: '10px',
      padding: theme.spacing(2),
    },
    focusedInputRoot: {
      backgroundColor: theme.palette.background.default,
    },
    multiline: {
      padding: 0,
    },
    error: {
      borderColor: theme.palette.error.main,
    },
    selectError: {
      '& div': {
        borderColor: theme.palette.error.main,
      },
    },
  }),
  { name: 'ThemedTextField' }
)

interface ThemedTextFieldProps extends InputBaseProps {
  multiline?: boolean
  placeholder?: string
  value?: string
  classes?: Classes
  onChange?: StandardInputProps['onChange']
  disabled?: boolean
  rows?: number
  type?: string
  InputProps?: Partial<StandardInputProps>
  inputProps?: StandardInputProps['inputProps']
  name?: string
  error?: boolean
  forSelect?: boolean
  autoComplete?: string
  variant?: TextFieldProps['variant']
  helperText?: string
}

const ThemedTextField = React.forwardRef<any, ThemedTextFieldProps>(
  (
    {
      color = 'primary',
      placeholder = '',
      value,
      forSelect = false,
      onChange,
      classes: classesProp = {},
      ...props
    },
    ref?: React.Ref<HTMLAnchorElement>
  ) => {
    const classes = useStyles({ classes: classesProp })

    return (
      <InputBase
        inputRef={ref}
        className={classes.input}
        classes={{
          input: classes.inputRoot,
          focused: classes.focusedInputRoot,
          multiline: classes.multiline,
          error: clsx(classes.error, {
            [classes.selectError]: forSelect && props?.error,
          }),
        }}
        color={color as any}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...props}
      />
    )
  }
)

export default ThemedTextField
