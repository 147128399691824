import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Theme, Typography, Grid, Link, FormControl } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import moment, { Moment } from 'moment'

import authActions from 'src/store/auth/actions'
import { useLoadingChange } from '../../../hooks/useLoadingChange'
import {
  isFailure,
  isLoading,
  isSuccess,
  LoadingContext,
} from '../../../utils/types'
import {
  getOutdatedTosList,
  getSignInLoading,
} from '../../../store/auth/selectors'
import { RoutePath } from '../../../routes'
import IOSSwitch from '../../../components/common/IOSSwitch'
import ThemedTextField from '../../../components/common/ThemedTextField'
import ThemedDatePicker from '../../../components/common/ThemedDatePicker'
import FooterButtons from '../../../components/FooterButtons'
import { validateMinDob } from '../../../utils/general'
import { useIsLabOrderingFlow } from '../../../hooks/labOrdering/useIsLabOrderingFlow'
import { useLabOrderDraftCreate } from '../../../hooks/labOrdering/useLabOrderDraftCreate'
import useLoadingSuccess from '../../../hooks/useLoadingSuccess'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(9, 4, 4),
      flex: 1,
    },
    controllersTitle: {
      fontSize: '1.125rem',
      lineHeight: 1.5,
      fontWeight: 500,
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
    },
    title: {
      fontWeight: 700,
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      lineHeight: 1.5,
    },
    link: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '0.875rem',
      lineHeight: 1.25,
      color: theme.palette.primary.dark,
      cursor: 'pointer',
    },
    formInput: {
      width: '100%',
      marginTop: theme.spacing(1.25),
      '&:first-child': {
        marginTop: theme.spacing(1.5),
      },
    },
    formControl: {
      width: '100%',
      marginTop: theme.spacing(1.25),
    },
    section: {
      marginTop: theme.spacing(3),
    },
    switches: {
      marginTop: theme.spacing(1),
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: '0.875rem',
    },
    detailsTextWrapper: {
      padding: theme.spacing(1.5),
      borderRadius: '12px 12px 12px 0',
      background: theme.palette.primary.light,
    },
  }),
  { name: 'OutdatedTosPage' }
)

export const TOS_FORM_NAMES: { [key: string]: string } = {
  TOS: 'tosConsents',
  TOS_MEDICAL_INFO: 'medicalRelease',
  TOS_MARKETING: 'tosMarketing',
  TOS_PG_APPLICATION_USAGE: 'minorAuth',
  TOS_PG: 'termsOfService',
}

export const TOS_LINKS: { [key: string]: string } = {
  TOS: 'https://kyla.com/p/tos-consents/',
  TOS_MEDICAL_INFO: 'https://kyla.com/p/medical-release-authorization/',
  TOS_MARKETING: 'https://kyla.com/p/authorization-of-marketing/',
  TOS_PG_APPLICATION_USAGE: 'https://kyla.com/p/minor-auth/',
  TOS_PG: 'https://kyla.com/p/terms-of-service-all/',
}

const HIPPA_TOS_LABELS: { [key: string]: string } = {
  TOS: 'Privacy policy',
  TOS_MEDICAL_INFO: 'Medical Release Authorization',
  TOS_MARKETING: 'Marketing Authorization',
}

const GUARDIAN_TOS_LABELS: { [key: string]: string } = {
  TOS_PG_APPLICATION_USAGE:
    'Authorization for Treatment of a Minor/Application Usage',
  TOS_PG: 'TOS, Consents, Authorizations',
}

export type TosFormValues = {
  firstName?: string
  lastName?: string
  dob?: Moment

  tosConsents?: boolean
  medicalRelease?: boolean
  minorAuth?: boolean
  termsOfService?: boolean
  tosMarketing?: boolean
}

const OutdatedTosPage: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const signInLoadingObj = useSelector(getSignInLoading)
  const signInIsLoading = isLoading(signInLoadingObj.state)

  const outdatedTosList = useSelector(getOutdatedTosList)

  const [generalValidationError, setGeneralValidationError] = useState('')

  const {
    creating: creatingLabOrderDraft,
    createLabOrderDraft,
  } = useLabOrderDraftCreate()
  const isCreatingLabOrderDraft = isLoading(creatingLabOrderDraft.state)

  const handleSuccessfulProceed = useCallback(() => {
    if (isLabOrderingFlow) {
      createLabOrderDraft()
    } else {
      history.push(RoutePath.createMedicalAccount)
    }
  }, [history, isLabOrderingFlow, createLabOrderDraft])

  const handleUpdateTosChange = useCallback(
    (newLoading: LoadingContext) => {
      if (isSuccess(newLoading.state)) {
        dispatch(authActions.clearOutdatedTos())

        handleSuccessfulProceed()
      }
      if (isFailure(newLoading.state)) {
        setGeneralValidationError(newLoading.message as string)
      }
    },
    [dispatch, setGeneralValidationError, history, handleSuccessfulProceed]
  )

  useLoadingChange(handleUpdateTosChange, signInLoadingObj)

  const { register, control, errors, watch, handleSubmit } = useForm<
    TosFormValues
  >({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      firstName: '',
      lastName: '',
      dob: undefined,

      tosConsents: false,
      medicalRelease: false,
      tosMarketing: false,
      minorAuth: false,
      termsOfService: false,
    },
  })

  const {
    dob,
    tosConsents,
    medicalRelease,
    minorAuth,
    termsOfService,
  } = watch()

  const isWithHippaTos = useMemo(
    () => outdatedTosList.some((tos) => HIPPA_TOS_LABELS[tos]),
    [JSON.stringify(outdatedTosList)]
  )

  const isWithGuardianTos = useMemo(
    () => outdatedTosList.some((tos) => GUARDIAN_TOS_LABELS[tos]),
    [JSON.stringify(outdatedTosList)]
  )

  const handleTosUpdate = useCallback(
    ({ dob, firstName, lastName }: TosFormValues) => {
      setGeneralValidationError('')

      dispatch(
        authActions.updateTos.request(
          firstName && lastName
            ? {
                guardianInfo: {
                  dateOfBirth: moment(dob).format('YYYY-MM-DD'),
                  firstName,
                  lastName,
                },
              }
            : {}
        )
      )
    },
    [dispatch, setGeneralValidationError]
  )

  const handleLogOut = useCallback(() => {
    dispatch(authActions.logOut())
    dispatch(authActions.clearOutdatedTos())

    history.push(RoutePath.confirmAppointment)
  }, [history, dispatch])

  const nextButtonDisabled = useMemo(
    () =>
      [tosConsents, medicalRelease, minorAuth, termsOfService].some(
        (value) => value === false
      ),
    [tosConsents, medicalRelease, minorAuth, termsOfService]
  )

  useLoadingSuccess(creatingLabOrderDraft, () => {
    history.replace(RoutePath.chooseLabOrderPaymentMethod)
  })

  const pageIsLoading = useMemo(
    () => signInIsLoading || isCreatingLabOrderDraft,
    [signInIsLoading, isCreatingLabOrderDraft]
  )

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      justifyContent="space-between"
    >
      <Grid item container direction="column" flex={1} sx={{ pb: 2 }}>
        <Typography className={clsx(classes.text, classes.title)}>
          TOS Update
        </Typography>

        <Grid container direction="column" justifyContent="flex-end" flex={1}>
          <Grid className={classes.detailsTextWrapper}>
            <Typography className={classes.text}>
              To continue, please read and agree to our updated terms and
              conditions
            </Typography>
          </Grid>

          {isWithHippaTos && (
            <Grid className={classes.section}>
              <Typography
                className={clsx(classes.text, classes.controllersTitle)}
              >
                HIPAA & Privacy
              </Typography>
              <Grid className={classes.switches}>
                {outdatedTosList.map((tos) =>
                  HIPPA_TOS_LABELS[tos] ? (
                    <Grid key={tos} container alignItems="center">
                      <Controller
                        name={TOS_FORM_NAMES[tos]}
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <Grid item xs={2}>
                            <IOSSwitch
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => props.onChange(e.target.checked)}
                              checked={props.value || false}
                            />
                          </Grid>
                        )}
                      />
                      <Grid item>
                        <Link
                          target="_blank"
                          className={classes.link}
                          href={TOS_LINKS[tos]}
                        >
                          {HIPPA_TOS_LABELS[tos]}
                        </Link>
                      </Grid>
                    </Grid>
                  ) : (
                    <React.Fragment key={tos} />
                  )
                )}
              </Grid>
            </Grid>
          )}
          {isWithGuardianTos && (
            <Grid className={classes.section}>
              <Typography
                className={clsx(classes.text, classes.controllersTitle)}
              >
                Consent for minor ages
              </Typography>
              <ThemedTextField
                name="firstName"
                autoComplete="off"
                inputRef={register({ required: true })}
                classes={{ input: classes.formInput }}
                color="primary"
                placeholder="First Name"
                error={Boolean(errors.firstName)}
              />

              <ThemedTextField
                name="lastName"
                autoComplete="off"
                inputRef={register({ required: true })}
                classes={{ input: classes.formInput }}
                color="primary"
                placeholder="Last Name"
                error={Boolean(errors.lastName)}
              />

              <FormControl className={classes.formControl}>
                <Controller
                  as={<ThemedDatePicker error={Boolean(errors.dob)} />}
                  control={control}
                  name="dob"
                  defaultValue={dob || null}
                  rules={{
                    required: true,
                    validate: {
                      validDate: (value: string) => validateMinDob(value),
                    },
                  }}
                />
              </FormControl>
              <Grid className={classes.switches}>
                {outdatedTosList.map((tos) =>
                  GUARDIAN_TOS_LABELS[tos] ? (
                    <Grid key={tos} container alignItems="center">
                      <Controller
                        name={TOS_FORM_NAMES[tos]}
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <Grid item xs={2}>
                            <IOSSwitch
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => props.onChange(e.target.checked)}
                              checked={props.value || false}
                            />
                          </Grid>
                        )}
                      />
                      <Grid item xs>
                        <Link
                          target="_blank"
                          className={classes.link}
                          href={TOS_LINKS[tos]}
                        >
                          {GUARDIAN_TOS_LABELS[tos]}
                        </Link>
                      </Grid>
                    </Grid>
                  ) : (
                    <React.Fragment key={tos} />
                  )
                )}
              </Grid>
            </Grid>
          )}
        </Grid>

        <Typography className={clsx(classes.text, classes.errorText)}>
          {generalValidationError}
        </Typography>
      </Grid>

      <Grid item>
        <FooterButtons
          nextButtonLabel="Continue"
          backButtonLabel="Logout"
          disableNext={nextButtonDisabled}
          loadingNext={pageIsLoading}
          disableBack={pageIsLoading}
          onNextButtonClick={handleSubmit(handleTosUpdate)}
          onBackButtonClick={handleLogOut}
        />
      </Grid>
    </Grid>
  )
}

export default OutdatedTosPage
