import { ActionType, createReducer } from 'typesafe-actions'

import { InsuranceCompany, OfficeAllyInfo } from 'src/store/insurance/models'
import { Moment } from 'moment'
import { oneInsert } from '../../mutators/relations'
import { insuranceActions } from '../actions'

interface State {
  companiesList: Array<InsuranceCompany['id']>
  companiesByName: Record<InsuranceCompany['name'], InsuranceCompany>

  insuranceCandidate: {
    insuranceValues: {
      insuranceName: string
      insuranceId: string
      firstName: string
      lastName: string
      dob: Moment
    }
  }
  isOnManualVerification: boolean | null

  officeAllyInfo: OfficeAllyInfo | null
}

const initialState = {
  companiesByName: {},
  companiesList: [],

  insuranceCandidate: {} as any,

  isOnManualVerification: false,

  officeAllyInfo: null,
}

export default createReducer<State, ActionType<typeof insuranceActions>>(
  initialState
)
  .handleAction(
    [insuranceActions.getCompanies.request, insuranceActions.clearCompanies],
    (state): State => {
      return {
        ...state,
        companiesByName: {},
        companiesList: [],
      }
    }
  )
  .handleAction(
    insuranceActions.getCompanies.success,
    (state, { payload: { companies } }): State => {
      const companiesByName = companies.reduce(
        (acc, company) => oneInsert(company, company.name, acc),
        state.companiesByName
      )

      return {
        ...state,
        companiesByName,
        companiesList: companies.map((company) => company.name),
      }
    }
  )
  .handleAction(
    insuranceActions.verifyInsurance.request,
    (state, { payload: { insuranceValues } }): State => {
      return {
        ...state,
        insuranceCandidate: { insuranceValues },
      }
    }
  )
  .handleAction(
    insuranceActions.getManualVerificationStatus.success,
    (state, { payload }): State => ({
      ...state,
      isOnManualVerification: payload,
    })
  )
  .handleAction(
    insuranceActions.getPatientOfficeAlly.success,
    (state, { payload: { officeAllyInfo } }): State => ({
      ...state,
      officeAllyInfo,
    })
  )
