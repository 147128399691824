import React, { useCallback, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Grid,
  IconButton,
  InputAdornment,
  Theme,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'

import ThemedTextField from '../common/ThemedTextField'

const useStyles = makeStyles(
  (theme: Theme) => ({
    formInput: {
      width: '100%',
      borderRadius: '10px',
      marginTop: theme.spacing(1.25),
    },
    iconButtonRoot: {
      color: theme.palette.primary.dark,
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
    },
    errorText: {
      paddingLeft: theme.spacing(0.5),
      color: theme.palette.error.main,
      fontSize: '0.875rem',
    },
  }),
  { name: 'ResetPasswordForm' }
)
export type ResetPasswordFormValues = {
  password: string
  confirmPassword: string
}

interface ResetPasswordFormProps {
  onSubmit(password: ResetPasswordFormValues['password']): void
  disabled?: boolean
}

const ResetPasswordForm = React.forwardRef<any, ResetPasswordFormProps>(
  ({ onSubmit, disabled = false }, ref: React.Ref<any>) => {
    const classes = useStyles()
    const [fieldViewState, setFieldViewState] = useState<{
      [key: string]: boolean
    }>({
      password: false,
      confirmPassword: false,
    })

    const { register, handleSubmit, errors, watch } = useForm<
      ResetPasswordFormValues
    >({
      mode: 'onChange',
      shouldFocusError: false,
    })

    const { password } = watch()

    const handleNewAccountSubmit = useCallback(
      (values: ResetPasswordFormValues) => {
        onSubmit(values.password)
      },
      [onSubmit]
    )

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(handleNewAccountSubmit),
    }))

    const handleClickShowPassword = useCallback((fieldKey: string) => {
      setFieldViewState((currentState) => ({
        ...currentState,
        [fieldKey]: !currentState[fieldKey],
      }))
    }, [])

    return (
      <Grid>
        <ThemedTextField
          disabled={disabled}
          name="password"
          autoComplete="off"
          inputRef={register({
            required: 'Required field',
            pattern: {
              value: /^(?=.*?[a-z])(?=.*?\d).{8,}$/i,
              message:
                'The password must be at least 8 characters long and contain both letters and numbers',
            },
          })}
          classes={{ input: classes.formInput }}
          type={fieldViewState.password ? 'text' : 'password'}
          color="primary"
          placeholder="New password"
          error={Boolean(errors.password)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                disabled={disabled}
                classes={{ root: classes.iconButtonRoot }}
                onClick={() => handleClickShowPassword('password')}
                size="large">
                {fieldViewState.password ? (
                  <VisibilityOutlined />
                ) : (
                  <VisibilityOffOutlined />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        {errors.password?.message && (
          <Typography className={clsx(classes.text, classes.errorText)}>
            {errors.password.message}
          </Typography>
        )}
        <ThemedTextField
          disabled={disabled}
          name="confirmPassword"
          autoComplete="off"
          inputRef={register({
            required: 'Required field',
            validate: {
              matchedWithOriginal: (value: string) =>
                value === password || 'Password mismatch',
            },
          })}
          classes={{ input: classes.formInput }}
          type={fieldViewState.confirmPassword ? 'text' : 'password'}
          color="primary"
          placeholder="Confirm password"
          error={Boolean(errors.confirmPassword)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                disabled={disabled}
                classes={{ root: classes.iconButtonRoot }}
                onClick={() => handleClickShowPassword('confirmPassword')}
                size="large">
                {fieldViewState.confirmPassword ? (
                  <VisibilityOutlined />
                ) : (
                  <VisibilityOffOutlined />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        {errors.confirmPassword?.message && (
          <Typography className={clsx(classes.text, classes.errorText)}>
            {errors.confirmPassword.message}
          </Typography>
        )}
      </Grid>
    );
  }
)

export default ResetPasswordForm
