import React from 'react'
import GoogleMapReact from 'google-map-react'
import { noop } from 'lodash'

import { Clinic } from '../../store/clinics/models'
import Marker from './Marker'
import { config } from '../../utils/config'

const defaultCenter = {
  lat: 59.95,
  lng: 30.33,
}

interface GoogleMapProps {
  center: {
    lat: number
    lng: number
  }
  zoom: number
  markers?: Clinic[]
  selectedMarkerId?: number
  onMarkerClick?: (childProps: any) => void
  yesIWantToUseGoogleMapApiInternals?: boolean
  onGoogleApiLoaded?: (maps: {
    map: any
    maps: any
    ref: Element | null
  }) => void
}

const GoogleMap: React.FC<GoogleMapProps> = ({
  center = defaultCenter,
  zoom = 5,
  markers = [],
  selectedMarkerId,
  onMarkerClick = noop,
  yesIWantToUseGoogleMapApiInternals = false,
  ...props
}) => {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: config.googleMapsApiKey,
          libraries: ['geometry'],
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        options={{ controlSize: 25 }}
        onChildClick={(_, childProps) => onMarkerClick(childProps)}
        yesIWantToUseGoogleMapApiInternals={yesIWantToUseGoogleMapApiInternals}
        {...props}
      >
        {markers.map(
          ({
            position: { lat, lng },
            name,
            id,
            address,
            examRoom,
            phoneNumber,
            url,
          }) => (
            <Marker
              key={name}
              lat={lat}
              lng={lng}
              name={name}
              examRoom={examRoom}
              address={address}
              phoneNumber={phoneNumber}
              url={url}
              id={id}
              selected={selectedMarkerId === id}
            />
          )
        )}
      </GoogleMapReact>
    </div>
  )
}

export default GoogleMap
