import { ActionType, createReducer } from 'typesafe-actions'

import clinicsActions from 'src/store/clinics/actions'
import {
  LoadingContext,
  LoadingContextDefault,
  LoadingState,
} from '../../../utils/types'
import { makeLoading } from '../../../utils/store'

interface State {
  loading: LoadingContext
}

const initialState: State = {
  loading: LoadingContextDefault,
}
export default createReducer<State, ActionType<typeof clinicsActions>>(
  initialState
)
  // ############################## Get Default Clinics ##############################
  .handleAction(
    [clinicsActions.getAll.request, clinicsActions.getClinicById.request],
    (state): State => ({
      ...state,
      loading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    [clinicsActions.getAll.success, clinicsActions.getClinicById.success],
    (state): State => ({
      ...state,
      loading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    [clinicsActions.getAll.failure, clinicsActions.getClinicById.failure],
    (state, { payload: { message } }): State => ({
      ...state,
      loading: makeLoading(LoadingState.failure, message),
    })
  )
