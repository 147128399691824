import React from 'react'
import { Button, Grid, Theme, Typography } from '@mui/material'
import moment from 'moment/moment'
import makeStyles from '@mui/styles/makeStyles'
import { Moment } from 'moment'

import { formatDateTitle } from '../../utils/general'

const useStyles = makeStyles(
  (theme: Theme) => ({
    controllers: {
      marginBottom: theme.spacing(4),
      padding: theme.spacing(3, 4, 0),
    },
    controlButton: {
      padding: theme.spacing(0.5),
      minWidth: 40,
      fontFamily: 'Arial',
      fontSize: '1rem',
      lineHeight: 1,
      letterSpacing: '-0.3px',
    },
    timeTitle: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '0.875rem',
      lineHeight: 1.5,
      textAlign: 'center',
      color: '#1F3075',
    },
    datesHeaders: {
      padding: theme.spacing(0, 4),
    },
    errorText: {
      marginTop: theme.spacing(0.5),
      color: theme.palette.error.main,
      fontSize: '0.875rem',
    },
  }),
  {
    name: 'AppointmentTimeSlotsControllers',
  }
)

interface AppointmentTimeSlotsControllersProps {
  dateRange: {
    current: Moment
    next: Moment
  }
  error?: string

  onShowPrev(): void
  onShowNext(): void
  onRetry(): void
}

const AppointmentTimeSlotsControllers: React.FC<AppointmentTimeSlotsControllersProps> = ({
  dateRange,
  error,
  onShowPrev,
  onShowNext,
  onRetry,
}) => {
  const classes = useStyles()

  return (
    <>
      <Grid
        item
        container
        className={classes.controllers}
        justifyContent="space-between"
      >
        {!dateRange?.current.clone().isSame(moment(), 'day') && (
          <Button
            color="primary"
            className={classes.controlButton}
            onClick={onShowPrev}
          >
            Previous
          </Button>
        )}
        <div />
        <Button
          color="primary"
          className={classes.controlButton}
          onClick={onShowNext}
        >
          Next
        </Button>
      </Grid>
      <Grid container className={classes.datesHeaders}>
        <Grid item xs={6}>
          <Typography className={classes.timeTitle}>
            {formatDateTitle(dateRange?.current?.toISOString())}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.timeTitle}>
            {formatDateTitle(dateRange?.next?.toISOString())}
          </Typography>
        </Grid>
      </Grid>
      {error && (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography align="center" className={classes.errorText}>
              {error}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={onRetry}
            >
              Retry
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default AppointmentTimeSlotsControllers
