import React from 'react'
import { Button, Grid, Theme, Typography } from '@mui/material'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { Classes } from '@mui/styles/mergeClasses/mergeClasses'
import { noop } from 'lodash'

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: '35%',
      margin: 'auto',
      width: '80%',
      background: theme.palette.background.default,
      borderRadius: 10,
      boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)',
      padding: theme.spacing(2),
      display: 'none',
      transition: 'opacity 0.15s linear',
    },
    showedRoot: {
      display: 'inherit',
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
    },
    dialogTitle: {
      marginBottom: 0,
      fontWeight: 700,
      fontSize: '1.375rem',
      letterSpacing: '-0.5px',
      lineHeight: 1.5,
    },
    button: {
      borderRadius: '12px',
      height: 56,
      width: 100,
      fontSize: '1rem',
      fontFamily: 'NeurialGrotesk',
      fontWeight: 700,
    },
    buttonsContainer: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'ConfirmationDialog' }
)
export enum ActionsDirection {
  row = 'row',
  column = 'column',
}

interface ConfirmationDialogProps {
  classes?: Classes
  open: boolean
  onClose(): void
  onConfirm?(): void
  content: string | React.ReactNode
  title?: string
  cancelOnly?: boolean
  cancelButtonLabel?: string
  confirmButtonLabel?: string
  actionsDirection?: ActionsDirection
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  classes: classesProp = {},
  open,
  title,
  content,
  onClose,
  onConfirm = noop,
  cancelOnly = false,
  cancelButtonLabel = 'Cancel',
  confirmButtonLabel = 'Confirm',
  actionsDirection = ActionsDirection.row,
}) => {
  const classes = useStyles({ classes: classesProp })

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={clsx(classes.root, { [classes.showedRoot]: open })}
    >
      <Grid>
        {title && (
          <Typography
            className={clsx(classes.text, classes.dialogTitle)}
            align="center"
          >
            {title}
          </Typography>
        )}
        {typeof content === 'string' ? (
          <Typography className={clsx(classes.text)}>{content}</Typography>
        ) : (
          content
        )}
      </Grid>
      <Grid
        container
        justifyContent="space-around"
        flexDirection={actionsDirection}
        className={classes.buttonsContainer}
      >
        {actionsDirection === ActionsDirection.row ? (
          <>
            <Grid item>
              <Button
                color="primary"
                className={classes.button}
                onClick={onClose}
              >
                {cancelButtonLabel}
              </Button>
            </Grid>

            {!cancelOnly && (
              <Grid item>
                <Button
                  color="primary"
                  className={classes.button}
                  variant="contained"
                  onClick={onConfirm}
                >
                  {confirmButtonLabel}
                </Button>
              </Grid>
            )}
          </>
        ) : (
          <>
            {!cancelOnly && (
              <Grid item>
                <Button
                  sx={{ width: '100% !important' }}
                  color="primary"
                  className={classes.button}
                  variant="contained"
                  onClick={onConfirm}
                >
                  {confirmButtonLabel}
                </Button>
              </Grid>
            )}

            <Grid item>
              <Button
                sx={{ width: '100% !important' }}
                color="primary"
                className={classes.button}
                onClick={onClose}
              >
                {cancelButtonLabel}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default ConfirmationDialog
