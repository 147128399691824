import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'

const useStyles = makeStyles(
  () =>
    ({
      overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#ffffff8f',
        zIndex: 10,
      },
    }),
  { name: 'OverlayItem' }
)

export interface OverlayItemProps {
  className?: string
}

const OverlayItem: React.FC<OverlayItemProps> = (props): React.ReactElement => {
  const { className, children } = props

  const classes = useStyles()

  return <div className={clsx(classes.overlay, className)}>{children}</div>
}

export default OverlayItem
