import moment, { Moment } from 'moment'
import { UsersProfile } from '../auth/models'

export const getInsuranceData = (
  insuranceValues: {
    insuranceName: string
    insuranceId: string
    firstName: string
    lastName: string
    dob: Moment
  },
  generalInfo: UsersProfile,
  images: {
    front: File | null
    back: File | null
  }
) => {
  const formData = new FormData()
  if (images.front) {
    formData.append('backSide', images.front, images.front.name)
  }

  if (images.back) {
    formData.append('frontSide', images.back, images.back.name)
  }

  const details = {
    firstName: insuranceValues.firstName,
    lastName: insuranceValues.lastName,
    gender: generalInfo.gender,
    dateOfBirth: moment(insuranceValues.dob).format('YYYY-MM-DD'),

    insuranceInfo: {
      isNotPrimaryInsured: false,
      insuranceName: insuranceValues.insuranceName,
      insuranceId: insuranceValues.insuranceId,
    },
  }

  formData.append('form', JSON.stringify(details))

  return formData
}
