import { noop } from 'lodash'

const fbq = window?.fbq || noop

const DEFAULT_CURRENCY = 'USD'

export const trackAddPaymentInfo = () => {
  fbq('track', 'AddPaymentInfo', {
    content_name: 'Payment Method',
    content_category: 'Credit Card Info',
    currency: DEFAULT_CURRENCY,
  })
}

export const trackLabOrderNowClick = ({
  labId,
  price,
}: {
  labId: number
  price: number
}) => {
  fbq('track', 'InitiateCheckout', {
    content_name: 'Order Lab Test',
    content_category: 'Checkout',
    content_ids: [labId],
    content_type: 'product',
    value: price,
    currency: DEFAULT_CURRENCY,
  })
}

export const trackLabPaymentConfirmation = ({
  labId,
  price,
}: {
  labId: number
  price: number
}) => {
  fbq('track', 'Purchase', {
    content_name: 'Order Lab Confirmation',
    content_category: 'Purchase',
    content_ids: [labId],
    content_type: 'product',
    value: price,
    currency: DEFAULT_CURRENCY,
  })
}

export const trackSignupEvent = () => {
  fbq('track', 'CompleteRegistration', {
    content_name: 'Create Password Page',
    content_category: 'Authentication',
    currency: DEFAULT_CURRENCY,
  })
}

export const trackLoginEvent = () => {
  fbq('trackCustom', 'Login', {
    content_name: 'Sign In Page',
    content_category: 'Authentication',
    currency: DEFAULT_CURRENCY,
  })
}
