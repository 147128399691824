import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'

type TickerProps = {
  time: null | Moment
  disablingTimeInSeconds: number
  children(data: { disabled: boolean; sendTimeDifference: number }): JSX.Element
}

const DisablingTicker: React.FC<TickerProps> = ({
  time,
  disablingTimeInSeconds,
  children,
}) => {
  const [disabled, setDisabled] = useState(true)
  const [sendTimeDifference, setSendTimeDifference] = useState(0)

  useEffect(() => {
    setDisabled(true)

    const interval = setInterval(() => {
      const currentTime = moment()
      const duration = moment.duration(currentTime.diff(time))

      setSendTimeDifference(Math.trunc(duration.asSeconds()))

      if (Math.trunc(duration.asSeconds()) >= disablingTimeInSeconds) {
        setDisabled(false)
        clearInterval(interval)
        setSendTimeDifference(0)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [time])

  return children({ disabled, sendTimeDifference })
}

export default DisablingTicker
