import { Box, Stack } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

type InfoAlertProps = {
  children: React.ReactNode
}

export default function InfoAlert({ children }: InfoAlertProps) {
  return (
    <Stack direction="row" spacing={1}>
      <Box>
        <InfoIcon color="primary" />
      </Box>
      <Box flex={1} color="primary.dark" lineHeight={1.5}>
        {children}
      </Box>
    </Stack>
  )
}
