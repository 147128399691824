import axios, { AxiosError, AxiosResponse } from 'axios'
import { config } from 'src/utils/config'

export interface ServerSuccessResponse {
  data: any
}

export interface ServerErrorResponse {
  error: {
    error_code: number
    user_msg: string
  }
}

const aucWpAxios = axios.create({
  timeout: 30000,
  responseType: 'json',
  baseURL: `${config?.server.aucWpHost}`,
})

aucWpAxios.interceptors.request.use((requestConfig) => requestConfig)

aucWpAxios.interceptors.response.use(
  (response: AxiosResponse<ServerSuccessResponse>) => response.data.data,
  (error: AxiosError<ServerErrorResponse>) => Promise.reject(error)
)

export { aucWpAxios }
