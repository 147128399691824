import React from 'react'
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    button: {
      borderRadius: Number(theme.shape.borderRadius) * 5,
      height: 50,
      textTransform: 'none',
      fontFamily: 'NeurialGrotesk',
      fontWeight: 500,
    },
  }),
  { name: 'RoundedButton' }
)

type RoundedButtonProps = MuiButtonProps & {
  active?: boolean
}

const RoundedButton: React.FC<RoundedButtonProps> = ({ active, ...props }) => {
  const classes = useStyles()

  return (
    <MuiButton
      fullWidth
      color={active ? 'secondary' : 'primary'}
      className={classes.button}
      variant={active ? 'contained' : 'outlined'}
      {...props}
    />
  )
}

export default RoundedButton
