/* eslint-disable @typescript-eslint/ban-ts-comment */
import { memo } from 'react'

import { getAbsoluteUrl } from '../utils/general'
// @ts-ignore
import NeurialGroteskRegular from '../assets/fonts/neurial_grotesk/NeurialGrotesk-Regular.otf'
// @ts-ignore
import NeurialGroteskBold from '../assets/fonts/neurial_grotesk/NeurialGrotesk-Bold.otf'
// @ts-ignore
import NeurialGroteskMedium from '../assets/fonts/neurial_grotesk/NeurialGrotesk-Medium.otf'
// @ts-ignore
import InterRegular from '../assets/fonts/Inter/Inter-Regular.ttf'
// @ts-ignore
import InterLight from '../assets/fonts/Inter/Inter-Light.ttf'
// @ts-ignore
import InterMedium from '../assets/fonts/Inter/Inter-Medium.ttf'
// @ts-ignore
import InterSemiBold from '../assets/fonts/Inter/Inter-SemiBold.ttf'
// @ts-ignore
import InterBold from '../assets/fonts/Inter/Inter-Bold.ttf'
// @ts-ignore
import SfProRegular from '../assets/fonts/sfPro/SF-Pro-Regular.otf'
// @ts-ignore
import SfProLight from '../assets/fonts/sfPro/SF-Pro-Light.otf'
// @ts-ignore
import SfProMedium from '../assets/fonts/sfPro/SF-Pro-Medium.otf'
// @ts-ignore
import SfProSemiBold from '../assets/fonts/sfPro/SF-Pro-Semibold.otf'
// @ts-ignore
import SfProBold from '../assets/fonts/sfPro/SF-Pro-Bold.otf'

const FontsLoader: React.FC = () => (
  <style>
    {`
      button:focus {
        outline: none !important;
      }

      @font-face {
        font-family: 'NeurialGrotesk';
        src: local('NeurialGrotesk'),
          url(${getAbsoluteUrl(NeurialGroteskRegular)})
            format('opentype');
      }
      
      @font-face {
        font-family: 'NeurialGrotesk';
        font-weight: 700;
        src: local('NeurialGrotesk'),
          url(${getAbsoluteUrl(NeurialGroteskBold)}) format('opentype');
      }
      
      @font-face {
        font-family: 'NeurialGrotesk';
        font-weight: 500;
        src: local('NeurialGrotesk'),
          url(${getAbsoluteUrl(NeurialGroteskMedium)})
            format('opentype');
      }
      
      @font-face {
        font-family: Inter;
        src: url(${getAbsoluteUrl(InterRegular)});
        font-weight: normal;
        font-display: swap;
      }
      
      @font-face {
        font-family: Inter;
        src: url(${getAbsoluteUrl(InterLight)});
        font-weight: 300;
        font-display: swap;
      }
      
      @font-face {
        font-family: Inter;
        src: url(${getAbsoluteUrl(InterMedium)});
        font-weight: 500;
        font-display: swap;
      }
      
      @font-face {
        font-family: Inter;
        src: url(${getAbsoluteUrl(InterSemiBold)});
        font-weight: 600;
        font-display: swap;
      }
      
      @font-face {
        font-family: Inter;
        src: url(${getAbsoluteUrl(InterBold)});
        font-weight: 700;
        font-display: swap;
      }
      
      @font-face {
        font-family: 'SF-Pro';
        src: local('SF-Pro'), url(${getAbsoluteUrl(SfProRegular)}) format('opentype');
        font-weight: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'SF-Pro';
        src: local('SF-Pro'), url(${getAbsoluteUrl(SfProLight)}) format('opentype');
        font-weight: 300;
        font-display: swap;
      }

      @font-face {
        font-family: 'SF-Pro';
        src: local('SF-Pro'), url(${getAbsoluteUrl(SfProMedium)}) format('opentype');
        font-weight: 500;
        font-display: swap;
      }

      @font-face {
        font-family: 'SF-Pro';
        src: local('SF-Pro'), url(${getAbsoluteUrl(SfProSemiBold)}) format('opentype');
        font-weight: 600;
        font-display: swap;
      }

      @font-face {
        font-family: 'SF-Pro';
        src: local('SF-Pro'), url(${getAbsoluteUrl(SfProBold)}) format('opentype');
        font-weight: 700;
        font-display: swap;
      }
      
      `}
  </style>
)

export default memo(FontsLoader)
