import { LabOrderingPatientInfo } from './labOrdering'

const storeKey = 'labPatientInfoStore'

export const setLabPatientInfoStore = (data: LabOrderingPatientInfo) => {
  window.localStorage.setItem(storeKey, JSON.stringify(data))
}

export const getLabPatientInfoStore = ():
  | LabOrderingPatientInfo
  | undefined => {
  try {
    const data = window.localStorage.getItem(storeKey)

    if (!data) {
      return undefined
    }

    return JSON.parse(data)
  } catch (error) {
    return undefined
  }
}

export const clearLabPatientInfoStore = () => {
  window.localStorage.removeItem(storeKey)
}
