import React from 'react'
import { Theme, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LocationOnIcon from '@mui/icons-material/LocationOn'

import theme from '../../utils/theme'

const useStyles = makeStyles(
  (theme: Theme) => ({
    tooltip: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      fontWeight: 'bold',
      fontFamily: 'NeurialGrotesk',
      fontSize: '0.75rem',
    },
  }),
  { name: 'Marker' }
)

interface MarkerProps {
  name: string
  address: string
  id: number
  examRoom: number
  phoneNumber: string
  url: string

  lat?: number
  lng?: number
  selected?: boolean
}

const Marker: React.FC<MarkerProps> = ({ name, selected = false }) => {
  const classes = useStyles()

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={name}
      placement="top"
    >
      <LocationOnIcon
        htmlColor={
          selected ? theme.palette.primary.main : theme.palette.error.light
        }
        style={{ transform: 'translate(-50%, -100%)' }}
      />
    </Tooltip>
  )
}

export default Marker
