import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Moment } from 'moment'
import appointmentActions from '../store/appointment/actions'
import { LoadingContext } from '../utils/types'
import {
  getSlotsLoading,
  getAvailableSlots,
} from '../store/appointment/selectors'
import { AvailableSlotsInfo } from '../store/appointment/models'

const NUMBER_OF_SLOTS_WITHOUT_SCROLLING = 4

export const useInClinicAvailableSlots = (
  currentDate: Moment,
  numberOfSlotsWithoutScrolling = NUMBER_OF_SLOTS_WITHOUT_SCROLLING
): {
  slotsInfo: AvailableSlotsInfo
  loading: LoadingContext
  withScrolling: boolean
  getSlots(): void
} => {
  const dispatch = useDispatch()

  const loading = useSelector(getSlotsLoading)
  const availableSlots = useSelector(getAvailableSlots)

  const getSlots = useCallback(() => {
    dispatch(
      appointmentActions.getInClinicAvailableSlots.request({
        currentDate: currentDate.format('yyyy-MM-DD'),
      })
    )
  }, [currentDate])

  const withScrolling = useMemo(
    () =>
      availableSlots.currentDate.length > numberOfSlotsWithoutScrolling ||
      availableSlots.nextDate.length > numberOfSlotsWithoutScrolling,
    [JSON.stringify(availableSlots), numberOfSlotsWithoutScrolling]
  )

  return { slotsInfo: availableSlots, loading, withScrolling, getSlots }
}
