import React from 'react'
import { Grid, Typography } from '@mui/material'
import clsx from 'clsx'
import moment from 'moment'
import { truncate } from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector } from 'react-redux'

import theme from '../../utils/theme'
import { Clinic } from '../../store/clinics/models'
import { InClinicSlot } from '../../store/appointment/models'
import { getSelectedPaymentMethod } from '../../store/appointment/selectors'

const useStyles = makeStyles(
  () => ({
    selectedClinicInfoButton: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(3),
      border: `2px solid ${theme.palette.primary.light}`,
      borderRadius: 20,
      width: '100%',
    },
    label: {
      fontSize: '0.875rem',
    },
    info: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    email: {
      wordBreak: 'break-word',
    },
    clinicInfoOverflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    text: {
      fontFamily: 'NeurialGrotesk',
      color: theme.palette.primary.dark,
      lineHeight: 1.5,
    },
    clinicAddress: {
      fontFamily: 'NeurialGrotesk',
      fontSize: '1.25rem',
      lineHeight: 1.3,
      maxWidth: '100%',
    },
  }),
  { name: 'AppointmentButton' }
)

interface AppointmentButtonProps {
  appointmentTime: InClinicSlot
  selectedClinicInfo: Clinic
  children?: React.ReactNode
  isTelemedicineClinic: boolean
  withPaymentMethod?: boolean
}

const AppointmentButton: React.FC<AppointmentButtonProps> = ({
  appointmentTime,
  selectedClinicInfo,
  children,
  withPaymentMethod = false,
  isTelemedicineClinic,
}) => {
  const classes = useStyles()
  const paymentMethod = useSelector(getSelectedPaymentMethod)

  return (
    <Grid container justifyContent="center">
      <Grid className={classes.selectedClinicInfoButton}>
        {children || null}
        <Grid container direction="column">
          <Typography
            align="left"
            className={clsx(classes.text, classes.label)}
          >
            Date
          </Typography>
          <Typography align="left" className={clsx(classes.text, classes.info)}>
            {appointmentTime
              ? moment(appointmentTime.start).format('ddd., MMM. DD')
              : 'Loading...'}
          </Typography>
          <Typography
            align="left"
            className={clsx(classes.text, classes.label)}
          >
            Time
          </Typography>
          <Typography align="left" className={clsx(classes.text, classes.info)}>
            {appointmentTime
              ? moment(appointmentTime.start).format('hh:mma')
              : 'Loading...'}
          </Typography>
          <Typography
            align="left"
            className={clsx(classes.text, classes.label)}
          >
            Location
          </Typography>
          <Typography
            align="left"
            className={clsx(classes.text, classes.info, {
              [classes.clinicInfoOverflow]: selectedClinicInfo?.name,
            })}
          >
            {isTelemedicineClinic
              ? 'Virtual visit'
              : selectedClinicInfo?.name || 'Loading...'}
          </Typography>
          {!isTelemedicineClinic && (
            <Typography
              align="left"
              className={clsx(classes.text, classes.clinicAddress)}
            >
              {truncate(selectedClinicInfo?.address, { length: 45 }) || ''}
            </Typography>
          )}

          {withPaymentMethod && paymentMethod && (
            <>
              <Typography
                align="left"
                className={clsx(classes.text, classes.label)}
              >
                Payment method
              </Typography>
              <Typography
                align="left"
                className={clsx(classes.text, classes.info, {
                  [classes.clinicInfoOverflow]: selectedClinicInfo?.name,
                })}
              >
                {paymentMethod === 'cash' ? 'Self-Pay' : 'Insurance'}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AppointmentButton
