import { Box, BoxProps, useTheme } from '@mui/material'
import { useIsLabOrderingFlow } from '../../hooks/labOrdering/useIsLabOrderingFlow'

export function PageLayout({ children }: BoxProps) {
  const isLabOrderingFlow = useIsLabOrderingFlow()

  const theme = useTheme()

  return (
    <Box
      sx={{
        padding: theme.spacing(isLabOrderingFlow ? 3 : 9, 4, 4),
        position: 'relative',
        // height: '100%',
        flex: 1,
      }}
    >
      {children}
    </Box>
  )
}
